import { useState } from "react";
import "../../components/Employee/Employee.css";
import { Button } from "../Button/Button";
import "../Button/Button.css";
import { Divider } from "../../components/Common/Divider";

const ClientManagerDetails = ({ onCancelClick, onDataSubmit }) => {
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactPersonEmail, setContactPersonEmail] = useState("");
  const [contactPersonPhone, setContactPersonPhone] = useState("");

  const [accountPersonName, setAccountPersonName] = useState("");
  const [accountPersonEmail, setAccountPersonEmail] = useState("");
  const [accountPersonPhone, setAccountPersonPhone] = useState("");

  const submitFormData = (e) => {
    e.preventDefault();

    if (
      !contactPersonName ||
      !contactPersonEmail ||
      !contactPersonPhone ||
      !accountPersonName ||
      !accountPersonEmail ||
      !accountPersonPhone
    ) {
      alert("Please add missing fields");
      return;
    }

    onDataSubmit({
      contactPerson: {
        contactPersonName,
        contactPersonEmail,
        contactPersonPhone,
      },
      accountPerson: {
        accountPersonName,
        accountPersonEmail,
        accountPersonPhone,
      },
    });
  };

  return (
    <div className="AddEmployeePopupViewContainer">
      <form className="AddEmployeePopupContainer" onSubmit={submitFormData}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="AddEmployeePopupTitle">Add Client</span>
          <span className="cstm-pfo-title">Step 2/3 - SPOC Information</span>
        </div>
        <Divider />
        <div className="InputContainer">
          <div style={{ marginTop: "24px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                Contact Person name <span style={{ color: "#eb2727" }}>*</span>
              </span>
              <span className="InputTitles" style={{ marginLeft: "16px" }}>
                Contact Person email <span style={{ color: "#eb2727" }}>*</span>
              </span>
              <span className="InputTitles" style={{ marginLeft: "16px" }}>
                Contact Person phone <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input"
                type="text"
                value={contactPersonName}
                onChange={(e) => setContactPersonName(e.target.value)}
              />
              <input
                className="Input"
                type="text"
                style={{ marginLeft: "11px" }}
                value={contactPersonEmail}
                onChange={(e) => setContactPersonEmail(e.target.value)}
              />
              <input
                className="Input"
                type="text"
                style={{ marginLeft: "11px" }}
                value={contactPersonPhone}
                onChange={(e) => setContactPersonPhone(e.target.value)}
              />
            </div>
          </div>

          <div style={{ marginTop: "24px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                Account Person name <span style={{ color: "#eb2727" }}>*</span>
              </span>
              <span className="InputTitles" style={{ marginLeft: "16px" }}>
                Account Person email <span style={{ color: "#eb2727" }}>*</span>
              </span>
              <span className="InputTitles" style={{ marginLeft: "16px" }}>
                Account Person phone <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input"
                type="text"
                value={accountPersonName}
                onChange={(e) => setAccountPersonName(e.target.value)}
              />
              <input
                className="Input"
                type="text"
                style={{ marginLeft: "11px" }}
                value={accountPersonEmail}
                onChange={(e) => setAccountPersonEmail(e.target.value)}
              />
              <input
                className="Input"
                type="text"
                style={{ marginLeft: "11px" }}
                value={accountPersonPhone}
                onChange={(e) => setAccountPersonPhone(e.target.value)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignSelf: "flex-end",
              marginTop: "32px",
            }}
          >
            <Button
              onClick={onCancelClick}
              buttonStyle="btn--outline"
              styles={{
                marginLeft: "auto",
              }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              styles={{
                marginLeft: "12px",
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ClientManagerDetails;
