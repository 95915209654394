import { useState, useEffect } from "react";
import placeholderImage from "../../img/employee_image_placeholder.png";

const EmployeeDetailsSidebarData = ({ employeeDetails }) => {
  const [profileImage, setProfileImage] = useState(placeholderImage);

  useEffect(() => {
    if (employeeDetails?.imgUrl) {
      preloadImage(employeeDetails.imgUrl);
    }
  }, [employeeDetails]);

  const preloadImage = (url) => {
    const img = new Image();
    img.src = url;
    img.onload = () => setProfileImage(url);
    img.onerror = () => setProfileImage(placeholderImage);
  };

  return (
    <div>
      <div className="cstm-user-name-photo">
        <div className="cstm-user-name-photo-img">
          <img src={profileImage} />
        </div>
        <div className="cstm-user-name-photo-title">
          {employeeDetails.firstName + " " + employeeDetails.lastName}
        </div>
        <div className="cstm-user-name-photo-active">Active</div>
      </div>
      <div className="cstm-ed-info">
        <div className="cstm-ed-info-tp">
          <div className="cstm-ed-info-tp-box">
            <div className="cstm-ed-info-tp-box-left">
              <span className="cstm-ed-title">Mobile</span>:
            </div>
            <div className="cstm-ed-info-tp-box-right">
              {employeeDetails.phone}
            </div>
          </div>

          <div className="cstm-ed-info-tp-box">
            <div className="cstm-ed-info-tp-box-left">
              <span className="cstm-ed-title">Email</span>:
            </div>
            <div className="cstm-ed-info-tp-box-right">
              {employeeDetails.email}
            </div>
          </div>

          <div className="cstm-ed-info-tp-box">
            <div className="cstm-ed-info-tp-box-left">
              <span className="cstm-ed-title">SL.No</span>:
            </div>
            <div className="cstm-ed-info-tp-box-right">-</div>
          </div>

          <div className="cstm-ed-info-tp-box">
            <div className="cstm-ed-info-tp-box-left">
              <span className="cstm-ed-title">Address</span>:
            </div>
            <div className="cstm-ed-info-tp-box-right">-</div>
          </div>
        </div>
        <div className="cstm-ed-info-bt">
          <div className="cstm-ed-info-bt-box">
            <div className="cstm-ed-info-bt-box-name">Shifts covered</div>
            <div className="cstm-ed-info-bt-box-number">102</div>
          </div>
          <div className="cstm-ed-info-bt-box">
            <div className="cstm-ed-info-bt-box-name">Upcoming shifts</div>
            <div className="cstm-ed-info-bt-box-number">7</div>
          </div>
          <div className="cstm-ed-info-bt-box">
            <div className="cstm-ed-info-bt-box-name">Incidents Reported</div>
            <div className="cstm-ed-info-bt-box-number">23</div>
          </div>
          <div className="cstm-ed-info-bt-box">
            <div className="cstm-ed-info-bt-box-name">Leaves taken</div>
            <div className="cstm-ed-info-bt-box-number">16/32</div>
          </div>
          <div className="cstm-ed-info-bt-box">
            <div className="cstm-ed-info-bt-box-name">Declined shifts</div>
            <div className="cstm-ed-info-bt-box-number">15</div>
          </div>
          <div className="cstm-ed-info-bt-box">
            <div className="cstm-ed-info-bt-box-name">Total duty hours</div>
            <div className="cstm-ed-info-bt-box-number">874</div>
          </div>
          <div className="cstm-ed-info-bt-box">
            <div className="cstm-ed-info-bt-box-name">Total payout</div>
            <div className="cstm-ed-info-bt-box-number">14,300 AUD</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetailsSidebarData;
