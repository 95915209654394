import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Box, Typography } from "@mui/material";

const RadialBarChart = ({ chartData, chartColors, chartName }) => {
  const [chartOptions, setChartOptions] = useState(null);

  useEffect(() => {
    const emptyPortion = 10;
    const total = chartData.reduce((acc, cur) => acc + cur.y, 0);
    const emptyValue = (emptyPortion / (100 - emptyPortion)) * total;

    // Add empty data point
    const newData = [
      ...chartData,
      { name: "Empty", y: emptyValue, color: "#f3f7fb" },
    ];

    const options = {
      chart: {
        type: "pie",
      },
      title: {
        text: "",
      },
      plotOptions: {
        pie: {
          innerSize: "70%",
          dataLabels: {
            enabled: false,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
          borderRadius: 15, // Set the border radius for rounded edges
          borderWidth: 0, // Remove space between two colors
        },
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: "Data",
          data: newData,
        },
      ],
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
        itemMarginBottom: 10,
        labelFormatter: function () {
          return `${this.name}: <span style="color: ${this.color}">${this.y}</span>`;
        },
      },
      colors: chartData.map(item => item.color),
    };

    setChartOptions(options);
  }, [chartData]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: 'wrap',
        }}
        >
        <Box sx={{
          height:'250px',
          overflow: 'hidden',
          '& > div' : {
            height: '100%',
            '& .highcharts-container' : {
              width: {sm:'300px !important', xs: '250px !important'},
              '& svg': {
                width: '100%',
                height:'auto'
              }
            },
            '& .highcharts-background' : {
              fill: 'transparent '
            }
          }
        }}>
          {chartOptions && (
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions}
            />
          )}
        </Box>
          <Box sx={{
            marginLeft: '20px'
          }}>
            <Typography variant="h2" sx={{
              fontSize: '20px',
              fontWeight: '600',
              color: '#030229',
              mb: '20px'
            }}>Finances</Typography>
            <ul style={{ listStyle: "none", padding: 0 }}>
              {chartData.map((item, index) => (
                <li key={index}>
                  <Typography variant="body1" sx={{
                    display: 'flex',
                    alignIte: 'center',
                    mb:'15px'
                  }}>
                    <Box sx={{
                      display: "inline-block",
                      borderRadius: '5px',
                      width: "20px",
                      height: "20px",
                      backgroundColor: item.color,
                      marginRight: "15px",
                    }}></Box>
                    {item.name}</Typography>
                </li>
              ))}
            </ul>
          </Box>
      </Box>
    </>
  );
};

export default RadialBarChart;
