import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export const exportCsvForPayslip = (
  columns,
  data,
  fileName = "ShiftsData",
  employeeName,
  totalHours,
  totalEarnings
) => {
  // Ensure the file name is a string and ends with .xlsx
  fileName = String(fileName);
  if (!fileName.endsWith(".xlsx")) {
    fileName += ".xlsx";
  }

  // Initialize worksheet data
  const wsData = [];

  // Add additional information rows
  if (employeeName) wsData.push(["Employee Name:", employeeName]);
  if (totalHours) wsData.push(["Total Hours:", totalHours]);
  if (totalEarnings) wsData.push(["Total Earnings:", totalEarnings]);
  wsData.push([]); // Add an empty row for spacing

  // Add table headers
  wsData.push(columns.map((col) => col.title));

  // Transform data to match the column structure
  data.forEach((rowData, index) => {
    const row = {};
    columns.forEach((col) => {
      row[col.title] = rowData[col.field];
    });
    // Add serial number
    row["S.No"] = index + 1;
    wsData.push(Object.values(row));
  });

  // Create worksheet and workbook
  const ws = XLSX.utils.aoa_to_sheet(wsData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Shifts Data");

  // Write to buffer
  const buf = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([buf], { type: "application/octet-stream" });

  // Save the file
  saveAs(blob, fileName);
};

export const exportCsvForInvoice = (
  columns,
  data,
  fileName = "ShiftsData",
  clientName,
  totalHours,
  totalEarnings
) => {
  // Ensure the file name is a string and ends with .xlsx
  fileName = String(fileName);
  if (!fileName.endsWith(".xlsx")) {
    fileName += ".xlsx";
  }

  // Initialize worksheet data
  const wsData = [];

  // Add additional information rows
  if (clientName) wsData.push(["Client Name:", clientName]);
  if (totalHours) wsData.push(["Total Hours:", totalHours]);
  if (totalEarnings) wsData.push(["Total Earnings:", totalEarnings]);
  wsData.push([]); // Add an empty row for spacing

  // Add table headers
  wsData.push(columns.map((col) => col.title));

  // Transform data to match the column structure
  data.forEach((rowData, index) => {
    const row = {};
    columns.forEach((col) => {
      row[col.title] = rowData[col.field];
    });
    // Add serial number
    row["S.No"] = index + 1;
    wsData.push(Object.values(row));
  });

  // Create worksheet and workbook
  const ws = XLSX.utils.aoa_to_sheet(wsData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Shifts Data");

  // Write to buffer
  const buf = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([buf], { type: "application/octet-stream" });

  // Save the file
  saveAs(blob, fileName);
};
