import { useState, useEffect } from "react";
import React from "react";
import "./Employee.css";
import { Button } from "../Button/Button";
import "../Button/Button.css";

const AddEmployeePopup = ({ onCancelClick, onDataSubmit }) => {
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleKeyPress = (event) => {
    if (event.keyCode === 27) {
      onCancelClick();
    }
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [dayRate, setDayRate] = useState(0);
  const [nightRate, setNightRate] = useState(0);
  const [saturdayRate, setSaturdayRate] = useState(0);
  const [sundayRate, setSundayRate] = useState(0);
  const [holidayRate, setHolidayRate] = useState(0);

  const submitFormData = (e) => {
    e.preventDefault();

    if (
      !firstName ||
      !lastName ||
      !email ||
      !phone ||
      dayRate === 0 ||
      nightRate === 0 ||
      saturdayRate === 0 ||
      sundayRate === 0 ||
      holidayRate === 0
    ) {
      alert("Please add missing fields");
      return;
    }

    onDataSubmit({
      firstName,
      lastName,
      email,
      phone,
      dayRate: dayRate * 100,
      nightRate: nightRate * 100,
      saturdayRate: saturdayRate * 100,
      sundayRate: sundayRate * 100,
      holidayRate: holidayRate * 100,
    });
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
  };

  return (
    <div className="AddEmployeePopupViewContainer">
      <form className="AddEmployeePopupContainer" onSubmit={submitFormData}>
        <span className="AddEmployeePopupTitle">Add Employee</span>
        <div className="InputContainer">
          <div style={{ marginTop: "29px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                First Name <span style={{ color: "#eb2727" }}>*</span>
              </span>
              <span className="InputTitles" style={{ marginLeft: "16px" }}>
                Last Name <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <input
                className="Input"
                type="text"
                style={{ marginLeft: "16px" }}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                Email Address <span style={{ color: "#eb2727" }}>*</span>
              </span>
              <span className="InputTitles" style={{ marginLeft: "16px" }}>
                Mobile <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                className="Input"
                type="text"
                style={{ marginLeft: "16px" }}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                Day Rate <span style={{ color: "#eb2727" }}>*</span>
              </span>
              <span className="InputTitles" style={{ marginLeft: "16px" }}>
                Night Rate <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input"
                pattern="[0-9]+(\.[0-9]+)?"
                value={dayRate}
                onChange={(e) => setDayRate(e.target.value)}
              />
              <input
                className="Input"
                pattern="[0-9]+(\.[0-9]+)?"
                style={{ marginLeft: "16px" }}
                value={nightRate}
                onChange={(e) => setNightRate(e.target.value)}
              />
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                Saturday Rate <span style={{ color: "#eb2727" }}>*</span>
              </span>
              <span className="InputTitles" style={{ marginLeft: "16px" }}>
                Sunday Rate <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input"
                pattern="[0-9]+(\.[0-9]+)?"
                value={saturdayRate}
                onChange={(e) => setSaturdayRate(e.target.value)}
              />
              <input
                className="Input"
                pattern="[0-9]+(\.[0-9]+)?"
                style={{ marginLeft: "16px" }}
                value={sundayRate}
                onChange={(e) => setSundayRate(e.target.value)}
              />
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                Holiday Rate <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input"
                pattern="[0-9]+(\.[0-9]+)?"
                value={holidayRate}
                onChange={(e) => setHolidayRate(e.target.value)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignSelf: "flex-end",
              marginTop: "32px",
            }}
          >
            <Button
              onClick={onCancelClick}
              buttonStyle="btn--outline"
              styles={{
                marginLeft: "auto",
              }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              styles={{
                marginLeft: "12px",
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEmployeePopup;
