import { Button } from "../Button/Button";

const SitesHeader = ({ onAddSiteClick }) => {
  return (
    <div className="cstm-cont-header">
      <div className="cstm-cont-header-title">
        <h1>Sites</h1>
      </div>

      <div className="cstm-cont-header-btn">
        <Button onClick={onAddSiteClick}>
          <i className="fa fa-map-pin"></i> Add Site
        </Button>
      </div>
    </div>
  );
};

export default SitesHeader;
