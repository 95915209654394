import React, { useState } from "react";
import {
  Box,
  Button,
  Fade,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { ReactComponent as DropDownArrow } from "../../../../img/DropdownDownArrow.svg";
import LineChart from "./StatisticsChart/LineChart";
import RadialBarChart from "./StatisticsChart/RadialBarChart";

const DashboardStatistics = () => {
  const [menu, setMenu] = useState(null);
  const [selectVal, setSelectVal] = useState("This month");
  const open = Boolean(menu);
  const dropDownList = ["This Month", "This Week", "Last Week", "Last Month"];
  const currentDate = new Date();

  const day = currentDate.getDate();
  const month = currentDate.toLocaleString("default", { month: "short" }); // Full month name
  const year = currentDate.getFullYear();

  const formattedDate = `${day}, ${month} `;

  const clientAnnotations = [
    {
      labels: [
        {
          point: {
            xAxis: 0,
            yAxis: 0,
            x: 3.5, // x-value of the data point you want to label
            y: 130, // y-value of the data point you want to label
          },
          text: "<p>+22 &#x2191;</p>",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          padding: 5,
          borderRadius: 5,
          borderColor: "none",
          useHTML: true,
          style: {
            color: "#4896DC",
            fontSize: "16px",
            fontWeight: "bold",
          },
        },
      ],
    },
  ];

  const employeeAnnotations = [
    {
      labels: [
        {
          point: {
            xAxis: 0,
            yAxis: 0,
            x: 4, // x-value of the data point you want to label
            y: 57, // y-value of the data point you want to label
          },
          text: "<p>+22 &#x2191;</p>",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          padding: 5,
          borderRadius: 5,
          borderColor: "none",
          useHTML: true,
          style: {
            color: "#4896DC",
            fontSize: "16px",
            fontWeight: "bold",
          },
        },
      ],
    },
  ];

  const profitLossAnnotations = [
    {
      labels: [
        {
          point: {
            xAxis: 0,
            yAxis: 0,
            x: 0.2, // x-value of the data point you want to label
            y: 35, // y-value of the data point you want to label
          },
          text: "<p>+8.5% &#x2191;</p>",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          padding: 5,
          borderRadius: 5,
          borderColor: "none",
          style: {
            color: "#4896DC",
            fontSize: "16px",
            fontWeight: "bold",
          },
        },
        {
          point: {
            xAxis: 0,
            yAxis: 0,
            x: 6, // x-value of the data point you want to label
            y: 35, // y-value of the data point you want to label
          },

          text: `<div className="cstm-profit-loss-date-container" style="display:grid"><span className="cstm-profit-loss-date">${formattedDate}</span><span className="cstm-profit-loss-no">305K</span></div>`,
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          padding: 5,
          borderRadius: 5,
          borderColor: "none",
          useHTML: true,
          style: {
            color: "#4896DC",
            fontSize: "15px",
            fontWeight: "bold",
          },
        },
      ],
    },
  ];

  const handleClick = (event) => {
    setMenu(event.currentTarget);
  };
  const handleClose = () => {
    setMenu(null);
  };
  const handleSelect = (value) => {
    setMenu(null);
    setSelectVal(value);
  };
  const btnStyle = {
    padding: "0",
    color: "#5B5E6F",
    fontWeight: "600",
    flexShrink: "0",
    "& .MuiTouchRipple-root .MuiTouchRipple-child": {
      backgroundColor: "#E3F5FF !important",
    },
    "& svg": {
      ml: "5px",
      transition: "all .5s ease",
      transform: `rotate(${open ? "180" : "0"}deg)`,
    },
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
  };
  const chartData = [
    { name: "Income", y: 200, color: "#1F4C74" },
    { name: "Sub -Contractor Expenses", y: 100, color: "#BDE3FF" },
    { name: "Employee Payments", y: 150, color: "#0164C6" },
  ];

  return (
    <>
      <Box className="section-spacing">
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            pb: "24px",
          }}
        >
          <Box className="text-wrapper">
            <Typography variant="h3">Statistics</Typography>
            <Typography variant="body2">
              Lorem ipsum dolor sit amet consectetur. Massa erat interdum id a
              est massa.
            </Typography>
          </Box>
          <Button
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={btnStyle}
          >
            {selectVal}
            <DropDownArrow />
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{ "aria-labelledby": "fade-button" }}
            anchorEl={menu}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            {dropDownList.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    handleSelect(item);
                  }}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Menu>
        </Box>

        <Grid
          container
          spacing={"25px"}
          sx={{
            "& .highcharts-container ": {
              width: "100% !important",
            },
          }}
        >
          <Grid item xxl={4} lg={12} md={6} xs={12}>
            <Box
              sx={{
                borderRadius: "20px",
                boxShadow:
                  "0 4px 59px rgba(50, 50, 71, 0.06), 0 4px 103px rgba(50, 50, 71, 0.01)",
                overflow: "hidden",
                height: "250px",
                padding: "20px 20px 0",
                "& > div": {
                  height: "100%",
                },
                "& .highcharts-title": {
                  fontSize: "14px !important",
                  fontFamily: "Inter",
                  fontWeight: "500 !important",
                  color: "#272830 !important",
                  fill: "#272830 !important",
                },
                "& .highcharts-subtitle": {
                  fontSize: "28px !important",
                  fontFamily: "Poppins",
                  fontWeight: "600 !important",
                  color: "#272830 !important",
                  fill: "#272830 !important",
                },
                "& .highcharts-button-symbol": {
                  width: "28px",
                  height: "24px",
                },
                "& svg": {
                  width: "100%",
                  height: "auto",
                },
                "& .highcharts-button-box": {
                  fill: "transparent !important",
                },
              }}
            >
              <LineChart
                title="Clients"
                subTitle="920"
                exports={true}
                data={[
                  10,
                  80,
                  60,
                  55,
                  60,
                  {
                    y: 80,
                    marker: {
                      symbol: "circle",
                      enabled: true,
                    },
                  },
                  140,
                  0,
                ]}
                annotations={clientAnnotations}
              />
            </Box>
          </Grid>
          <Grid item xxl={8} lg={12} md={6} xs={12}>
            <RadialBarChart chartData={chartData} />
          </Grid>
          <Grid item xxl={4} lg={12} md={6} xs={12}>
            <Box
              sx={{
                borderRadius: "20px",
                boxShadow:
                  "0 4px 59px rgba(50, 50, 71, 0.06), 0 4px 103px rgba(50, 50, 71, 0.01)",
                overflow: "hidden",
                height: "250px",
                padding: "20px 20px 0",
                "& > div": {
                  height: "100%",
                },
                "& .highcharts-title": {
                  fontSize: "14px !important",
                  fontFamily: "Inter",
                  fontWeight: "500 !important",
                  color: "#272830 !important",
                  fill: "#272830 !important",
                },
                "& .highcharts-subtitle": {
                  fontSize: "28px !important",
                  fontFamily: "Poppins",
                  fontWeight: "600 !important",
                  color: "#272830 !important",
                  fill: "#272830 !important",
                },
                "& .highcharts-button-symbol": {
                  width: "28px",
                  height: "24px",
                },
                "& svg": {
                  width: "100%",
                  height: "auto",
                },
                "& .highcharts-button-box": {
                  fill: "transparent !important",
                },
              }}
            >
              <LineChart
                title="Employees"
                subTitle="9.9K"
                exports={true}
                data={[
                  10,
                  20,
                  30,
                  40,
                  {
                    y: 50,
                    marker: {
                      symbol: "circle",
                      enabled: true,
                    },
                  },
                  40,
                  30,
                ]}
                annotations={employeeAnnotations}
              />
            </Box>
          </Grid>
          <Grid item xxl={8} lg={12} md={6} xs={12}>
            <Box
              sx={{
                borderRadius: "20px",
                boxShadow:
                  "0 4px 59px rgba(50, 50, 71, 0.06), 0 4px 103px rgba(50, 50, 71, 0.01)",
                overflow: "hidden",
                height: "250px",
                padding: "20px 20px 0",
                "& > div": {
                  height: "100%",
                },
                "& .highcharts-title": {
                  fontSize: "14px !important",
                  fontFamily: "Inter",
                  fontWeight: "500 !important",
                  color: "#272830 !important",
                  fill: "#272830 !important",
                },
                "& .highcharts-subtitle": {
                  fontSize: "50px !important",
                  fontFamily: "Poppins",
                  fontWeight: "600 !important",
                  color: "#272830 !important",
                  fill: "#272830 !important",
                },
                "& .highcharts-button-symbol": {
                  width: "28px",
                  height: "24px",
                },
                "& svg": {
                  width: "100%",
                  height: "auto",
                },
                "& .highcharts-button-box": {
                  fill: "transparent !important",
                },
              }}
            >
              <LineChart
                title="Profit/Loss"
                data={[0, 10, 5, 12, 30, 25, 40, 75, 92, 100, 108, 113]}
                crosshair={true}
                subTitle="16050 AUD"
                exports={false}
                annotations={profitLossAnnotations}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DashboardStatistics;
