import { useEffect, useState } from "react";
import Navbar1 from "../../components/Navbar/Navbar1";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminApi from "../../api/AdminApi";
import { Divider } from "../../components/Common/Divider";

import ShiftSidebar from "./ShiftSidebar";
import ShiftCard from "./ShiftCard";
import ShiftFooter from "./ShiftFooter";
import ShiftEmployeeInput from "./ShiftEmployeeInput";
import Loader from "../../components/Loader/Loader";

import AssignShiftPopup from "../../components/Shift/AssignShiftPopup";

import { convertUTCToDateString } from "../../util/DateTimeUtils";

const ShiftDetails = () => {
  const [shiftDetails, setShiftDetails] = useState({});

  const [loader, setLoader] = useState(true);
  const [showAssignShiftPopup, setShowAssignShiftPopup] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const { adminId } = useSelector((state) => state.auth);

  useEffect(() => {
    fetchShiftDetails();
  }, []);

  async function fetchShiftDetails() {
    setLoader(true);
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("shiftId", id);
    try {
      const response = await AdminApi.fetchShiftDetails(formData);
      if (response.status) {
        setShiftDetails(response.data.response);
        setLoader(false);
      }
    } catch (error) {
      navigate("/shifts");
    }
  }

  async function markShiftAsApproved() {
    setLoader(true);
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("shiftId", id);
    try {
      const response = await AdminApi.markShiftAsApproved(formData);
      if (response.status) {
        setShiftDetails(response.data.response);
        setLoader(false);
      }
    } catch (error) {
      navigate("/shifts");
    }
  }

  const handleAssignShiftClicked = () => {
    setShowAssignShiftPopup(true);
  };

  const handleAssignShiftSubmitClicked = () => {
    setShowAssignShiftPopup(false);
    fetchShiftDetails();
  };

  const handleAssignShiftCancelClicked = () => {
    setShowAssignShiftPopup(false);
  };

  const handleShiftUnAssigned = () => {
    fetchShiftDetails();
  };

  const handleMarkShiftApprovedClick = () => {
    markShiftAsApproved();
  };

  if (loader) return <Loader />;

  return (
    <>
      <Navbar1 />
      <div className="cstm-contant-main">
        <div className="cstm-contant-ed">
          <ShiftSidebar shiftDetails={shiftDetails} />
          <div className="cstm-contant-ed-right">
            <div className="cstm-prevnext">
              <div className="cstm-pn-title">Previous/Next Shift</div>
              <div className="pg-ar">
                <button className="pg-ar-left">
                  <span className="material-icons">chevron_left</span>
                </button>
                <button className="pg-ar-right">
                  <span className="material-icons">chevron_right</span>
                </button>
              </div>
            </div>

            <div className="cstm-pfo-main">
              <div className="cstm-pfo-main-left">Shift Overview</div>
              <div className="cstm-pfo-main-right">
                <div className="cstm-pfo-title">
                  Shift created on{" "}
                  {convertUTCToDateString(shiftDetails.createdAt)}
                </div>
                {shiftDetails.shiftStatus !== "PROCESSED" && (
                  <div className="cstm-pfo-btn">
                    <button
                      className="btn btn--outline btn--medium"
                      onClick={handleMarkShiftApprovedClick}
                    >
                      Mark Approved
                    </button>
                  </div>
                )}
              </div>
            </div>

            <Divider />

            <div
              style={{
                display: "flex",
              }}
            >
              <ShiftCard shiftDetails={shiftDetails} />
              <ShiftEmployeeInput
                shiftDetails={shiftDetails}
                assignShiftClicked={handleAssignShiftClicked}
                onShiftUnassigned={handleShiftUnAssigned}
              />
            </div>

            <ShiftFooter shiftDetails={shiftDetails} />
          </div>
        </div>
        {showAssignShiftPopup && (
          <AssignShiftPopup
            shiftId={shiftDetails.shiftId}
            shiftStartTime={shiftDetails.startTime}
            shiftEndTime={shiftDetails.endTime}
            onCancelClick={handleAssignShiftCancelClicked}
            onDataSubmit={handleAssignShiftSubmitClicked}
          />
        )}
      </div>
    </>
  );
};

export default ShiftDetails;
