const ClickableText = ({ text, onClick, isSelected }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <span
      onClick={handleClick}
      className="clickable-text"
      style={{ fontWeight: isSelected ? "bold" : "normal", cursor: "pointer" }}
    >
      {text}
    </span>
  );
};

ClickableText.defaultProps = {
  isSelected: false,
};

export default ClickableText;
