import React, { useState } from 'react'
import { Button } from '../../components/Button/Button'
import Logo from "../../img/logo.png"
import { Link } from 'react-router-dom'
const ResetPassword = () => {
    const [password, setPassword] = useState({
        newPassword: "",
        confirmPassword: ""
    })
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState({
        newPassword: "",
        confirmPassword: "",
        matchError: ""
    })

    const validate = () => {
        let passError = {}
        let isValid = false
        if (!password.newPassword) {
            isValid = true
            passError.newPassword = "Enter New Password"
        }
        if (!password.confirmPassword) {
            isValid = true
            passError.confirmPassword = "Enter Confirm Password"
        }
        if (password.confirmPassword !== password.newPassword) {
            isValid = true
            passError.matchError = "New Password & confirm Password not match "
        }
        setError(passError)
        return isValid
    }


    const handleOnChange = (e) => {
        const { name, value } = e.target
        setPassword({ ...password, [name]: value })
        switch (name) {
            case "newPassword":
                value === "" ? setError({ ...error, newPassword: "Enter New Password" }) : setError({ ...error, newPassword: "" })
                break;
            case "confirmPassword":
                value === "" ? setError({ ...error, confirmPassword: "Enter confirm Password" }) : setError({ ...error, confirmPassword: "" })
                break;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!validate(password)) {
            console.log(password, "password");
        }
    }
    const onClickShowPassword = () => {
        setShowPassword(!showPassword)
    }
    return (
        <>
            <div className='cstm-form-man'>
                <div className='cstm-form-man-in'>
                    <div className='cstm-form-man-logo'><Link ><img src={Logo} /></Link></div>
                    <div className='cstm-form-man-info'>
                        <form>
                            <div className='cstm-form-title'>Reset Password</div>
                            <div className='cstm-form-box'>
                                <div className='cstm-form-box-top'>New Password<span className="form-asterisk">*</span></div>
                                <div className='cstm-form-box-bottom'>
                                    <input
                                        placeholder='Enter New Password'
                                        className="cstm-input-fm"
                                        onChange={handleOnChange}
                                        name="newPassword"
                                        value={password.newPassword}
                                        type={showPassword ? "text" : "password"}
                                    />
                                    <span className="error-message">{error.newPassword}</span>
                                </div>
                            </div>

                            <div className='cstm-form-box'>
                                <div className='cstm-form-box-top'>Confirm Password<span className="form-asterisk">*</span></div>
                                <div className='cstm-form-box-bottom'>
                                    <input
                                        className="cstm-input-fm"
                                        onChange={handleOnChange}
                                        name='confirmPassword'
                                        value={password.confirmPassword}
                                        type={showPassword ? "text" : "password"}
                                        placeholder='Enter Confirm Password'
                                    />
                                    <span className="error-message">{error.confirmPassword}</span><br />
                                    <span className="error-message">{error.matchError}</span>
                                </div>
                            </div>


                            <div className='cstm-form-box2'>
                                <div className='cstm-form-box2-stay'>
                                    <label><input type="checkbox" onClick={onClickShowPassword} /> Show Password</label>
                                </div>
                            </div>

                            <div className='cstm-form-box3'>
                                <Button
                                    onClick={handleSubmit}
                                >Reset Password</Button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ResetPassword;