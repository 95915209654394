import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as PayPalIcon } from "../../../../img/paypal.svg";
import { ReactComponent as BitbucketIcon } from "../../../../img/bitbucket.svg";
import { ReactComponent as AutodeskIcon } from "../../../../img/autodesk.svg";
import { ReactComponent as JqueryIcon } from "../../../../img/jquery.svg";

const CompanyDashboardClients = () => {
  const boxStyle = {
    boxShadow:
      "0 4px 59px rgba(50, 50, 71, 0.06), 0 4px 103px rgba(50, 50, 71, 0.01)",
    padding: "20px",
    borderRadius: "16px",
    minHeight: { sm: "180px" },
    "& .list-wrapper": {
      display: "flex",
      alignItems: "start",
      flexWrap: "wrap",
      mt: "22px",
      ml: "-16px",
      mb: "-16px",
      "& > div": {
        maxWidth: "170px",
        minWidth: "60px",
        ml: "16px",
        mb: "16px",
        textAlign: "center",
        "& svg": {
          width: { lg: "40px" },
          height: { lg: "auto" },
        },
      },
    },
  };

  return (
    <>
      <Box className="section-spacing">
        <Box>
          <Typography variant="h3">Clients</Typography>
          <Typography variant="body2">
            Lorem ipsum dolor sit amet consectetur. Massa erat interdum id a est
            massa.
          </Typography>
          <Grid container spacing={"18px"} pt="24px">
            <Grid item lg={6} xs={12}>
              <Box sx={boxStyle}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                  color="#0A0A0A"
                >
                  New Clients
                </Typography>
                <Box className="list-wrapper">
                  <Box>
                    <PayPalIcon />
                    <Typography variant="body1" color="initial">
                      Paypal
                    </Typography>
                  </Box>
                  <Box>
                    <BitbucketIcon />
                    <Typography variant="body1" color="initial">
                      Box Security
                    </Typography>
                  </Box>
                  <Box>
                    <AutodeskIcon />
                    <Typography variant="body1" color="initial">
                      Axis
                    </Typography>
                  </Box>
                  <Box>
                    <JqueryIcon />
                    <Typography variant="body1" color="initial">
                      Sphere global
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Box sx={boxStyle}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                  color="#0A0A0A"
                >
                  Focus Accounts
                </Typography>
                <Box className="list-wrapper">
                  <Box>
                    <PayPalIcon />
                    <Typography variant="body1" color="initial">
                      Paypal
                    </Typography>
                  </Box>
                  <Box>
                    <BitbucketIcon />
                    <Typography variant="body1" color="initial">
                      Box Security
                    </Typography>
                  </Box>
                  <Box>
                    <AutodeskIcon />
                    <Typography variant="body1" color="initial">
                      Axis
                    </Typography>
                  </Box>
                  <Box>
                    <JqueryIcon />
                    <Typography variant="body1" color="initial">
                      Sphere global
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default CompanyDashboardClients;
