import "./PrivacyPolicy.css"; // Import the CSS file for styling

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1 className="title">Privacy Policy</h1>
      <h2 className="section-title">1.0 Acknowledgement</h2>
      <p className="section-text">
        This Privacy Policy (Policy) is published on the application/platform of{" "}
        <strong>Schedit Global Pty. Ltd.</strong> (Company) and may be updated
        from time to time. By continuing to deal with the Company, you accept
        this Policy as it applies from time to time.
      </p>
      <h2 className="section-title">2.0 Purpose</h2>
      <p className="section-text">
        This policy outlines how the Company and its offices, branches and
        related companies (the Group) uses and manages personal information that
        is provided to it and that it collects. It is also a guide to the Group
        staff as to the standards to be applied in respect of handling personal
        information and ensures consistency in the Group’s approach to privacy.
      </p>
      <p className="section-text">
        The Group is bound by the Australian Privacy Principles (APP) contained
        in the Commonwealth Privacy Act 1988 and will collect, use and retain
        personal information in accordance with those principles.
      </p>
      <p className="section-text">
        The Group reserve the right to and may, from time to time, review and
        update this Policy to take into account new laws and technology, changes
        to the Group’s operations and practices and to make sure the Policy
        remains current and relevant.
      </p>
      <h2 className="section-title">3.0 Scope</h2>
      <p className="section-text">
        This policy applies to all employees and board members of the Group.
      </p>
      <h2 className="section-title">4.0 What is Personal Information?</h2>
      <p className="section-text">
        <strong>Personal Information</strong> means information or an opinion
        about an identified individual, or an individual who is reasonably
        identifiable, whether the information or opinion is true or not, and
        whether the information is recorded in material form or not.
      </p>
      <h2 className="section-title">5.0 What is not Personal Information?</h2>
      <p className="section-text">
        Information where Group has removed any reference to an individual is
        not personal information provided that the identity of the individual
        can no longer be ascertained. Employee Records are also not Personal
        Information for the purposes of the Privacy Act and this Policy.
      </p>
      <h2 className="section-title">6.0 Collection of Personal Information</h2>
      <p className="section-text">
        Generally, all personal information is collected directly from you, or
        with your consent, and our policy is to collect only Personal
        Information that is reasonably necessary or directly related to one or
        more of the Group’s functions or services. The Group may collect
        information about you such as your name, contact details, skills,
        qualifications, accreditations and your employment history. We may also
        collect other types of personal information such as references and
        employment objectives during the course of dealing with you, for
        example, if you are considered for a particular employment position.
      </p>
      <p className="section-text">
        When you visit our platform, we may collect your information when:
        <ol className="lettered-list">
          <li>
            registering or subscribing to our services or requesting further
            information;
          </li>
          <li>
            contacting us to report a problem with our platforms or make any
            enquiry or query or comment;
          </li>
          <li>providing a response in an online survey;</li>
          <li>completing a feedback form; and</li>
          <li>applying online for a job.</li>
        </ol>
      </p>
      <h2 className="section-title">
        7.0 Use and Disclosure of Personal Information
      </h2>
      <p className="section-text">
        The Group hold, uses and discloses personal information for the purpose
        disclosed at the time of collection, or otherwise as set out in this
        Policy. We will not use or disclose your personal information for any
        other purpose without first seeking your consent, unless authorised or
        required by law.
      </p>
      <p className="section-text">
        Generally we will only use and disclose your personal information for
        the following purposes:
        <ol className="lettered-list">
          <li>to provide our services to you;</li>
          <li>
            to maintain our business relationship, where you are a user of our
            platform, a client or candidate;
          </li>
          <li>
            to enable you to submit your CV generally, to apply for specific
            jobs;
          </li>
          <li>
            to trusted third parties where we have retained them to provide
            services that you have requested; such as psychometric evaluations
            or skills tests, and who also provide services to us, such as
            professional advisers, consultants and function co-ordinators;
          </li>
          <li>
            we may also release personal information to regulatory or law
            enforcement authorities, if they require us to do so; and
          </li>
          <li>
            we may also seek your consent to collect, hold, use and disclose
            your personal information for any other purpose not listed above.
          </li>
        </ol>
      </p>
      <h2 className="section-title">8.0 Sensitive Information</h2>
      <p className="section-text">
        Sensitive information is a special category of personal information
        under the Privacy Act 1988 (Cth). It is the information or an opinion
        about you, including racial or ethnic origin, political opinions,
        membership of a political association, religious beliefs or
        affiliations, philosophical beliefs, membership of a professional or
        trade association, membership of a trade union, sexual preferences or
        practices, criminal records and health information. The Group will
        provide the purpose for the collection and only be disclosed with your
        consent.
      </p>
      <h2 className="section-title">9.0 Privacy on Our Platform</h2>
      <p className="section-text">
        You may submit your CV via this platform, either for general
        consideration for positions as they arise or to apply for a specific
        advertised job. Once submitted, your CV will be stored on our central
        database. This database, including your personal information, can be
        accessed by our staff members with the appropriate access. You can
        update your CV at any time, simply by following the same procedure to
        submit a new CV via our platforms.
      </p>
      <p className="section-text">
        We gather information and statistics about all visitors to our platform
        including the most frequently accessed pages and most frequently used
        services. We only use such data in aggregate form (that is, the
        information does not identify any one individual). This information
        helps us to determine the most beneficial parts of our platforms and
        ways in which we can continually improve our online services to create a
        better overall experience for our users.
      </p>
      <h2 className="section-title">10.0 Cookies</h2>
      <p className="section-text">
        Cookies are a small text file that our platform may place on your
        computer to collect information such as your Internet protocol address,
        your computer's operating system, browser type and traffic patterns. You
        may adjust your Internet browser to disable cookies, or inform you when
        one is being used. If you choose to disable cookies, you may be unable
        to access certain areas of our platform.
      </p>
      <p className="section-text">
        Sometimes our platform contains links to other platforms for your
        convenience and information. When you access a platform other than our
        platform please understand that we are not responsible for the privacy
        practices of that site.
      </p>
      <h2 className="section-title">11.0 Security</h2>
      <p className="section-text">
        We take reasonable steps to keep personal information secure, accurate
        and up to date. The Internet is not always a secure method of
        transmitting information. Accordingly, while we seek to protect your
        personal information by implementing digital security systems in various
        parts of our platform, we cannot accept responsibility for the security
        of information you send to or receive from us over the Internet or for
        any unauthorised access or use of that information. Where we have links
        to platforms outside the Group, we cannot ensure that your privacy will
        be protected in accordance with this policy. You should consult these
        other platforms' privacy policies as we have no control over them and
        are not responsible for any information that is submitted to or
        collected by these third parties.
      </p>
      <h2 className="section-title">
        12.0 Transfer of Personal Information Overseas
      </h2>
      <p className="section-text">
        We do not currently transfer to or store personal information in
        countries outside of Australia. We do not currently intend to do so in
        the future. If our policy in this regard changes in the future, we would
        update this policy so please check it from time to time.
      </p>
      <h2 className="section-title">13.0 Access and Correction</h2>
      <p className="section-text">
        We take reasonable steps to make sure that the personal information we
        collect, use and disclose is accurate, complete and up-to-date. You may
        request access to the information we hold about you by contacting us as
        set out below.
      </p>
      <p className="section-text">
        We will assume (unless you tell us otherwise) that your request for
        access relates to our current records about you. These current records
        include personal information about you which is included in our
        databases and in paper files, and which may be used by us on a day to
        day basis. We may, but are not obliged to, archive any out of date
        personal information.
      </p>
      <p className="section-text">
        If you believe that your personal information is not accurate, complete
        or up to date, please provide your request for correction. We will
        consider your requests for correction in a timely manner. We reserve the
        right to charge an administrative fee for access and updating requests.
      </p>

      <p className="section-text">
        You should note that Group may reject a request to access your personal
        information if:
        <ol className="lettered-list">
          <li>the request is frivolous or vexatious</li>
          <li>
            providing access would have an unreasonable impact on the privacy of
            another person
          </li>
          <li>
            providing access would pose a serious and imminent threat to the
            life or health of any person
          </li>
          <li>providing access would prejudice Group’s legal rights</li>
          <li>there are other legal grounds to deny the request</li>
        </ol>
      </p>

      <h2 className="section-title">14.0 Links</h2>
      <p className="section-text">
        Our platform may contain links to platforms operated by third parties.
        Those links are provided for convenience and may not remain current or
        be maintained. Unless expressly stated otherwise, we are not responsible
        for the privacy practices of, or any content on, those linked platforms,
        and have no control over or rights in those linked platforms. The
        privacy policies that apply to those other platforms may differ
        substantially from our Policy, so we encourage individuals to read them
        before using those platforms.
      </p>

      <h2 className="section-title">15.0 Privacy Complaints</h2>
      <p className="section-text">
        If you think that Group has breached an APP or if you are dissatisfied
        with the manner in which your Personal Information has been dealt with,
        you should contact Group directly my email at ceo@schedit.com.au. Your
        complaint should include a brief description of your privacy concern,
        any action or dealings you have had with Group staff members regarding
        your privacy complaint and your contact details. Please allow Group 30
        days to provide a response. If you are dissatisfied with our response
        you may lodge a complaint with the Office of the Australian Information
        Commissioner (OAIC) about your privacy concerns.
      </p>

      <p className="section-text">
        Complaints to the OAIC must be made in writing. The OAIC can receive
        privacy complaints through the following:
      </p>

      <h3 className="sub-section-title">
        Office of the Australian Privacy Commissioner
      </h3>
      <ul className="bullet-list">
        <li>
          <strong>Online form:</strong> OAIC Privacy Complaint Form
        </li>
        <li>
          <strong>Email:</strong> enquiries@oaic.gov.au
        </li>
        <li>
          <strong>Post:</strong> The Privacy Commissioner, Office of the
          Australian Commissioner, GPO Box 2999, CANBERRA ACT 2601
        </li>
        <li>
          <strong>Facsimile:</strong> (02) 9284 9666
        </li>
      </ul>

      {/* <p className="section-text">
        Alternatively, you can contact the Group at:
      </p>
      <ul className="bullet-list">
        <li>
          <strong>Phone:</strong> **insert phone**
        </li>
        <li>
          <strong>Email:</strong> **insert email**
        </li>
        <li>
          <strong>Post:</strong> **insert address**
        </li>
      </ul> */}
    </div>
  );
};

export default PrivacyPolicy;
