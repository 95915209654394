import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminApi from "../../api/AdminApi";
import SubcontractorSidebar from "../../components/Sidebar/SubcontractorSidebar";
import { Divider } from "../../components/Common/Divider";
import { Button } from "../../components/Button/Button";
import CommonTable from "../../components/Table/CommonTable";
import SubcontractorHeader from "../../components/Header/SubcontractorHeader";
import CustomPagination from "../../components/Pagination/CustomPagination";

import AddSubcontractorPopup from "../../components/Subcontractor/AddSubcontractorPopup";

const SubcontractorContainer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [subContractors, setSubcontractors] = useState([]);
  const [tblSubcontractors, setTblSubcontractors] = useState([]);

  const { adminId } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    fetchSubcontractorsData();
  }, []);

  useEffect(() => {
    const sub = subContractors.map((subContractor, index) => ({
      index: index + 1,
      name: subContractor.name,
      address: subContractor.address,
      businessCode: subContractor.businessCode,
      phone: subContractor.contactPhone,
    }));
    setTblSubcontractors(sub);
  }, [subContractors]);

  const fetchSubcontractorsData = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    try {
      const response = await AdminApi.fetchAllSubcontractors(formData);
      if (response.status === 200) {
        setSubcontractors(response.data.response.subContractors);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onClickSubcontractorDetails = (e, index) => {
    const sub = subContractors[index - 1];
    // navigate("/client-details/" + client.id);
  };

  const columns = [
    {
      title: "S.No",
      field: "index",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Sub-Contractor",
      field: "name",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Address",
      field: "address",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "ABN",
      field: "businessCode",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Phone",
      field: "phone",
      emptyValue: () => <div>-</div>,
    },
  ];

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const handleOnCancelClick = () => {
    setIsOpen(false);
  };

  const handleOnContinueClick = () => {
    setIsOpen(false);
    fetchSubcontractorsData();
  };

  return (
    <>
      <div className="cstm-contant-main">
        <div className="cstm-contant-main-cont">
          <SubcontractorSidebar />
          <div className="cstm-contant-main-cont-all">
            <div className="cstm-contant-main-cont-all-main">
              <div className="cstm-cont-header">
                <SubcontractorHeader
                  child={"Sub-Contractors"}
                  buttonchild={<></>}
                />

                <div className="cstm-cont-header-btn">
                  <Button onClick={() => togglePopup()}>
                    Add Sub-Contractor
                  </Button>
                </div>
              </div>

              <Divider />

              <div className="cstm-contant-main-cont-table">
                <div>
                  <CommonTable
                    apiData={tblSubcontractors}
                    columns={columns}
                    options={{
                      emptyRowsWhenPaging: false,
                      thirdSortClick: false,
                      paginationPosition: "top",
                      search: false,
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 30],
                      draggable: false,
                      search: false,
                    }}
                    onRowClick={(e, rowData) => {
                      onClickSubcontractorDetails(e, rowData.index);
                    }}
                    toolbarData={{
                      Pagination: (props) => {
                        let paginationprops = props;
                        paginationprops = {
                          ...paginationprops,
                          label: "Sub-Contractors showing : ",
                          positionPagination: "top",
                        };

                        return <CustomPagination {...paginationprops} />;
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <AddSubcontractorPopup
            onCancelClick={handleOnCancelClick}
            onDataSubmit={handleOnContinueClick}
          />
        )}
      </div>
    </>
  );
};

export default SubcontractorContainer;
