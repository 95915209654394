import { Box, ImageListItem, Typography } from '@mui/material'
import React from 'react'
import { images } from '../../img'
import { Link } from 'react-router-dom'

const NotificationsItemMentioned = ({ details }) => {
  return (
    <>
      <Box
        sx={{
          '& a': {
            display: 'flex',
            alignItems: 'start',
            textDecoration: 'none'
          }
        }}>
        <Link >
          <Box className="user-img" sx={{
            flexShrink: '0'
          }}>
            <ImageListItem sx={{
              '& img': {
                maxWidth: '100%',
                height: '42px',
                width: '42px',
                borderRadius: '50%'
              }
            }}>
              <img src={details.userImg} width={42} height={42} alt="user-img" />
            </ImageListItem>
          </Box>
          <Box sx={{
            marginLeft: '12px'
          }}>
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'baseline'
            }}>
              <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: '400', color: '#344054' }}>{details.name}</Typography>
              <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: '400', color: '#667085', ml: '8px' }}>{details.time}</Typography>
            </Box>
            <Typography variant="body1" sx={{
              fontSize: '16px',
              fontWeight: '400',
              color: '#667085',
            }}>{details.text}</Typography>
          </Box>

        </Link>
      </Box>
    </>
  )
}

export default NotificationsItemMentioned