import { Box } from '@mui/material'
import React from 'react'

const Notifications = ({ children }) => {
  return (
    <>
      <Box
        className='custom-scrollbar'
        sx={{
          position: 'sticky',
          top: '25px',
          width: { md: '326px', sm: '306px', xs: '100%' },
          maxWidth: { sm: 'fit-content', xs: '400px' },
          flexShrink: 0,
          margin: { sm: '27px 25px', xs: '' },
          marginLeft: 0,
        }}>
        <Box sx={{
          margin: { sm: 0, xs: '15px' },
          borderRadius: '16px',
          background: "rgba(255, 255, 255, 0.50)",
          boxShadow: "0px 4px 59px 0px rgba(50, 50, 71, 0.06), 0px 4px 103px 0px rgba(50, 50, 71, 0.01)",
          padding: '16px',
          backgroundColor: "#fff",
        }}>
          {children}
        </Box>
      </Box>
    </>
  )
}

export default Notifications