import { useRef } from "react";
import HomeNavbar from "../components/HomeNavbar/HomeNavbar";
import HomePage from "../components/pages/HomePage";
import HomeFormContainerComponent from "../components/Home/HomeFormContainerComponent";
import HomeFormFooterComponent from "../components/Home/HomeFormFooterComponent";

const HomeContainer = () => {
  const requestDemoFormRef = useRef(null);

  const scrollToRequestDemoForm = () => {
    requestDemoFormRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <HomeNavbar onNavbarRequestDemoClicked={scrollToRequestDemoForm} />
      <HomePage onRequestDemoClick={scrollToRequestDemoForm} />
      <div ref={requestDemoFormRef}>
        <HomeFormContainerComponent />
      </div>
      <div>
        <HomeFormFooterComponent />
      </div>
    </>
  );
};

export default HomeContainer;
