import { Button } from "../Button/Button";
import "../Button/Button.css";

const NoSubcontractorFoundComponent = ({ onInviteClick }) => {
  return (
    <div>
      <span style={{ color: "#eb2727" }} className="InputTitles">
        No Sub-contractor found.
      </span>

      <Button
        styles={{ marginTop: "4px" }}
        onClick={onInviteClick}
        buttonStyle="btn--outline"
      >
        Invite
      </Button>
    </div>
  );
};

export default NoSubcontractorFoundComponent;
