import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import LeftSidebar from "../LeftSIdebar/LeftSidebar";
import { Link } from "react-router-dom";
import { ReactComponent as AddFile } from "../../img/addFile.svg";

const CompanyDashboardLeftSidebar = ({ title }) => {
  const buttonsList = [
    {
      name: "Customer",
      link: "/customer",
    },
    {
      name: "Finance Entry",
      link: "/finance-entry",
    },
  ];

  return (
    <>
      <LeftSidebar title={title}>
        <Box
          sx={{
            padding: "30px 0 32px",
          }}
        >
          <Box>
            <Accordion
              sx={{
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                id="panel-header"
                aria-controls="panel-content"
                sx={{
                  boxShadow: "2px 2px 1px 0px rgba(0, 0, 0, 0.04)",
                  backgroundColor: "#fff",
                  minHeight: "auto !important",
                  padding: "16px 27px",
                  borderRadius: "6px",
                  "& .MuiAccordionSummary-content": {
                    margin: "0 !important",
                  },
                  "&.Mui-expanded": {
                    backgroundColor: "#E5F1FF",
                    "& h6 span": {
                      color: "#0164C6",
                    },
                    "& svg path": {
                      fill: "#0164C6",
                    },
                  },
                  "& svg path": {
                    fill: "#3C3C3C80",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  color="initial"
                  sx={{
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AddFile />
                  <Typography
                    variant="caption"
                    color="initial"
                    ml={"10px"}
                    lineHeight={"1"}
                    fontSize={"inherit"}
                  >
                    Add new
                  </Typography>
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "0",
                }}
              >
                <Box
                  className="buttons"
                  sx={{
                    padding: "20px 30px",
                    display: "flex",
                    flexDirection: "column",
                    "& a": {
                      backgroundImage:
                        "linear-gradient(135deg, #4896DC 0%, #1D78CE 100%)",
                      fontFamily: "Roboto , Helvetica , Arial,  sans-serif",
                      padding: "11px 25px",
                      borderRadius: "6px",
                      fontSize: "16px",
                      color: "#fff",
                      textDecoration: "none",
                      marginBottom: "15px",
                      "&:last-child": {
                        mb: 0,
                      },
                    },
                  }}
                >
                  {buttonsList.map((item, index) => {
                    return (
                      <Link key={index} to={item.link}>
                        {item.name}
                      </Link>
                    );
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </LeftSidebar>
    </>
  );
};

export default CompanyDashboardLeftSidebar;
