// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-menu {
  width: 200px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
}

.dropdown-menu li {
  background: #1888ff;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #5cabff;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}
@media screen and (max-width: 1023px) {
  .dropdown-menu {
    top: 98px;
  }
}
@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
  .dropdown-menu {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/HomeNavbar/Dropdown.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,WAAW;EACX,qBAAqB;EACrB,WAAW;EACX,aAAa;AACf;AACA;EACE;IACE,SAAS;EACX;AACF;AACA;EACE;IACE,aAAa;EACf;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".dropdown-menu {\n  width: 200px;\n  position: absolute;\n  top: 80px;\n  list-style: none;\n  text-align: start;\n}\n\n.dropdown-menu li {\n  background: #1888ff;\n  cursor: pointer;\n}\n\n.dropdown-menu li:hover {\n  background: #5cabff;\n}\n\n.dropdown-menu.clicked {\n  display: none;\n}\n\n.dropdown-link {\n  display: block;\n  height: 100%;\n  width: 100%;\n  text-decoration: none;\n  color: #fff;\n  padding: 16px;\n}\n@media screen and (max-width: 1023px) {\n  .dropdown-menu {\n    top: 98px;\n  }\n}\n@media screen and (max-width: 960px) {\n  .fa-caret-down {\n    display: none;\n  }\n  .dropdown-menu {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
