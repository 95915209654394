import { useState, useRef } from "react";

import SupportForm from "./SupportForm";

import HomeNavbar from "../../components/HomeNavbar/HomeNavbar";
import HomeFormContainerComponent from "../../components/Home/HomeFormContainerComponent";

const SupportPage = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Support Request:", { title, description });
  };

  const requestDemoFormRef = useRef(null);

  const scrollToBottom = () => {
    requestDemoFormRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <HomeNavbar onNavbarRequestDemoClicked={scrollToBottom} />
      {/* <div className="support-page">
        <h1>Support Request</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter your title"
            />
          </div>
          <div>
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter your detailed description"
              rows="5"
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div> */}
      <SupportForm />
      <div ref={requestDemoFormRef}>
        <HomeFormContainerComponent />
      </div>
    </>
  );
};

export default SupportPage;
