import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import AdminApi from "../../api/AdminApi";

import CommonTable from "../../components/Table/CommonTable";
import CustomPagination from "../../components/Pagination/CustomPagination";
import { Divider } from "../../components/Common/Divider";
import CeoHeader from "./CeoHeader";
import AddCustomerPopup from "./AddCustomerPopup";

const CeoDashboard = () => {
  const { adminId, accessToken, role } = useSelector((state) => state.auth);

  const [isOpen, setIsOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [tblCustomers, setTblCustomers] = useState([]);

  useEffect(() => {
    fetchCustomersData();
  }, []);

  useEffect(() => {
    const org = customers.map((organisation, index) => ({
      index: index + 1,
      name: organisation.name,
      address: organisation.address,
      businessCode: organisation.businessCode,
      phone: organisation.contactPhone,
    }));
    setTblCustomers(org);
  }, [customers]);

  const fetchCustomersData = async () => {
    try {
      const response = await AdminApi.allOrganisations();
      if (response.status === 200) {
        setCustomers(response.data.response.organisations);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
      localStorage.clear();
      window.location.href = "/login";
    }
  };

  const columns = [
    {
      title: "S.No",
      field: "index",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Customer",
      field: "name",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Address",
      field: "address",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "ABN",
      field: "businessCode",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Phone",
      field: "phone",
      emptyValue: () => <div>-</div>,
    },
  ];

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="cstm-contant-main">
      <div className="cstm-contant-main-cont">
        <div className="cstm-contant-main-cont-all">
          <div className="cstm-contant-main-cont-all-main">
            <CeoHeader onAddCustomerClick={() => togglePopup()} />
            <Divider />
            <div className="cstm-contant-main-cont-table">
              <div>
                <CommonTable
                  apiData={tblCustomers}
                  columns={columns}
                  options={{
                    thirdSortClick: false,
                    emptyRowsWhenPaging: false,
                    paginationPosition: "top",
                    search: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    draggable: false,
                    search: false,
                  }}
                  onRowClick={(e, rowData) => {
                    //   onClickSubcontractorDetails(e, rowData.index);
                  }}
                  toolbarData={{
                    Pagination: (props) => {
                      let paginationprops = props;
                      paginationprops = {
                        ...paginationprops,
                        label: "Customers showing : ",
                        positionPagination: "top",
                      };

                      return <CustomPagination {...paginationprops} />;
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <AddCustomerPopup
          onCancelClick={() => {
            setIsOpen(false);
          }}
          onDataSubmit={() => {
            setIsOpen(false);
            fetchCustomersData();
          }}
        />
      )}
    </div>
  );
};

export default CeoDashboard;
