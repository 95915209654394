import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateRangePicker.css";

const DateRangePicker = ({ onApply }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (onApply) {
      onApply(dates);
    }
  };

  const CustomInputField = forwardRef(({ value, onClick }, ref) => (
    <div>
      <input
        className="calendar-input"
        type="text"
        placeholder="Select the date"
        onClick={onClick}
        ref={ref}
        readOnly={true}
        value={value}
      />
    </div>
  ));

  return (
    <div>
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          handleDateChange(update);
        }}
        placeholderText="Select a date"
        customInput={<CustomInputField />}
        isClearable={true}
        dateFormat="dd/MM/yyyy"
      />
    </div>
  );
};

export default DateRangePicker;
