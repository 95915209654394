import { Divider } from "../../components/Common/Divider";
import { FinanceDashboardCardBox } from "./FinanceCards";
import FinanceDashboardGraphs from "./FinanceGraphs";
import "./FinanceCard.css";

const FinanceDashboard = () => {
	return (
		<div className="main-container-page">
			<div className="main-sub-container-page">
				<h1>Finances</h1>
			</div>
			<div className="divider">
				<Divider />
			</div>
			<div className="main-sub-container-page">
				<div className="dropdown-selector">
					<select className="select-all">
						<option>Select the period</option>
					</select>
				</div>
				<div className="secondary-header">Statistics</div>
				<div className="secondary-subheader">
					Lorem ipsum dolor sit amet consectetur. Massa erat interdum id a est
					massa.
				</div>
				<div className="graph-container">
					<FinanceDashboardGraphs />
				</div>
				<div>
					<FinanceDashboardCardBox />
				</div>
			</div>
		</div>
	);
};

export default FinanceDashboard;
