import CircularProgress from "@mui/material/CircularProgress";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader-component">
        <CircularProgress
          size={70}
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,
          }}
        />
      </div>
    </div>
  );
};

export default Loader;
