import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Calendar from "../../components/Calendar/Calendar";
import CreateShiftPopup from "../../components/Shift/CreateShiftPopup";

import AdminApi from "../../api/AdminApi";

const Roster = () => {
  const [shifts, setShifts] = useState([]);
  const [events, setEvents] = useState([]);

  const [isCreateShiftOpen, setIsCreateShiftOpen] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const { adminId } = useSelector((state) => state.auth);

  useEffect(() => {
    fetchShiftsData();
  }, []);

  useEffect(() => {
    const tempEvents = [];
    shifts.forEach((shift) => {
      const start = new Date(shift.startTime);
      const end = new Date(shift.endTime);

      if (start.getDate() !== end.getDate()) {
        // Shift spans across two days, create two separate events
        const event1 = {
          id: shift.shiftId,
          title: shift.clientName,
          start: start,
          end: new Date(
            start.getFullYear(),
            start.getMonth(),
            start.getDate(),
            23,
            59,
            59,
            999
          ),
        };

        const event2 = {
          id: shift.shiftId,
          title: shift.clientName,
          start: new Date(
            end.getFullYear(),
            end.getMonth(),
            end.getDate(),
            0,
            0,
            0
          ),
          end: end,
        };

        // Push both events to the events array
        tempEvents.push(event1, event2);
      } else {
        const event = {
          id: shift.shiftId,
          title: shift.clientName,
          start: start,
          end: end,
        };

        tempEvents.push(event);
      }
    });
    // const eventTemp = shifts.map((shift, index) => ({
    //   id: shift.shiftId,
    //   start: new Date(shift.startTime),
    //   end: new Date(shift.endTime),
    //   title: shift.clientName,
    // }));
    setEvents(tempEvents);
  }, [shifts]);

  const handleSelectedSlot = (slotInfo) => {
    console.log(slotInfo);
    setStartTime(slotInfo.start.getTime());
    setIsCreateShiftOpen(true);
  };

  const handleSelectedEvent = (eventInfo) => {
    window.open(`/shift-details/${eventInfo.id}`, "_blank");
  };

  const handleOnCancelClick = () => {
    setIsCreateShiftOpen(false);
  };

  const handleOnContinueClick = () => {
    fetchShiftsData();
    setIsCreateShiftOpen(false);
  };

  const fetchShiftsData = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    try {
      const response = await AdminApi.fetchAllShifts(formData);
      if (response.status === 200) {
        setShifts(response.data.response.shifts);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="cstm-contant-main">
        <div>
          <Calendar
            style={{ height: "91.5vh" }}
            events={events}
            selectable={true}
            onSelectSlot={handleSelectedSlot}
            onSelectEvent={handleSelectedEvent}
          />
        </div>
        {isCreateShiftOpen && (
          <CreateShiftPopup
            onCancelClick={() => handleOnCancelClick()}
            onDataSubmit={() => handleOnContinueClick()}
            presetStartTime={startTime}
            presetEndTime={null}
          />
        )}
      </div>
    </>
  );
};

export default Roster;
