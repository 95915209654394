import { useState, useEffect } from "react";
import React from "react";
import { useSelector } from "react-redux";
import "../../components/Employee/Employee.css";
import AdminApi from "../../api/AdminApi";
import { Button } from "../Button/Button";
import "../Button/Button.css";

import AssignShiftPopup from "./AssignShiftPopup";
import DateTimeTextPicker from "../DateTime/DateTimeTextPicker";

const CreateShiftPopup = ({
  onCancelClick,
  onDataSubmit,
  presetStartTime,
  presetEndTime,
}) => {
  const handleKeyPress = (event) => {
    if (event.keyCode === 27) {
      onCancelClick();
    }
  };
  const [locationDetails, setLocationDetails] = useState("");
  const [description, setDescription] = useState("");

  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const [selectedSite, setSelectedSite] = useState({});
  const [sites, setSites] = useState([]);

  const [startHour, setStartHour] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [endMinute, setEndMinute] = useState(0);
  const [breakDurationHour, setBreakDurationHour] = useState(0);
  const [breakDurationMinute, setBreakDurationMinute] = useState(0);
  const [welfareDurationHour, setWelfareDurationHour] = useState(0);
  const [welfareDurationMinute, setWelfareDurationMinute] = useState(0);

  const [isBreakAllowed, setIsBreakAllowed] = useState(false);
  const [isWelfareRequired, setIsWelfareRequired] = useState(false);

  const [shiftCreated, setShiftCreated] = useState(false);
  const [shiftId, setShiftId] = useState(null);

  var startTimeUTC = presetStartTime ? presetStartTime : 0;
  var endTimeUTC = presetEndTime ? presetEndTime : 0;
  var duration = presetEndTime ? presetEndTime - presetStartTime : 0;

  const { adminId } = useSelector((state) => state.auth);

  useEffect(() => {
    fetchAllClients();
  }, []);

  useEffect(() => {
    fetchSitesForClient();
  }, [selectedClient]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const fetchAllClients = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    try {
      const response = await AdminApi.fetchAllClients(formData);
      if (response.status === 200) {
        setClients(response.data.response.clients);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSitesForClient = async () => {
    if (selectedClient !== null) {
      const formData = new FormData();
      formData.append("adminId", adminId);
      formData.append("clientId", selectedClient.id);
      try {
        const response = await AdminApi.fetchAllSitesForClient(formData);
        if (response.status === 200) {
          setSites(response.data.response.sites);
        } else {
          localStorage.clear();
          window.location.href = "/login";
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const createShift = async () => {
    const breakDuration =
      (breakDurationHour * 60 + breakDurationMinute) * 60 * 1000;
    const welfareDuration =
      (welfareDurationHour * 60 + welfareDurationMinute) * 60 * 1000;
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("siteId", selectedSite.siteId);
    formData.append("startTime", startTimeUTC);
    formData.append("endTime", endTimeUTC);
    formData.append("duration", duration);
    formData.append("allowedBreakTime", breakDuration);
    formData.append("locationDetails", locationDetails);
    formData.append("description", description);
    formData.append("welfareDuration", welfareDuration);

    try {
      const response = await AdminApi.createShift(formData);
      if (response.status === 200) {
        setShiftCreated(true);
        setShiftId(response.data.response.shiftId);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const currentDate = presetStartTime ? new Date(presetStartTime) : new Date();
  const yearRange = Array.from(
    { length: 20 },
    (_, i) => i + currentDate.getFullYear()
  );

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth());
  const [selectedDay, setSelectedDay] = useState(currentDate.getDate());

  const daysRange = () => {
    var monthLength = 0;
    switch (months[selectedMonth]) {
      case "January":
      case "March":
      case "May":
      case "July":
      case "August":
      case "October":
      case "December":
        monthLength = 31;
        break;
      case "February":
        if (selectedYear % 4 === 0) {
          monthLength = 29;
        } else {
          monthLength = 28;
        }
        break;
      default:
        monthLength = 30;
        break;
    }
    return Array.from({ length: monthLength }, (_, i) => i + 1);
  };

  const onClientOptionChangeHandler = (event) => {
    const client = clients[event.target.value];
    if (client !== undefined) {
      setSelectedClient(client);
    } else {
      setSelectedClient({});
      setSites([]);
    }
  };

  const onSiteOptionChangeHandler = (event) => {
    const site = sites[event.target.value];
    setSelectedSite(site);
  };

  const onStartTimeSelectedHandler = (time) => {
    if (time !== null) {
      setStartHour(time.hours);
      setStartMinute(time.minutes);
    }
  };

  const onDurationSelectedHandler = (time) => {
    if (time !== null) {
      setEndHour(time.hours);
      setEndMinute(time.minutes);
    }
  };

  const onBreakDurationSelectedHandler = (time) => {
    if (time !== null) {
      setBreakDurationHour(time.hours);
      setBreakDurationMinute(time.minutes);
    }
  };

  const welfareDuratiionSelectedHandler = (time) => {
    if (time !== null) {
      setWelfareDurationHour(time.hours);
      setWelfareDurationMinute(time.minutes);
    }
  };

  const submitFormData = (e) => {
    e.preventDefault();

    if (
      selectedClient === undefined ||
      selectedClient === null ||
      Object.keys(selectedClient).length === 0
    ) {
      alert("Please select a client");
      return;
    }

    if (
      selectedSite === undefined ||
      selectedSite === null ||
      Object.keys(selectedSite).length === 0
    ) {
      alert("Please select site");
      return;
    }
    const selectedDate = new Date(
      selectedYear,
      selectedMonth,
      selectedDay,
      startHour,
      startMinute
    );

    if (currentDate > selectedDate) {
      alert("Shift date/time cannot be in the past");
      return;
    }

    duration = (endHour * 60 + endMinute) * 60 * 1000;

    if (duration === 0) {
      alert("Shift duration cannot be zero");
      return;
    }

    startTimeUTC = selectedDate.getTime();
    endTimeUTC = startTimeUTC + duration;

    createShift();
  };

  return (
    <div>
      {!shiftCreated && (
        <div className="AddEmployeePopupViewContainer">
          <form className="CreateShiftPopupContainer" onSubmit={submitFormData}>
            <span className="AddEmployeePopupTitle">Create shift</span>
            <div className="InputContainer">
              <div style={{ display: "flex", gap: "12px" }}>
                {/* Client selector start*/}
                <div className="drop-down-with-text-container">
                  <span className="InputTitles">Choose a client</span>

                  <select
                    style={{ marginTop: "8px" }}
                    className="select-all cstm-lastdays"
                    onChange={onClientOptionChangeHandler}
                  >
                    <option>--</option>
                    {clients.map((client, index) => {
                      return (
                        <option key={index} value={index}>
                          {client.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {/* Site selector end*/}

                {/* Site selector start*/}
                {sites.length !== 0 && (
                  <div className="drop-down-with-text-container">
                    <span className="InputTitles">Choose a site</span>

                    <select
                      style={{ marginTop: "8px" }}
                      className="select-all cstm-lastdays"
                      onChange={onSiteOptionChangeHandler}
                    >
                      <option>--</option>
                      {sites.map((site, index) => {
                        return (
                          <option key={index} value={index}>
                            {site.siteName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
                {/* Site selector end*/}
              </div>

              {/* shift structure selector start*/}
              <div style={{ marginTop: "24px" }}>
                <div style={{ display: "flex", gap: "30px" }}>
                  <div className="drop-down-with-text-container">
                    <span className="InputTitles-NoWidth">
                      Choose a shift start time
                    </span>

                    <div
                      style={{ display: "flex", marginTop: "8px", gap: "8px" }}
                    >
                      <DateTimeTextPicker
                        onTimeSelected={onStartTimeSelectedHandler}
                        presetTime={
                          startTimeUTC
                            ? `${currentDate.getHours()}${currentDate.getMinutes()}`
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="drop-down-with-text-container">
                    <span className="InputTitles-NoWidth">
                      Choose shift duration
                    </span>

                    <div
                      style={{ display: "flex", marginTop: "8px", gap: "8px" }}
                    >
                      <DateTimeTextPicker
                        onTimeSelected={onDurationSelectedHandler}
                      />
                    </div>
                  </div>

                  {isBreakAllowed && (
                    <div className="drop-down-with-text-container">
                      <span className="InputTitles-NoWidth">
                        Choose break duration
                      </span>

                      <div
                        style={{
                          display: "flex",
                          marginTop: "8px",
                          gap: "8px",
                        }}
                      >
                        <DateTimeTextPicker
                          onTimeSelected={onBreakDurationSelectedHandler}
                        />
                      </div>
                    </div>
                  )}

                  {isWelfareRequired && (
                    <div className="drop-down-with-text-container">
                      <span className="InputTitles-NoWidth">
                        Choose welfare interval
                      </span>

                      <div
                        style={{
                          display: "flex",
                          marginTop: "8px",
                          gap: "8px",
                        }}
                      >
                        <DateTimeTextPicker
                          onTimeSelected={welfareDuratiionSelectedHandler}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ display: "flex", gap: "16px", marginTop: "8px" }}>
                  <div>
                    <label className="InputTitles-NoWidth">
                      <input
                        style={{ marginRight: "4px" }}
                        type="checkbox"
                        checked={isBreakAllowed}
                        onChange={(e) => setIsBreakAllowed(e.target.checked)}
                      />
                      Is break allowed?
                    </label>
                  </div>
                  <div>
                    <label className="InputTitles-NoWidth">
                      <input
                        style={{ marginRight: "4px" }}
                        type="checkbox"
                        checked={isWelfareRequired}
                        onChange={(e) => setIsWelfareRequired(e.target.checked)}
                      />
                      Is welfare required?
                    </label>
                  </div>
                </div>
              </div>

              {/* shift structure selector end*/}

              <span className="InputTitles" style={{ marginTop: "24px" }}>
                Date
              </span>

              {/* year selector start*/}
              <div style={{ display: "flex", gap: "12px" }}>
                <div className="drop-down-with-text-container">
                  <select
                    style={{ marginTop: "8px" }}
                    className="select-all cstm-lastdays"
                    onChange={(e) => setSelectedYear(e.target.value)}
                    defaultValue={selectedYear}
                  >
                    {yearRange.map((year, index) => {
                      return (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* month selector start*/}

                <div className="drop-down-with-text-container">
                  <select
                    style={{ marginTop: "8px" }}
                    className="select-all cstm-lastdays"
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    defaultValue={selectedMonth}
                  >
                    {months.map((month, index) => {
                      return (
                        <option key={index} value={index}>
                          {month}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* day selector start*/}

                <div className="drop-down-with-text-container">
                  <select
                    style={{ marginTop: "8px" }}
                    className="select-all cstm-lastdays"
                    onChange={(e) => setSelectedDay(e.target.value)}
                    defaultValue={selectedDay}
                  >
                    {daysRange().map((day, index) => {
                      return (
                        <option key={index} value={day}>
                          {day}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {/* day selector end*/}

              {/* location details starts*/}
              <div style={{ marginTop: "29px" }}>
                <div style={{ display: "flex" }}>
                  <span className="InputTitles">Location details</span>
                </div>

                <div style={{ display: "flex", marginTop: "5px" }}>
                  <input
                    className="Input-Full"
                    type="text"
                    value={locationDetails}
                    onChange={(e) => setLocationDetails(e.target.value)}
                  />
                </div>
              </div>
              {/* location details end*/}

              {/* description starts*/}
              <div style={{ marginTop: "16px" }}>
                <div style={{ display: "flex" }}>
                  <span className="InputTitles">Description</span>
                </div>

                <div style={{ display: "flex", marginTop: "5px" }}>
                  <input
                    className="Input-Full"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignSelf: "flex-end",
                  marginTop: "32px",
                }}
              >
                <Button
                  onClick={onCancelClick}
                  buttonStyle="btn--outline"
                  styles={{
                    marginLeft: "auto",
                  }}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  styles={{
                    marginLeft: "12px",
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}

      {shiftCreated && (
        <AssignShiftPopup
          shiftId={shiftId}
          shiftStartTime={startTimeUTC}
          shiftEndTime={endTimeUTC}
          onCancelClick={onDataSubmit}
          onDataSubmit={onDataSubmit}
        />
      )}
    </div>
  );
};

export default CreateShiftPopup;
