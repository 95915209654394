import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminApi from "../../api/AdminApi";
import ClientsSidebar from "../../components/Sidebar/ClientsSidebar";
import { Divider } from "../../components/Common/Divider";
import { Button } from "../../components/Button/Button";
import CommonTable from "../../components/Table/CommonTable";
import ClientsHeader from "../../components/Header/ClientsHeader";
import AddClientPopup from "../../components/Client/AddClientPopup";
import CustomPagination from "../../components/Pagination/CustomPagination";

const ClientsContainer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [tblClients, setTblClients] = useState([]);

  const { adminId } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    fetchClientsData();
  }, []);

  useEffect(() => {
    const cl = clients.map((client, index) => ({
      index: index + 1,
      name: client.name,
      address: client.address,
      businessCode: client.businessCode,
      phone: client.contactPhone,
    }));
    setTblClients(cl);
  }, [clients]);

  const fetchClientsData = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    try {
      const response = await AdminApi.fetchAllClients(formData);
      if (response.status === 200) {
        setClients(response.data.response.clients);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onClickClientDetails = (e, index) => {
    const client = clients[index - 1];
    navigate("/client-details/" + client.id);
  };

  const columns = [
    {
      title: "S.No",
      field: "index",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Client",
      field: "name",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Address",
      field: "address",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "ABN",
      field: "businessCode",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Phone",
      field: "phone",
      emptyValue: () => <div>-</div>,
    },
  ];

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const handleOnCancelClick = () => {
    setIsOpen(false);
  };

  const handleOnContinueClick = () => {
    setIsOpen(false);
    fetchClientsData();
  };

  return (
    <>
      <div className="cstm-contant-main">
        <div className="cstm-contant-main-cont">
          <ClientsSidebar />
          <div className="cstm-contant-main-cont-all">
            <div className="cstm-contant-main-cont-all-main">
              <div className="cstm-cont-header">
                <ClientsHeader child={"Clients"} buttonchild={<></>} />

                <div className="cstm-cont-header-btn">
                  <Button onClick={() => togglePopup()}>Add Client</Button>
                </div>
              </div>

              <Divider />

              <div className="cstm-contant-main-cont-table">
                <div>
                  <CommonTable
                    apiData={tblClients}
                    columns={columns}
                    options={{
                      thirdSortClick: false,
                      paginationPosition: "top",
                      search: false,
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 30],
                      draggable: false,
                      search: false,
                      emptyRowsWhenPaging: false,
                    }}
                    onRowClick={(e, rowData) => {
                      onClickClientDetails(e, rowData.index);
                    }}
                    toolbarData={{
                      Pagination: (props) => {
                        let paginationprops = props;
                        paginationprops = {
                          ...paginationprops,
                          label: "Clients showing : ",
                          positionPagination: "top",
                        };

                        return <CustomPagination {...paginationprops} />;
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <AddClientPopup
            onCancelClick={() => {
              setIsOpen(false);
            }}
            onDataSubmit={() => {
              setIsOpen(false);
              fetchClientsData();
            }}
          />
        )}
      </div>
    </>
  );
};

export default ClientsContainer;
