import { useState } from "react";

import "../../components/Home/Home.css";
import "../../components/Home/Form.css";
import { Button } from "../../components/Button/Button";
import "../../components/Button/Button.css";

const SupportForm = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const submitFormData = (e) => {
    e.preventDefault();

    if (!title || !description) {
      alert("Enter missing fields");
    }
    console.log("Support Request:", { title, description });
  };

  return (
    <div className="form-container">
      <form onSubmit={submitFormData}>
        <div
          style={{
            display: "flex",
            backgroundColor: "#fafafa",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="form-hint-text">
              NAME <span className="form-asterisk">*</span>
            </span>
            <input
              name="name"
              className="home-page-form-text-box"
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter support title"
            />
          </div>

          <div
            style={{
              marginTop: "2.4vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span className="form-hint-text">
              DESCRIPTION <span className="form-asterisk">*</span>
            </span>
            <input
              name="description"
              className="home-page-form-text-box"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter your detailed description"
              rows="5"
            />
          </div>
        </div>
        <Button
          type="submit"
          buttonStyle="btn--home"
          styles={{ marginTop: "5.12vh" }}
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default SupportForm;
