import "./ShiftDetails.css";

const ShiftCard = ({ shiftDetails }) => {
  return (
    <div className="shift-card-frame">
      <div className="background" />
      <div className="details">
        <div className="div">
          <div className="item">
            <div className="text-wrapper">{shiftDetails.siteName}</div>
            <div className="text-wrapper-2">Site</div>
          </div>
          <div className="item-2">
            <div className="text-wrapper">{shiftDetails.clientName}</div>
            <div className="text-wrapper-3">Client</div>
          </div>
        </div>
        <div className="div">
          <div className="item-3">
            <p className="text-wrapper">{shiftDetails.locationDetails}</p>
            <div className="text-wrapper-4">Location Details</div>
          </div>
          <div className="item-4">
            <p className="text-wrapper">{shiftDetails.description}</p>
            <div className="text-wrapper-5">Description</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftCard;
