export const colors = {
    basics: {
        theme: `linear-gradient(180.53deg, #A175FF 7.8%, #563AFF 119.47%)`,
        primary: '#00bbff',
        white: '#FFFFFF',
        black: "#141414",
        danger: "#E33437"
    },
    dark: {
        800: '#0D0D0D',
        600: '#1D1D1D',
        200: '#343434',
        100: '#393939',
    },
    gray: {
        500: '#7D7D7D',
        300: '#A9A9A9',
    },
};