import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const EmployeeDetailSidebar = ({
  options,
  selectedOption,
  setSelectedOption,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="cstm-contant-ed-left">
        <div onClick={() => navigate("/employees")} className="back-link">
          <ArrowBack /> Back to all employees
        </div>

        <ul className="cstm-sidebar-edsd">
          {options.map((option, index) => {
            return (
              <li
                key={index}
                className={
                  selectedOption.key === option.key ? "cstm-edsd-active" : ""
                }
                onClick={() => setSelectedOption(option)}
              >
                <KeyboardDoubleArrowRight />
                <span className="cstm-sidebar-edsd-title">{option.label}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default EmployeeDetailSidebar;
