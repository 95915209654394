import { Button } from "../../components/Button/Button";

const CeoHeader = ({ onAddCustomerClick }) => {
  return (
    <div className="cstm-cont-header">
      <div className="cstm-cont-header-title">
        <h1>Customers</h1>
      </div>

      <div className="cstm-cont-header-btn">
        <Button onClick={onAddCustomerClick}>
          <i className="fa fa-user-plus"></i> Add Customer
        </Button>
      </div>
    </div>
  );
};

export default CeoHeader;
