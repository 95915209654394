// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Center the title */
.terms-conditions-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  text-align: center;
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #333;
}

/* Section titles */
.section-title {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #444;
}

/* Sub-section titles */
.sub-section-title {
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
  color: #555;
}

/* Section text */
.section-text {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #666;
}

/* List styling */
.lettered-list {
  list-style-type: lower-alpha; /* Displays a, b, c... */
  padding-left: 20px; /* Indent the list */
  margin-bottom: 20px;
}

.lettered-list li {
  margin-bottom: 10px; /* Add spacing between list items */
  color: #666; /* Same color as section text for consistency */
}

/* Bullet list styling */
.bullet-list {
  list-style-type: disc;
  padding-left: 20px; /* Indent the list */
  margin-bottom: 20px;
}

.bullet-list li {
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/TermsAndConditions/TermsAndConditions.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;AACb;;AAEA,mBAAmB;AACnB;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;AACb;;AAEA,uBAAuB;AACvB;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;AACb;;AAEA,iBAAiB;AACjB;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;AACb;;AAEA,iBAAiB;AACjB;EACE,4BAA4B,EAAE,wBAAwB;EACtD,kBAAkB,EAAE,oBAAoB;EACxC,mBAAmB;AACrB;;AAEA;EACE,mBAAmB,EAAE,mCAAmC;EACxD,WAAW,EAAE,+CAA+C;AAC9D;;AAEA,wBAAwB;AACxB;EACE,qBAAqB;EACrB,kBAAkB,EAAE,oBAAoB;EACxC,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["/* Center the title */\n.terms-conditions-container {\n  max-width: 800px;\n  margin: 0 auto;\n  padding: 20px;\n}\n\n.title {\n  text-align: center;\n  font-size: 32px;\n  margin-bottom: 20px;\n  font-weight: bold;\n  color: #333;\n}\n\n/* Section titles */\n.section-title {\n  font-size: 24px;\n  margin-top: 20px;\n  margin-bottom: 10px;\n  color: #444;\n}\n\n/* Sub-section titles */\n.sub-section-title {\n  font-size: 20px;\n  margin-top: 15px;\n  margin-bottom: 10px;\n  color: #555;\n}\n\n/* Section text */\n.section-text {\n  font-size: 16px;\n  line-height: 1.6;\n  margin-bottom: 20px;\n  color: #666;\n}\n\n/* List styling */\n.lettered-list {\n  list-style-type: lower-alpha; /* Displays a, b, c... */\n  padding-left: 20px; /* Indent the list */\n  margin-bottom: 20px;\n}\n\n.lettered-list li {\n  margin-bottom: 10px; /* Add spacing between list items */\n  color: #666; /* Same color as section text for consistency */\n}\n\n/* Bullet list styling */\n.bullet-list {\n  list-style-type: disc;\n  padding-left: 20px; /* Indent the list */\n  margin-bottom: 20px;\n}\n\n.bullet-list li {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
