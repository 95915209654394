import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import { ArrowBack } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ClientDetailsSidebar = ({ isSelectMenu, setIsSelectMenu }) => {
  const navigate = useNavigate();
  const menuItems = [
    { name: "Profile Overview" },
    { name: "Contracts" },
    { name: "Sites" },
    { name: "Invoices" },
    { name: "Shifts" },
  ];
  return (
    <>
      <div className="cstm-contant-ed-left">
        <div onClick={() => navigate("/clients")} className="back-link">
          <ArrowBack /> Back to all clients
        </div>

        <ul className="cstm-sidebar-edsd">
          {menuItems.map((item, index) => {
            return (
              <li
                key={index}
                className={isSelectMenu === item.name ? "cstm-edsd-active" : ""}
                onClick={() => setIsSelectMenu(item.name)}
              >
                <KeyboardDoubleArrowRight />
                <span className="cstm-sidebar-edsd-title">{item.name}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default ClientDetailsSidebar;
