import React, { Component } from "react";
import { Button } from "../Button/Button";
import { MenuItems } from "./MenuItems";
import Logo from "../../img/logo.png";
import { Link } from "react-router-dom";
import "./Navbar.css";
import AdminApi from "../../api/AdminApi";

class Navbar extends Component {
  state = {
    clicked: false,
  };

  handleClick = () => {
    this.setState({
      clicked: !this.state.clicked,
    });
  };


  render() {
    return (
      <nav className="NavbarItems">
        <Link to="/" className="navbar-logo">
          <img
            style={{
              marginTop: "10px",
              width: "30vh",
            }}
            src={Logo}
          />
        </Link>
        <div className="menu-icon" onClick={this.handleClick}>
          <i
            className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
          ></i>
        </div>
        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
          {MenuItems.map((item, index) => {
            return (
              <li key={index}>
                <Link className={item.cName} to={item.url}>
                  {item.title}
                </Link>
              </li>
            );
          })}
        </ul>
        <Button onClick={AdminApi.logoutAdmin}>Log Out</Button>
      </nav>
    );
  }
}

export default Navbar;
