import { Button } from "../Button/Button";

const EmployeeHeader = ({ onAddEmployeeClick }) => {
  return (
    <div className="cstm-cont-header">
      <div className="cstm-cont-header-title">
        <h1>Employees</h1>
      </div>

      <div className="cstm-cont-header-btn">
        {/* Need to add it later TODO */}
        {/* <Button buttonStyle="btn--outline">
            <i className="fa fa-users"></i> Bulk Import
          </Button> */}
        <Button onClick={onAddEmployeeClick}>
          <i className="fa fa-user-plus"></i> Add Employee
        </Button>
      </div>
    </div>
  );
};

export default EmployeeHeader;
