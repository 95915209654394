import { Box } from "@mui/material";
import React from "react";
import CompanyDashboardLeftSidebar from "../../components/Sidebar/CompanyDashboardLeftSidebar";
import CompanyDashboardCenterView from "./CompanyDashboardCenter/CompanyDashboardCenterView";


const CompanyDashboard = () => {


  return (
    <>
      <Box
        sx={{
          paddingTop: "60px",
        }}
      >
        <Box sx={{
          display: { lg: 'flex' },
          height: '100%',
        }}>

          {/* Left Sidebar */}
          <CompanyDashboardLeftSidebar title={'CEO/Suresight team'} />

          {/* Dashboard Center Box */}
          <CompanyDashboardCenterView />
        </Box>

      </Box>
    </>
  );
};

export default CompanyDashboard;
