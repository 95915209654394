// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../img/arrow.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker-wrapper {
  display: inline-block;
  border-radius: 20px;
}

.react-datepicker__input-container {
  border-radius: 20px;
}

.react-datepicker__day {
  margin: 0 !important;
  padding: 0 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  border-radius: 0 !important;
}

.react-datepicker__day--selected {
  background-color: #007bff;
  color: white;
}

.react-datepicker__day--in-range {
  background-color: #e3f5ff;
  color: black;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: #0164c6;
  color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.react-datepicker__day--today {
  color: #0080ff;
}

.calendar-input {
  width: 417px;
  height: 38px;
  padding: 0px 16px;
  border-radius: 5px;
  border: solid 0.5px #707070;
  background-color: #fff;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-size: 11px;
  background-position-y: 50%;
  color: #000;
  font-family: Helvetica;
  font-size: 13px;
}

.calendar-input::placeholder {
  color: rgba(112, 112, 112, 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/components/DateTime/DateRangePicker.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;;;;EAIE,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;;EAEE,yBAAyB;EACzB,YAAY;EACZ,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,2BAA2B;EAC3B,sBAAsB;EACtB,oEAAqD;EACrD,4BAA4B;EAC5B,0BAA0B;EAC1B,qBAAqB;EACrB,0BAA0B;EAC1B,WAAW;EACX,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".react-datepicker-wrapper {\n  display: inline-block;\n  border-radius: 20px;\n}\n\n.react-datepicker__input-container {\n  border-radius: 20px;\n}\n\n.react-datepicker__day {\n  margin: 0 !important;\n  padding: 0 !important;\n}\n\n.react-datepicker__day--selected,\n.react-datepicker__day--in-range,\n.react-datepicker__day--range-start,\n.react-datepicker__day--range-end {\n  border-radius: 0 !important;\n}\n\n.react-datepicker__day--selected {\n  background-color: #007bff;\n  color: white;\n}\n\n.react-datepicker__day--in-range {\n  background-color: #e3f5ff;\n  color: black;\n}\n\n.react-datepicker__day--range-start,\n.react-datepicker__day--range-end {\n  background-color: #0164c6;\n  color: white;\n  border-top-left-radius: 10px;\n  border-bottom-left-radius: 10px;\n}\n\n.react-datepicker__day--today {\n  color: #0080ff;\n}\n\n.calendar-input {\n  width: 417px;\n  height: 38px;\n  padding: 0px 16px;\n  border-radius: 5px;\n  border: solid 0.5px #707070;\n  background-color: #fff;\n  background-image: url(../../img/arrow.png) !important;\n  background-repeat: no-repeat;\n  background-position-x: 95%;\n  background-size: 11px;\n  background-position-y: 50%;\n  color: #000;\n  font-family: Helvetica;\n  font-size: 13px;\n}\n\n.calendar-input::placeholder {\n  color: rgba(112, 112, 112, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
