import EmployeeDetailsSidebarData from "./EmployeeDetailsSidebarData";

const EmployeeDetails = ({ employeeDetails }) => {
  return (
    <>
      <EmployeeDetailsSidebarData employeeDetails={employeeDetails} />
    </>
  );
};

export default EmployeeDetails;
