import { Box, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const NotificationsItem = ({ notificationDetails }) => {
  return (
    <>
      <Box sx={{
        '& a': {
          display: 'flex',
          alignItems: 'start',
          textDecoration: 'none'
        }
      }}>
        <Link to={notificationDetails.LinkURL}>
          <Box className="notification_box" sx={{ width: '24px', height: '24px', backgroundColor: '#E3F5FF', flexShrink: '0', borderRadius: '8px' }}></Box>
          <Box sx={{ marginLeft: '8px' }}>
            <Typography variant="body1" sx={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "1.4",
              color: '#1C1C1C',
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              overflow: "hidden"
            }} >{notificationDetails.text}</Typography>
            <Typography variant="caption" sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "1.5",
              color: 'rgba(0, 0, 0, 0.40)'
            }} >{notificationDetails.time}</Typography>
          </Box>

        </Link>
      </Box>
    </>
  )
}

export default NotificationsItem