import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Divider } from "../../components/Common/Divider";
import { Button } from "../../components/Button/Button";
import "../../components/Button/Button.css";
import DateRangePicker from "../../components/DateTime/DateRangePicker";
import Dropdown from "../../components/Dropdown/Dropdown";
import CommonTable from "../../components/Table/CommonTable";

import CustomPagination from "../../components/Pagination/CustomPagination";

import AdminApi from "../../api/AdminApi";

const GenerateClientInvoice = () => {
  const { adminId } = useSelector((state) => state.auth);

  const [clients, setClients] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [shiftsForInvoice, setShiftsForInvoice] = useState([]);
  const [tblShifts, setTblShifts] = useState([]);

  const [selectedShiftIds, setSelectedShiftIds] = useState([]);

  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    setTblShifts(
      shiftsForInvoice.map((shift, index) => {
        return {
          index: index + 1,
          shiftId: shift.shiftId,
          siteName: shift.siteName,
          shiftDate: formateShiftDate(shift.startTime),
          shiftTime: formatShiftTime(shift.startTime, shift.endTime),
        };
      })
    );
  }, [shiftsForInvoice]);

  function formateShiftDate(dateInMillis) {
    const startTime = new Date(dateInMillis);
    const dayOfWeek = startTime.toLocaleDateString("en-US", {
      weekday: "short",
    });
    const day = String(startTime.getDate()).padStart(2, "0");
    const month = String(startTime.getMonth() + 1).padStart(2, "0");
    const year = String(startTime.getFullYear()).slice(-2);

    const formattedDate = `${dayOfWeek}, ${day}/${month}/${year}`;
    return formattedDate;
  }

  function formatShiftTime(startTimeInMillis, endTimeInMillis) {
    const startTime = new Date(startTimeInMillis);
    const endTime = new Date(endTimeInMillis);

    const formatTime = (time) => {
      const hours = String(time.getHours()).padStart(2, "0");
      const minutes = String(time.getMinutes()).padStart(2, "0");
      return `${hours}${minutes}`;
    };

    const startFormatted = formatTime(startTime);
    const endFormatted = formatTime(endTime);

    const durationMs = endTime - startTime;
    const durationHours = Math.floor(durationMs / (1000 * 60 * 60));

    const shiftTimings = `${startFormatted} - ${endFormatted}, ${durationHours}hrs`;
    return shiftTimings;
  }

  const fetchClients = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    try {
      const response = await AdminApi.fetchAllClients(formData);
      if (response.status === 200) {
        setClients(response.data.response.clients);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchShiftsForInvoice = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("clientId", selectedClientId);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    try {
      const response = await AdminApi.fetchAllShiftsForInvoice(formData);
      if (response.status === 200) {
        setShiftsForInvoice(response.data.response.shifts);
        setShowTable(true);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const generateInvoice = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("clientId", selectedClientId);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("shiftIds", selectedShiftIds);
    try {
      const response = await AdminApi.generateInvoice(formData);
      if (response.status >= 200 && response.status < 300) {
        alert("Invoice successfully created");
        fetchShiftsForInvoice();
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGenerateShiftListButtonClick = () => {
    if (!selectedClientId) {
      alert("Please select a client");
      return;
    }

    if (!startDate) {
      alert("Please choose a starting date");
      return;
    }

    if (!endDate) {
      alert("Please choose an ending date");
      return;
    }

    fetchShiftsForInvoice();
  };

  const handleDateSelected = (selectedDates) => {
    const [start, end] = selectedDates;
    setStartDate(start ? start.getTime() : null);
    setEndDate(end ? end.getTime() : null);
  };

  const handleClientSelected = (clientId) => {
    setSelectedClientId(clientId);
  };

  const handleTableRowsSelected = (rows) => {
    const shiftIds = rows.map((row) => row.shiftId);
    setSelectedShiftIds(shiftIds);
  };

  const handleGenerateInvoiceClicked = () => {
    if (selectedShiftIds.length === 0) {
      alert("No shifts selected");
      return;
    }

    generateInvoice();
  };

  const columns = [
    {
      title: "S.No",
      field: "index",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Site Name",
      field: "siteName",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Shift Date",
      field: "shiftDate",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Shift Time",
      field: "shiftTime",
      emptyValue: () => <div>-</div>,
    },
  ];

  return (
    <div className="cstm-contant-main-cont-all-main">
      <div className="cstm-cont-header">
        <div className="cstm-cont-header-title">
          <h1>Client - Generate Invoices</h1>
        </div>
      </div>
      <Divider />
      <div className="cstm-contant-main-cont-table">
        <div className="cstm-contant-main-cont-table-header">
          <div className="cstm-contant-main-cont-table-header-top">
            <div className="cstm-contant-main-cont-table-header-top-left">
              <Dropdown
                options={clients.map((client) => ({
                  value: client.id,
                  label: client.name,
                }))}
                placeholder={"Select the client"}
                onChange={handleClientSelected}
              />

              <div
                style={{
                  zIndex: 1000,
                }}
              >
                {selectedClientId && (
                  <DateRangePicker onApply={handleDateSelected} />
                )}
              </div>
            </div>
            <div className="cstm-contant-main-cont-table-header-top-right">
              <Button
                styles={{
                  width: "190px",
                }}
                onClick={handleGenerateShiftListButtonClick}
              >
                Generate Shift List
              </Button>
            </div>
          </div>
        </div>

        {showTable && (
          <CommonTable
            columns={columns}
            apiData={tblShifts}
            options={{
              emptyRowsWhenPaging: false,
              paginationPosition: "top",
              search: false,
              pageSize: 10,
              pageSizeOptions: [10, 20, 30],
              draggable: false,
              exportButton: false,
              thirdSortClick: false,
              maxBodyHeight: "40vh",
            }}
            isSelectable={tblShifts.length > 0}
            onSelectionChange={handleTableRowsSelected}
            toolbarData={{
              Pagination: (props) => {
                let paginationprops = props;
                paginationprops = {
                  ...paginationprops,
                  label: "Shifts showing : ",
                  positionPagination: "top",
                };

                return <CustomPagination {...paginationprops} />;
              },
            }}
          />
        )}

        {selectedShiftIds.length > 0 && (
          <Button
            styles={{
              marginTop: "36px",
              width: "190px",
            }}
            onClick={handleGenerateInvoiceClicked}
          >
            Generate Invoice
          </Button>
        )}
      </div>
    </div>
  );
};

export default GenerateClientInvoice;
