import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Divider } from "../../components/Common/Divider";
import { Button } from "../../components/Button/Button";
import "../../components/Button/Button.css";
import DateRangePicker from "../../components/DateTime/DateRangePicker";
import Dropdown from "../../components/Dropdown/Dropdown";
import CommonTable from "../../components/Table/CommonTable";

import CustomPagination from "../../components/Pagination/CustomPagination";

import AdminApi from "../../api/AdminApi";

const GenerateEmployeePayslip = () => {
  const { adminId } = useSelector((state) => state.auth);

  const [employees, setEmployees] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [shiftsForPayslip, setShiftsForPayslip] = useState([]);
  const [tblShifts, setTblShifts] = useState([]);

  const [selectedShiftIds, setSelectedShiftIds] = useState([]);

  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    fetchEmployees();
  }, []);

  useEffect(() => {
    setTblShifts(
      shiftsForPayslip.map((shift, index) => {
        return {
          index: index + 1,
          shiftId: shift.shiftId,
          siteName: shift.siteName,
          shiftDate: formateShiftDate(shift.startTime),
          shiftTime: formatShiftTime(shift.startTime, shift.endTime),
        };
      })
    );
  }, [shiftsForPayslip]);

  function formateShiftDate(dateInMillis) {
    const startTime = new Date(dateInMillis);
    const dayOfWeek = startTime.toLocaleDateString("en-US", {
      weekday: "short",
    });
    const day = String(startTime.getDate()).padStart(2, "0");
    const month = String(startTime.getMonth() + 1).padStart(2, "0");
    const year = String(startTime.getFullYear()).slice(-2);

    const formattedDate = `${dayOfWeek}, ${day}/${month}/${year}`;
    return formattedDate;
  }

  function formatShiftTime(startTimeInMillis, endTimeInMillis) {
    const startTime = new Date(startTimeInMillis);
    const endTime = new Date(endTimeInMillis);

    const formatTime = (time) => {
      const hours = String(time.getHours()).padStart(2, "0");
      const minutes = String(time.getMinutes()).padStart(2, "0");
      return `${hours}${minutes}`;
    };

    const startFormatted = formatTime(startTime);
    const endFormatted = formatTime(endTime);

    const durationMs = endTime - startTime;
    const durationHours = Math.floor(durationMs / (1000 * 60 * 60));

    const shiftTimings = `${startFormatted} - ${endFormatted}, ${durationHours}hrs`;
    return shiftTimings;
  }

  const fetchEmployees = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    try {
      const response = await AdminApi.fetchAllEmployees(formData);
      if (response.status === 200) {
        setEmployees(response.data.response.employees);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchShiftsForPayslip = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("employeeId", selectedEmployeeId);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    try {
      const response = await AdminApi.fetchAllShiftsForPayslip(formData);
      if (response.status === 200) {
        setShiftsForPayslip(response.data.response.shifts);
        setShowTable(true);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const generatePayslip = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("employeeId", selectedEmployeeId);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("shiftIds", selectedShiftIds);
    try {
      const response = await AdminApi.generatePayslip(formData);
      if (response.status >= 200 && response.status < 300) {
        alert("Payslip successfully created");
        fetchShiftsForPayslip();
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGenerateShiftListButtonClick = () => {
    if (!selectedEmployeeId) {
      alert("Please select an employee");
      return;
    }

    if (!startDate) {
      alert("Please choose a starting date");
      return;
    }

    if (!endDate) {
      alert("Please choose an ending date");
      return;
    }

    fetchShiftsForPayslip();
  };

  const handleDateSelected = (selectedDates) => {
    const [start, end] = selectedDates;
    setStartDate(start ? start.getTime() : null);
    setEndDate(end ? end.getTime() : null);
  };

  const handleEmployeeSelected = (employeeId) => {
    setSelectedEmployeeId(employeeId);
  };

  const handleTableRowsSelected = (rows) => {
    const shiftIds = rows.map((row) => row.shiftId);
    setSelectedShiftIds(shiftIds);
  };

  const handleGeneratePayslipClicked = () => {
    if (selectedShiftIds.length === 0) {
      alert("No shifts selected");
      return;
    }

    generatePayslip();
  };

  const columns = [
    {
      title: "S.No",
      field: "index",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Site Name",
      field: "siteName",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Shift Date",
      field: "shiftDate",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Shift Time",
      field: "shiftTime",
      emptyValue: () => <div>-</div>,
    },
  ];

  return (
    <div className="cstm-contant-main-cont-all-main">
      <div className="cstm-cont-header">
        <div className="cstm-cont-header-title">
          <h1>Employee - Generate Payslips</h1>
        </div>
      </div>
      <Divider />
      <div className="cstm-contant-main-cont-table">
        <div className="cstm-contant-main-cont-table-header">
          <div className="cstm-contant-main-cont-table-header-top">
            <div className="cstm-contant-main-cont-table-header-top-left">
              <Dropdown
                options={employees.map((employee) => ({
                  value: employee.employeeId,
                  label: `${employee.firstName} ${employee.lastName}`,
                }))}
                placeholder={"Select the employee"}
                onChange={handleEmployeeSelected}
              />

              <div
                style={{
                  zIndex: 1000,
                }}
              >
                {selectedEmployeeId && (
                  <DateRangePicker onApply={handleDateSelected} />
                )}
              </div>
            </div>
            <div className="cstm-contant-main-cont-table-header-top-right">
              <Button
                styles={{
                  width: "190px",
                }}
                onClick={handleGenerateShiftListButtonClick}
              >
                Generate Shift List
              </Button>
            </div>
          </div>
        </div>

        {showTable && (
          <CommonTable
            columns={columns}
            apiData={tblShifts}
            options={{
              emptyRowsWhenPaging: false,
              paginationPosition: "top",
              search: false,
              pageSize: 10,
              pageSizeOptions: [10, 20, 30],
              draggable: false,
              exportButton: false,
              thirdSortClick: false,
              maxBodyHeight: "40vh",
            }}
            isSelectable={tblShifts.length > 0}
            onSelectionChange={handleTableRowsSelected}
            toolbarData={{
              Pagination: (props) => {
                let paginationprops = props;
                paginationprops = {
                  ...paginationprops,
                  label: "Shifts showing : ",
                  positionPagination: "top",
                };

                return <CustomPagination {...paginationprops} />;
              },
            }}
          />
        )}

        {selectedShiftIds.length > 0 && (
          <Button
            styles={{
              marginTop: "36px",
              width: "190px",
            }}
            onClick={handleGeneratePayslipClicked}
          >
            Generate Payslip
          </Button>
        )}
      </div>
    </div>
  );
};

export default GenerateEmployeePayslip;
