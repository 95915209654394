import "react-big-calendar/lib/css/react-big-calendar.css";

import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

const Calendar = (props) => {
  const localizer = momentLocalizer(moment);
  const formats = {
    timeGutterFormat: "HHmm", // 24-hour clock format
    eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, "HHmm", culture)} - ${localizer.format(
        end,
        "HHmm",
        culture
      )}`,
  };
  return (
    <>
      <BigCalendar {...props} formats={formats} localizer={localizer} />
    </>
  );
};

export default Calendar;
