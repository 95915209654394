import React, { useState } from "react";
import {
  Box,
  Button,
  Fade,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { ReactComponent as DropDownArrow } from "../../../../img/DropdownDownArrow.svg";
import CompanyLineChart from "./CompanyStatisticsChart/CompanyLineChart";
import { images } from "../../../../img";
import CompanyGradientLineChart from "./CompanyStatisticsChart/CompanyGradientLineChart";
import RadialBarChart from "../../../Dashboard/DashboardCenterView/DashboardCenterComponents/StatisticsChart/RadialBarChart";

const CompanyDashboardStatistics = () => {
  const [menu, setMenu] = useState(null);
  const [selectVal, setSelectVal] = useState("This month");
  const open = Boolean(menu);

  const dropDownList = ["This Month", "This Week", "Last Week", "Last Month"];

  const handleClick = (event) => {
    setMenu(event.currentTarget);
  };
  const handleClose = () => {
    setMenu(null);
  };
  const handleSelect = (value) => {
    setMenu(null);
    setSelectVal(value);
  };
  const btnStyle = {
    padding: "0",
    color: "#5B5E6F",
    fontWeight: "600",
    flexShrink: "0",
    "& .MuiTouchRipple-root .MuiTouchRipple-child": {
      backgroundColor: "#E3F5FF !important",
    },
    "& svg": {
      ml: "5px",
      transition: "all .5s ease",
      transform: `rotate(${open ? "180" : "0"}deg)`,
    },
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
  };
  const chartData = [
    { name: "Earnings", y: 150, color: "#1F4C74" },
    { name: "Payments", y: 100, color: "#BDE3FF" },
  ];
  return (
    <>
      <Box className="section-spacing">
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            pb: "24px",
          }}
        >
          <Box className="text-wrapper">
            <Typography variant="h3">Statistics</Typography>
            <Typography variant="body2">
              Lorem ipsum dolor sit amet consectetur. Massa erat interdum id a
              est massa.
            </Typography>
          </Box>
          <Button
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={btnStyle}
          >
            {selectVal}
            <DropDownArrow />
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{ "aria-labelledby": "fade-button" }}
            anchorEl={menu}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            {dropDownList.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    handleSelect(item);
                  }}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Menu>
        </Box>

        <Grid
          container
          spacing={"25px"}
          sx={{
            "& .highcharts-container ": {
              width: "100% !important",
            },
          }}
        >
          <Grid item xxl={4} lg={12} md={6} xs={12}>
            <Box
              sx={{
                borderRadius: "20px",
                boxShadow:
                  "0 4px 59px rgba(50, 50, 71, 0.06), 0 4px 103px rgba(50, 50, 71, 0.01)",
                overflow: "hidden",
                height: "250px",
                padding: "20px 20px 0",
                "& > div": {
                  height: "100%",
                },
                "& .highcharts-title": {
                  fontSize: "14px !important",
                  fontFamily: "Inter",
                  fontWeight: "500 !important",
                  color: "#272830 !important",
                  fill: "#272830 !important",
                },
                "& .highcharts-subtitle": {
                  fontSize: "28px !important",
                  fontFamily: "Poppins",
                  fontWeight: "600 !important",
                  color: "#272830 !important",
                  fill: "#272830 !important",
                },
                "& .highcharts-button-symbol": {
                  width: "28px",
                  height: "24px",
                },
                "& svg": {
                  width: "100%",
                  height: "auto",
                },
                "& .highcharts-button-box": {
                  fill: "transparent !important",
                },
              }}
            >
              <CompanyLineChart
                title="Clients"
                subTitle="920"
                exports={true}
                data={[10, 80, 60, 55, 80, 140, 0]}
              />
            </Box>
          </Grid>
          <Grid item xxl={8} lg={12} md={6} xs={12}>
            <RadialBarChart chartData={chartData} />
          </Grid>
          <Grid item xxl={4} lg={12} md={6} xs={12}>
            <Box
              sx={{
                borderRadius: "20px",
                boxShadow:
                  "0 4px 59px rgba(50, 50, 71, 0.06), 0 4px 103px rgba(50, 50, 71, 0.01)",
                overflow: "hidden",
                height: "250px",
                padding: "20px 20px 0",
                backgroundColor: "#0C6CF2",
                backgroundImage: `url(${images.chartBg})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                "& > div": {
                  height: "100%",
                },
                "& .highcharts-title": {
                  fontSize: "14px !important",
                  fontFamily: "Inter",
                  fontWeight: "500 !important",
                  color: "#fff !important",
                  fill: "#fff !important",
                },
                "& .highcharts-subtitle": {
                  fontSize: "28px !important",
                  fontFamily: "Poppins",
                  fontWeight: "600 !important",
                  color: "#fff !important",
                  fill: "#fff !important",
                },
                "& .highcharts-button-box": {
                  fill: "#ffffff00",
                },
                "& .highcharts-button-symbol": {
                  width: "28px",
                  height: "24px",
                },
                "& svg": {
                  width: "100%",
                  height: "auto",
                },
                "& .highcharts-button-box": {
                  fill: "transparent !important",
                },
              }}
            >
              <CompanyGradientLineChart
                title="Employees"
                subTitle="9.9K"
                exports={true}
                data={[10, 20, 30, 40, 50, 40, 30]}
              />
            </Box>
          </Grid>
          <Grid item xxl={8} lg={12} md={6} xs={12}>
            <Box
              sx={{
                borderRadius: "20px",
                boxShadow:
                  "0 4px 59px rgba(50, 50, 71, 0.06), 0 4px 103px rgba(50, 50, 71, 0.01)",
                overflow: "hidden",
                height: "250px",
                padding: "20px 20px 0",
                "& > div": {
                  height: "100%",
                },
                "& .highcharts-title": {
                  fontSize: "14px !important",
                  fontFamily: "Inter",
                  fontWeight: "500 !important",
                  color: "#272830 !important",
                  fill: "#272830 !important",
                },
                "& .highcharts-subtitle": {
                  fontSize: "50px !important",
                  fontFamily: "Poppins",
                  fontWeight: "600 !important",
                  color: "#272830 !important",
                  fill: "#272830 !important",
                },
                "& .highcharts-button-symbol": {
                  width: "28px",
                  height: "24px",
                },
                "& svg": {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              <CompanyLineChart
                title="Profit/Loss"
                crosshair={true}
                subTitle="16050 AUD"
                exports={false}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CompanyDashboardStatistics;
