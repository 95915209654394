import { Box } from "@mui/material";
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const ColumnChart = ({ data }) => {
  var perShapeGradient = {
    x1: 0,
    y1: 0,
    x2: 1,
    y2: 0,
  };

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      title: {
        text: null,
      },
      gridLineWidth: 0,
      lineWidth: 0,
    },
    colors: [
      {
        linearGradient: perShapeGradient,
        stops: [
          [0.8, "#0164C6"],
          [0, "#1B59F8 "],
        ],
      },
    ],
    yAxis: {
      min: 0,
      title: {
        enabled: false,
      },
      labels: {
        overflow: "justify",
      },
      gridLineWidth: 1,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      colorByPoint: true,
      series: {
        pointWidth: 20,
        borderRadius: "50%",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Year 1990",
        showInLegend: false,
        data: data,
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            plotOptions: {
              series: {
                pointWidth: 10,
              },
            },
          },
        },
      ],
    },
  };

  return (
    <>
      <Box
        sx={{
          mt: "30px",
          "& .highcharts-container": {
            width: "100% !important",
          },
          "& .highcharts-xaxis-labels text": {
            color: "#838383 !important",
            fontSize: "12px !important",
            fontWeight: "500 !important",
            fill: "#838383 !important",
          },
          "& .highcharts-yaxis-labels text": {
            color: "#00000080 !important",
            fontSize: "14px !important",
            fontWeight: "600 !important",
            fill: "#00000080 !important",
          },
          "& svg": {
            width: "100%",
            height: "auto",
          },
          "& .highcharts-button-box": {
            fill: "transparent !important",
          },
        }}
      >
        <HighchartsReact highcharts={Highcharts} options={options} />
      </Box>
    </>
  );
};

export default ColumnChart;
