import { Box } from '@material-ui/core'
import React from 'react'
import Typography from '@mui/material/Typography'
import NotificationsItem from '../../../components/Notifications/NotificationsItem'
import { Tab, Tabs } from '@mui/material'
import NotificationsItemMentioned from '../../../components/Notifications/NotificationsItemMentioned'
import { images } from '../../../img'
import Notifications from '../../../components/Notifications/Notifications'

const CompanyDashboardNotification = () => {
  const [value, setValue] = React.useState(0);


  /* Dummy Notification data Start */
  const notificationData = {
    text: 'You have a bug that needs to be fixed.',
    time: '59 minutes ago'
  }

  const feedData = {
    name: '@DercoMani',
    userImg: images.user,
    time: '59 minutes ago',
    text: 'Edited the details of Project X.'
  }
  /* Dummy Notification data End */

  /* Tabbing Start */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{
            padding: '0 0 20px',
            '&> div:not(:last-child)': {
              marginBottom: '24px'
            },
          }}>
            {children}
          </Box>
        )}
      </Box>
    );
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  /* Tabbing End */



  return (
    <>
      <Notifications>
        <Box>
          <Typography variant="h3" sx={{
            fontFamily: "Helvetica",
            fontSize: "16px",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.19,
            letterSpacing: "0.32px",
            textAlign: "left",
            color: "#000"
          }} fontWeight={500} color="initial">Notifications</Typography>
          <Box sx={{
            mt: '16px',
            '&> div:not(:last-child)': {
              marginBottom: '8px'
            },
            '&> div:nth-child(odd) a .notification_box': {
              backgroundColor: '#E3F5FF'
            },
            '&> div:nth-child(even) a .notification_box': {
              backgroundColor: '#E5ECF6'
            }
          }}>
            <NotificationsItem notificationDetails={notificationData} />
            <NotificationsItem notificationDetails={notificationData} />
            <NotificationsItem notificationDetails={notificationData} />
            <NotificationsItem notificationDetails={notificationData} />
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
              sx={{
                margin: '24px 0',
                '& .MuiButtonBase-root ': {
                  minHeight: 'auto',
                  color: '#00000080 !important',
                  textTransform: 'unset',
                  fontSize: '16px',
                  flexBasis: '50%'
                },
                '& .Mui-selected': {
                  color: '#082567 !important'
                },
                '& .MuiTabs-indicator ': {
                  backgroundColor: '#082567'
                }

              }}>
              <Tab label="Activity feed"  {...a11yProps(0)} />
              <Tab label="Client" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <NotificationsItemMentioned details={feedData} />
            <NotificationsItemMentioned details={feedData} />
            <NotificationsItemMentioned details={feedData} />
            <NotificationsItemMentioned details={feedData} />
            <NotificationsItemMentioned details={feedData} />
            <NotificationsItemMentioned details={feedData} />
            <NotificationsItemMentioned details={feedData} />
            <NotificationsItemMentioned details={feedData} />
            <NotificationsItemMentioned details={feedData} />
            <NotificationsItemMentioned details={feedData} />
            <NotificationsItemMentioned details={feedData} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <NotificationsItemMentioned details={feedData} />
            <NotificationsItemMentioned details={feedData} />
            <NotificationsItemMentioned details={feedData} />
          </CustomTabPanel>
        </Box>
      </Notifications>



    </>
  )
}

export default CompanyDashboardNotification