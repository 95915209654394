import { useState } from "react";
import "../../components/Employee/Employee.css";
import { Button } from "../Button/Button";
import "../Button/Button.css";
import { Divider } from "../../components/Common/Divider";
import AddressAutocomplete from "../AddressAutocomplete/AddressAutocomplete";

const EnterClientDetails = ({ onCancelClick, onDataSubmit }) => {
  const [clientName, setClientName] = useState("");
  const [businessCode, setBusinessCode] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const submitFormData = (e) => {
    e.preventDefault();

    if (!clientName || !businessCode || !phone || !address) {
      alert("Please add missing fields");
      return;
    }

    onDataSubmit({ clientName, businessCode, phone, address });
  };

  const handlePlaceSelect = (location) => {
    setAddress(location.address);
  };

  return (
    <div className="AddEmployeePopupViewContainer">
      <form className="AddEmployeePopupContainer" onSubmit={submitFormData}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="AddEmployeePopupTitle">Add Client</span>
          <span className="cstm-pfo-title">Step 1/3 - Client Information</span>
        </div>
        <Divider />
        <div className="InputContainer">
          <div style={{ marginTop: "24px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                Client name <span style={{ color: "#eb2727" }}>*</span>
              </span>
              <span className="InputTitles" style={{ marginLeft: "16px" }}>
                Contact phone <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input"
                type="text"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
              />
              <input
                className="Input"
                type="text"
                style={{ marginLeft: "11px" }}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>

          <div style={{ marginTop: "24px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                ABN/ACN <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input"
                type="text"
                value={businessCode}
                onChange={(e) => setBusinessCode(e.target.value)}
              />
            </div>
          </div>

          <div style={{ marginTop: "29px" }}>
            <span className="InputTitles">
              Address <span style={{ color: "#eb2727" }}>*</span>
            </span>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <AddressAutocomplete
                className="Input"
                type="text"
                style={{ width: "78%" }}
                selected={address}
                onChange={handlePlaceSelect}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignSelf: "flex-end",
              marginTop: "32px",
            }}
          >
            <Button
              onClick={onCancelClick}
              buttonStyle="btn--outline"
              styles={{
                marginLeft: "auto",
              }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              styles={{
                marginLeft: "12px",
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EnterClientDetails;
