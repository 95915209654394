import "./ShiftDetails.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "../../components/Button/Button";
import { Delete } from "@mui/icons-material";

import AdminApi from "../../api/AdminApi";

const ShiftEmployeeInput = ({
  shiftDetails,
  assignShiftClicked,
  onShiftUnassigned,
}) => {
  const { adminId } = useSelector((state) => state.auth);

  const subContractor =
    shiftDetails.subContractor !== undefined
      ? shiftDetails.subContractor
      : null;
  const employee =
    shiftDetails.employee !== undefined ? shiftDetails.employee : null;

  const navigate = useNavigate();

  async function unAssignShift() {
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("shiftId", shiftDetails.shiftId);

    try {
      const response = await AdminApi.unAssignShift(formData);
      if (response.status >= 200 && response.status < 300) {
        onShiftUnassigned();
      }
    } catch (error) {
      navigate("/shifts");
    }
  }

  return (
    <div className="shift-employee-input">
      <div className="text-form">
        <div className="select">
          <div className="label">
            <div className="text-wrapper">Shift assignment details</div>
          </div>
          <div className="employee-details">
            <div className="field">
              <div className="div">
                {subContractor !== null ? (
                  <span>{subContractor.name}</span>
                ) : employee !== null ? (
                  <span>{`${employee.firstName} ${employee.lastName}`}</span>
                ) : (
                  <span>Please assign shift</span>
                )}

                {subContractor !== null && employee !== null && (
                  <span>
                    {` (Employee: ${employee.firstName} ${employee.lastName})`}
                  </span>
                )}
              </div>

              {(shiftDetails.shiftStatus === "PENDING" ||
                shiftDetails.shiftStatus === "ACCEPTED") && (
                <Delete
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    unAssignShift();
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {subContractor === null && employee === null && (
          <Button
            styles={{
              height: "40px",
              position: "relative",
              width: "382px",
              marginTop: "32px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={assignShiftClicked}
          >
            Assign Shift
          </Button>
        )}
      </div>
    </div>
  );
};

export default ShiftEmployeeInput;
