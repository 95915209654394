import { useState } from "react";
import React from "react";
import "./Home.css";
import "./Form.css";
import { Button } from "../Button/Button";
import "../Button/Button.css";
import requestApi from "../../api/requestApi";

const HomeDemoFormComponent = () => {
  const [demoData, setDemoData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    company: "",
  });
  const [error, setError] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    company: "",
  });

  const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const submitFormData = (e) => {
    e.preventDefault();

    if (
      !demoData.name ||
      !demoData.email ||
      !demoData.phone ||
      !demoData.city ||
      !demoData.company
    ) {
      setError({
        name: !demoData.name ? "Enter Name" : "",
        email: !demoData.email
          ? "Enter Email"
          : !emailRegex.test(!demoData.email)
          ? "Enter valid email"
          : "",
        phone: !demoData.phone ? "Enter Phone" : "",
        city: !demoData.city ? "Enter City" : "",
        company: !demoData.company ? "Enter Company" : "",
      });
      return;
    }

    requestDemo();
  };

  const requestDemo = async () => {
    console.log(demoData, "demodata");
    console.log(demoData.company, "company");
    const formData = new FormData();
    formData.append("name", demoData.name);
    formData.append("email", demoData.email);
    formData.append("mobile", demoData.phone);
    formData.append("city", demoData.city);
    formData.append("companyName", demoData.company);
    try {
      const response = await requestApi.requestDemo(formData);
      console.log(response, "response");
      if (response.status === 200) {
        alert("Demo Request Sent");
        setDemoData({
          name: "",
          email: "",
          phone: "",
          city: "",
          company: "",
        });
      }
    } catch (error) {
      alert("Some error occured");
      setDemoData({
        name: "",
        email: "",
        phone: "",
        city: "",
        company: "",
      });
      console.error(error);
    }
  };

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setDemoData({ ...demoData, [name]: value });
    switch (name) {
      case "city":
        value === ""
          ? setError({ ...error, city: "Enter City" })
          : setError({ ...error, city: "" });
        break;
      case "company":
        value === ""
          ? setError({ ...error, company: "Enter Company" })
          : setError({ ...error, company: "" });
        break;
      case "name":
        value === ""
          ? setError({ ...error, name: "Enter Name" })
          : setError({ ...error, name: "" });
        break;
      case "phone":
        value === ""
          ? setError({ ...error, phone: "Enter Phone" })
          : setError({ ...error, phone: "" });
        break;
      case "email":
        value === ""
          ? setError({ ...error, email: "Enter email" })
          : !emailRegex.test(value)
          ? setError({ ...error, email: "Enter valid email" })
          : setError({ ...error, email: "" });
      default:
        break;
    }
  };

  return (
    <>
      <div className="form-container">
        <form onSubmit={submitFormData}>
          <div
            style={{
              display: "flex",
              backgroundColor: "#fafafa",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="form-hint-text">
                NAME <span className="form-asterisk">*</span>
              </span>
              <input
                name="name"
                className="home-page-form-text-box"
                type="text"
                value={demoData.name}
                onChange={handleOnchange}
              />
              <span className="error-messag">{error.name}</span>
            </div>

            <div
              style={{
                marginTop: "2.4vh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span className="form-hint-text">
                EMAIL ID <span className="form-asterisk">*</span>
              </span>
              <input
                name="email"
                className="home-page-form-text-box"
                type="email"
                value={demoData.email}
                onChange={handleOnchange}
              />
              <span className="error-messag">{error.email}</span>
            </div>

            {/* --------------------------- */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "48%",
                  marginTop: "2.4vh",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span className="form-hint-text">
                  MOBILE <span className="form-asterisk">*</span>
                </span>
                <input
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  name="phone"
                  maxLength={10}
                  className="home-page-form-text-box"
                  type="text"
                  value={demoData.phone}
                  onChange={handleOnchange}
                />
                <span className="error-messag">{error.phone}</span>
              </div>
              <div
                style={{
                  width: "48%",
                  marginTop: "2.4vh",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span className="form-hint-text">
                  CITY <span className="form-asterisk">*</span>
                </span>
                <input
                  name="city"
                  className="home-page-form-text-box"
                  type="text"
                  value={demoData.city}
                  onChange={handleOnchange}
                />
              </div>
            </div>
            {/* --------------------------- */}

            <div
              style={{
                marginTop: "2.4vh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span className="form-hint-text">
                COMPANY NAME <span className="form-asterisk">*</span>
              </span>
              <input
                className="home-page-form-text-box"
                type="text"
                name="company"
                value={demoData.company}
                onChange={handleOnchange}
              />
            </div>
          </div>

          <Button
            type="submit"
            buttonStyle="btn--home"
            styles={{ marginTop: "5.12vh" }}
          >
            Request Demo
          </Button>
        </form>
      </div>
    </>
  );
};

export default HomeDemoFormComponent;
