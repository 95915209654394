import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Divider } from "../../components/Common/Divider";
import { Button } from "../../components/Button/Button";
import "../../components/Button/Button.css";
import Dropdown from "../../components/Dropdown/Dropdown";
import CommonTable from "../../components/Table/CommonTable";

import CustomPagination from "../../components/Pagination/CustomPagination";

import AdminApi from "../../api/AdminApi";

const PastClientInvoices = () => {
  const { adminId } = useSelector((state) => state.auth);

  const [clients, setClients] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);

  const [invoices, setInvoices] = useState([]);
  const [tblInvoices, setTblInvoices] = useState([]);

  const [selectedPayslipIds, setSelectedInvoicesIds] = useState([]);

  const [showTable, setShowTable] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    if (clients.length !== 0) {
      const allClientOpt = {
        value: "ALL",
        label: `All Invoices`,
      };
      const emp = clients.map((client) => ({
        value: client.id,
        label: client.name,
      }));

      setClientOptions([allClientOpt, ...emp]);
    }
  }, [clients]);

  useEffect(() => {
    setTblInvoices(
      invoices.map((invoice, index) => {
        return {
          index: index + 1,
          invoiceId: invoice.invoiceId,
          clientName: invoice.clientName,
          earnings: invoice.totalEarnings,
          shifts: invoice.shifts.length,
        };
      })
    );
  }, [invoices]);

  const fetchClients = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    try {
      const response = await AdminApi.fetchAllClients(formData);
      if (response.status === 200) {
        setClients(response.data.response.clients);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllInvoicesForClient = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("clientId", selectedClientId);
    try {
      const response = await AdminApi.fetchAllInvoicesForClient(formData);
      if (response.status === 200) {
        setInvoices(response.data.response.invoices);
        setShowTable(true);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllInvoices = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    try {
      const response = await AdminApi.fetchAllInvoices(formData);
      if (response.status === 200) {
        setInvoices(response.data.response.invoices);
        setShowTable(true);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetInvoicesClick = () => {
    if (!selectedClientId) {
      alert("Please select an client");
      return;
    }

    if (isAllClientsSelected()) {
      fetchAllInvoices();
    } else {
      fetchAllInvoicesForClient();
    }
  };

  const handleClientSelected = (clientId) => {
    setSelectedClientId(clientId);
  };

  const handleTableRowsSelected = (rows) => {
    const payslipIds = rows.map((row) => row.payslipId);
    setSelectedInvoicesIds(payslipIds);
  };

  function isAllClientsSelected() {
    return selectedClientId && selectedClientId === "ALL";
  }

  const handleInvoiceClicked = (row) => {
    navigate("/invoice/" + row.invoiceId);
  };

  const columns = [
    {
      title: "S.No",
      field: "index",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Client Name",
      field: "clientName",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Number of Shifts",
      field: "shifts",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Total Earnings",
      field: "earnings",
      emptyValue: () => <div>-</div>,
    },
  ];

  const filteredColumns = isAllClientsSelected()
    ? columns
    : columns.filter((column) => column.field !== "clientName");

  return (
    <div className="cstm-contant-main-cont-all-main">
      <div className="cstm-cont-header">
        <div className="cstm-cont-header-title">
          <h1>Client - Past Invoices</h1>
        </div>
      </div>
      <Divider />
      <div className="cstm-contant-main-cont-table">
        <div className="cstm-contant-main-cont-table-header">
          <div className="cstm-contant-main-cont-table-header-top">
            <div className="cstm-contant-main-cont-table-header-top-left">
              <Dropdown
                options={clientOptions}
                placeholder={"Select the client"}
                onChange={handleClientSelected}
              />
            </div>
            <div className="cstm-contant-main-cont-table-header-top-right">
              <Button
                styles={{
                  width: "190px",
                }}
                onClick={handleGetInvoicesClick}
              >
                Get Invoices
              </Button>
            </div>
          </div>
        </div>

        {showTable && (
          <CommonTable
            columns={filteredColumns}
            apiData={tblInvoices}
            options={{
              emptyRowsWhenPaging: false,
              paginationPosition: "top",
              search: false,
              pageSize: 10,
              pageSizeOptions: [10, 20, 30],
              draggable: false,
              exportButton: false,
              thirdSortClick: false,
              maxBodyHeight: "40vh",
            }}
            isSelectable={false}
            onSelectionChange={handleTableRowsSelected}
            toolbarData={{
              Pagination: (props) => {
                let paginationprops = props;
                paginationprops = {
                  ...paginationprops,
                  label: "Invoices showing : ",
                  positionPagination: "top",
                };

                return <CustomPagination {...paginationprops} />;
              },
            }}
            onRowClick={(e, rowData) => {
              handleInvoiceClicked(rowData);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PastClientInvoices;
