export const MenuItems = [
  {
    title: "Dashboard",
    url: "/dashboard",
    cName: "nav-links",
  },
  {
    title: "Roster",
    url: "/roster",
    cName: "nav-links",
  },
  {
    title: "Employees",
    url: "/employees",
    cName: "nav-links",
  },
  {
    title: "Sub-Contractors",
    url: "/subcontractors",
    cName: "nav-links",
  },
  {
    title: "Shifts",
    url: "/shifts",
    cName: "nav-links",
  },
  {
    title: "Clients",
    url: "/clients",
    cName: "nav-links",
  },
  {
    title: "Sites",
    url: "/sites",
    cName: "nav-links",
  },
  {
    title: "Incidents",
    url: "/incidents",
    cName: "nav-links",
  },
  {
    title: "Finances",
    url: "/finances",
    cName: "nav-links",
  },
];
