import {
  ADMIN_API_PATH,
  EMPLOYEE_API_PATH,
  SITE_API_PATH,
  API_PATH,
  CLIENT_API_PATH,
  UPLOAD_EMP_API_PATH,
  ORGANISATION_API_PATH,
  PAYSLIP_API_PATH,
  INVOICE_API_PATH,
  INCIDENTS_API_PATH,
} from "./ApiConstants";
import axios from "axios";

const FETCH_ALL_EMPLOYEES_PATH = ADMIN_API_PATH + "employee/all";
const ADD_EMPLOYEE_PATH = ADMIN_API_PATH + "addEmployee";
const FETCH_SHIFT_DETAILS_PATH = ADMIN_API_PATH + "shift/";
const FETCH_ALL_SHIFTS_PATH = ADMIN_API_PATH + "shift/all";
const CREATE_SITE_PATH = SITE_API_PATH + "add";
const FETCH_ALL_SITES_PATH = SITE_API_PATH + "all";
const CREATE_SHIFT_PATH = ADMIN_API_PATH + "shift/create";
const FETCH_CLIENT_DETAILS_PATH = CLIENT_API_PATH;
const ADD_CLIENT_DETAILS_PATH = CLIENT_API_PATH + "add";
const FETCH_ALL_CLIENTS_PATH = CLIENT_API_PATH + "all";
const FETCH_ALL_SITES_FOR_CLIENT_PATH = CLIENT_API_PATH + "site/all";
const ADD_SUBCONTRACTOR_PATH = ADMIN_API_PATH + "/subcontractor/add";
const INVITE_SUBCONTRACTOR_PATH = ADMIN_API_PATH + "/subcontractor/invite";
const FETCH_ALL_SUBCONTRACTOR_PATH = ADMIN_API_PATH + "/subcontractor/all";
const UPLOAD_EMP_DOCUMENT_PATH = UPLOAD_EMP_API_PATH + "security-license";
const FETCH_AVAILABLE_EMPLOYEES_PATH = EMPLOYEE_API_PATH + "/available";
const ASSIGN_SHIFT_TO_EMPLOYEE_PATH = EMPLOYEE_API_PATH + "/shift/assign";
const ASSIGN_SHIFT_TO_SUBCONTRACTOR_PATH =
  ADMIN_API_PATH + "/subcontractor/assign";
const UNASSIGN_SHIFT_PATH = EMPLOYEE_API_PATH + "/shift/unAssign";
const ORG_BY_BUSINESS_CODE_PATH = ORGANISATION_API_PATH + "/business";
const SEARCH_ORGANISATION_PATH = ORGANISATION_API_PATH + "/search";
const ALL_ORG_PATH = ORGANISATION_API_PATH + "/all";
const ADD_ORG_PATH = ORGANISATION_API_PATH + "/add";
const FETCH_ALL_SHIFTS_FOR_PAYSLIP_PATH = ADMIN_API_PATH + "shift/all/payslip";
const FETCH_ALL_SHIFTS_FOR_INVOICE_PATH = ADMIN_API_PATH + "shift/all/invoice";
const GENERATE_PAYSLIP_PATH = PAYSLIP_API_PATH + "generate";
const GET_ALL_PAYSLIPS_PATH = PAYSLIP_API_PATH + "all";
const GET_ALL_PAYSLIPS_FOR_EMPLOYEE_PATH = PAYSLIP_API_PATH + "/employee/all";
const GET_PAYSLIP_FOR_EMPLOYEE_PATH = PAYSLIP_API_PATH;
const GENERATE_INVOICE_PATH = INVOICE_API_PATH + "generate";
const GET_ALL_INVOICES_PATH = INVOICE_API_PATH + "all";
const GET_ALL_INVOICES_FOR_EMPLOYEE_PATH = INVOICE_API_PATH + "/client/all";
const GET_INVOICE_FOR_EMPLOYEE_PATH = INVOICE_API_PATH;
const MARK_SHIFT_AS_APPROVED = ADMIN_API_PATH + "shift/approve";
const GET_ALL_INCIDENTS_LIST = INCIDENTS_API_PATH + "/all";

const fetchAllEmployees = (data) => {
  return axios.post(FETCH_ALL_EMPLOYEES_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const addEmployee = (data) => {
  return axios.post(ADD_EMPLOYEE_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchEmployeeDeatils = (data) => {
  return axios.post(EMPLOYEE_API_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const uploadEmployeeDocument = (data) => {
  return axios.post(UPLOAD_EMP_DOCUMENT_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const createSite = (data) => {
  return axios.post(CREATE_SITE_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchAllSites = (data) => {
  return axios.post(FETCH_ALL_SITES_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const createShift = (data) => {
  return axios.post(CREATE_SHIFT_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchUpcomingShift = (data) => {
  return axios.post(ADMIN_API_PATH + "shift/upcoming", data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchPastShift = (data) => {
  return axios.post(ADMIN_API_PATH + "shift/past", data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchdeclinedShift = (data) => {
  return axios.post(ADMIN_API_PATH + "shift/declined", data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};
const fetchAllShifts = (data) => {
  return axios.post(FETCH_ALL_SHIFTS_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchShiftDetails = (data) => {
  return axios.post(FETCH_SHIFT_DETAILS_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const loginAdmin = (loginData) => {
  return axios.post(API_PATH + "admin/login", loginData, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const logoutAdmin = async () => {
  const formdata = new FormData();
  formdata.append("adminId", localStorage.getItem("adminId"));
  try {
    const response = await axios.post(API_PATH + "admin/logout", formdata, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
    if (response.status) {
      localStorage.clear();
      window.location.href = "/login";
    }
  } catch (error) {}
};

const addClient = (data) => {
  return axios.post(ADD_CLIENT_DETAILS_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchClientDetails = (data) => {
  return axios.post(FETCH_CLIENT_DETAILS_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchAllClients = (data) => {
  return axios.post(FETCH_ALL_CLIENTS_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchAllSitesForClient = (data) => {
  return axios.post(FETCH_ALL_SITES_FOR_CLIENT_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const addSubcontractor = (data) => {
  return axios.post(ADD_SUBCONTRACTOR_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const inviteSubcontractor = (data) => {
  return axios.post(INVITE_SUBCONTRACTOR_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchAllSubcontractors = (data) => {
  return axios.post(FETCH_ALL_SUBCONTRACTOR_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchAvailableEmployees = (data) => {
  return axios.post(FETCH_AVAILABLE_EMPLOYEES_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const assignShiftToEmployee = (data) => {
  return axios.post(ASSIGN_SHIFT_TO_EMPLOYEE_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const assignShiftToSubcontractor = (data) => {
  return axios.post(ASSIGN_SHIFT_TO_SUBCONTRACTOR_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const unAssignShift = (data) => {
  return axios.post(UNASSIGN_SHIFT_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const organisationByBusinessCode = (data) => {
  return axios.post(ORG_BY_BUSINESS_CODE_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const searchOrganisation = (data) => {
  return axios.post(SEARCH_ORGANISATION_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const allOrganisations = () => {
  return axios.post(ALL_ORG_PATH, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const addOrganisations = (data) => {
  return axios.post(ADD_ORG_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchAllShiftsForPayslip = (data) => {
  return axios.post(FETCH_ALL_SHIFTS_FOR_PAYSLIP_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchAllShiftsForInvoice = (data) => {
  return axios.post(FETCH_ALL_SHIFTS_FOR_INVOICE_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const generatePayslip = (data) => {
  return axios.post(GENERATE_PAYSLIP_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchPayslipForEmployee = (data) => {
  return axios.post(GET_PAYSLIP_FOR_EMPLOYEE_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchAllPayslipsForEmployee = (data) => {
  return axios.post(GET_ALL_PAYSLIPS_FOR_EMPLOYEE_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchAllPayslips = (data) => {
  return axios.post(GET_ALL_PAYSLIPS_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const generateInvoice = (data) => {
  return axios.post(GENERATE_INVOICE_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchInvoiceForClient = (data) => {
  return axios.post(GET_INVOICE_FOR_EMPLOYEE_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchAllInvoicesForClient = (data) => {
  return axios.post(GET_ALL_INVOICES_FOR_EMPLOYEE_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchAllInvoices = (data) => {
  return axios.post(GET_ALL_INVOICES_PATH, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const markShiftAsApproved = (data) => {
  return axios.post(MARK_SHIFT_AS_APPROVED, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

const fetchAllIncidents = (data) => {
  return axios.post(GET_ALL_INCIDENTS_LIST, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export default {
  loginAdmin,
  logoutAdmin,
  fetchAllEmployees,
  addEmployee,
  createSite,
  fetchAllSites,
  createShift,
  fetchShiftDetails,
  fetchAllShifts,
  fetchEmployeeDeatils,
  uploadEmployeeDocument,
  fetchUpcomingShift,
  fetchPastShift,
  fetchdeclinedShift,
  addClient,
  fetchClientDetails,
  fetchAllClients,
  fetchAllSitesForClient,
  addSubcontractor,
  inviteSubcontractor,
  fetchAllSubcontractors,
  fetchAvailableEmployees,
  assignShiftToEmployee,
  assignShiftToSubcontractor,
  unAssignShift,
  organisationByBusinessCode,
  searchOrganisation,
  allOrganisations,
  addOrganisations,
  fetchAllShiftsForPayslip,
  fetchAllShiftsForInvoice,
  generatePayslip,
  fetchPayslipForEmployee,
  fetchAllPayslipsForEmployee,
  fetchAllPayslips,
  generateInvoice,
  fetchInvoiceForClient,
  fetchAllInvoicesForClient,
  fetchAllInvoices,
  markShiftAsApproved,
  fetchAllIncidents,
};
