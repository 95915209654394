import Navbar1 from "../../components/Navbar/Navbar1";
import { Divider } from "../../components/Common/Divider";
import CommonTable from "../../components/Table/CommonTable";
import CustomPagination from "../../components/Pagination/CustomPagination";
import { Button } from "../../components/Button/Button";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { exportCsvForPayslip } from "../../util/exportCsv";

import AdminApi from "../../api/AdminApi";

const PayslipDetails = () => {
  const { id } = useParams();
  const { adminId } = useSelector((state) => state.auth);

  const [payslipDetails, setPayslipDetails] = useState(null);
  const [duration, setDuration] = useState(0);
  const [tblShifts, setTblShifts] = useState([]);

  useEffect(() => {
    fetchPayslipDetails();
  }, []);

  useEffect(() => {
    if (payslipDetails !== null) {
      var totalDuration = 0;
      setTblShifts(
        payslipDetails.shifts.map((shift, index) => {
          totalDuration += shift.duration;
          return {
            index: index + 1,
            shiftId: shift.shiftId,
            siteName: shift.siteName,
            shiftDate: formateShiftDate(shift.startTime),
            shiftTime: formatShiftTime(shift.startTime, shift.endTime),
            earnings: shift.earning / 100,
          };
        })
      );
      setDuration(totalDuration / (60 * 60 * 1000));
    }
  }, [payslipDetails]);
  async function fetchPayslipDetails() {
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("payslipId", id);
    try {
      const response = await AdminApi.fetchPayslipForEmployee(formData);
      if (response.status) {
        setPayslipDetails(response.data.response);
        console.log(response.data.response);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function formateShiftDate(dateInMillis) {
    const startTime = new Date(dateInMillis);
    const dayOfWeek = startTime.toLocaleDateString("en-US", {
      weekday: "long",
    });
    const day = String(startTime.getDate()).padStart(2, "0");
    const month = String(startTime.getMonth() + 1).padStart(2, "0");
    const year = String(startTime.getFullYear()).slice(-2);

    const formattedDate = `${dayOfWeek}, ${day}/${month}/${year}`;
    return formattedDate;
  }

  function formatShiftTime(startTimeInMillis, endTimeInMillis) {
    const startTime = new Date(startTimeInMillis);
    const endTime = new Date(endTimeInMillis);

    const formatTime = (time) => {
      const hours = String(time.getHours()).padStart(2, "0");
      const minutes = String(time.getMinutes()).padStart(2, "0");
      return `${hours}${minutes}`;
    };

    const startFormatted = formatTime(startTime);
    const endFormatted = formatTime(endTime);

    const durationMs = endTime - startTime;
    const durationHours = Math.floor(durationMs / (1000 * 60 * 60));

    const shiftTimings = `${startFormatted} - ${endFormatted}, ${durationHours}hrs`;
    return shiftTimings;
  }

  const formatCostInAUD = (costInCents) => {
    const costInDollars = costInCents / 100;
    return new Intl.NumberFormat("en-AU", {
      style: "currency",
      currency: "AUD",
    }).format(costInDollars);
  };

  const columns = [
    {
      title: "S.No",
      field: "index",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Site Name",
      field: "siteName",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Shift Date",
      field: "shiftDate",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Shift Time",
      field: "shiftTime",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Earnings",
      field: "earnings",
      emptyValue: () => <div>-</div>,
    },
  ];

  const handleExportAsCsvClick = () => {
    exportCsvForPayslip(
      columns,
      tblShifts,
      "shifts",
      `${payslipDetails.employeeFirstName} ${payslipDetails.employeeLastName}`,
      duration,
      payslipDetails.totalEarningsInCents / 100
    );
  };

  return (
    <>
      <Navbar1 />
      <div className="cstm-contant-main">
        <div className="cstm-contant-ed">
          <div className="cstm-contant-ed-right">
            <div className="cstm-pfo-main-left">Payslip Details</div>
            <div style={{ marginTop: "16px" }}>
              <Divider />
            </div>

            <div className="cstm-ed-info-tp">
              <div className="cstm-ed-info-tp-box">
                <div className="cstm-ed-info-tp-box-left">
                  <span className="cstm-ed-title">Employee: </span>
                </div>
                <div className="cstm-ed-info-tp-box-right">
                  {payslipDetails
                    ? `${payslipDetails.employeeFirstName} ${payslipDetails.employeeLastName}`
                    : ""}
                </div>
              </div>

              <div className="cstm-ed-info-tp-box">
                <div className="cstm-ed-info-tp-box-left">
                  <span className="cstm-ed-title">Created: </span>
                </div>
                <div className="cstm-ed-info-tp-box-right">
                  {payslipDetails
                    ? formateShiftDate(payslipDetails.created)
                    : ""}
                </div>
              </div>
            </div>

            <div className="cstm-ed-info-bt">
              <div className="cstm-ed-info-bt-box">
                <div className="cstm-ed-info-bt-box-name">Shifts covered</div>
                <div className="cstm-ed-info-bt-box-number">
                  {payslipDetails ? payslipDetails.shifts.length : 0}
                </div>
              </div>
              <div className="cstm-ed-info-bt-box">
                <div className="cstm-ed-info-bt-box-name">Total duty hours</div>
                <div className="cstm-ed-info-bt-box-number">{duration}</div>
              </div>
              <div className="cstm-ed-info-bt-box">
                <div className="cstm-ed-info-bt-box-name">Total payout</div>
                <div className="cstm-ed-info-bt-box-number">
                  {payslipDetails
                    ? formatCostInAUD(payslipDetails.totalEarningsInCents)
                    : ""}
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: "40px",
              }}
            >
              <CommonTable
                columns={columns}
                apiData={tblShifts}
                options={{
                  emptyRowsWhenPaging: false,
                  paginationPosition: "top",
                  search: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 30],
                  draggable: false,
                  exportButton: false,
                  thirdSortClick: false,
                  maxBodyHeight: "40vh",
                }}
                toolbarData={{
                  Pagination: (props) => {
                    let paginationprops = props;
                    paginationprops = {
                      ...paginationprops,
                      label: "Shifts showing : ",
                      positionPagination: "top",
                    };

                    return <CustomPagination {...paginationprops} />;
                  },
                }}
              />

              <Button
                styles={{
                  marginTop: "24px",
                  width: "190px",
                }}
                onClick={handleExportAsCsvClick}
              >
                Download as .csv
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayslipDetails;
