import { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";

import FinanceSidebar from "./FinanceSidebar";

import FinanceDashboard from "./FinanceDashboard";
import GenerateEmployeePayslip from "./GenerateEmployeePayslip";
import PastEmployeePayslips from "./PastEmployeePayslips";
import GenerateClientInvoice from "./GenerateClientInvoice";
import PastClientInvoices from "./PastClientInvoices";

const FinancesContainer = () => {
  const location = useLocation();
  const [selectedView, setSelectedView] = useState("financeDashboard");

  useEffect(() => {
    if (location.state && location.state.selectedTab) {
      setSelectedView(location.state.selectedTab);
    }
  }, [location.state]);

  const handleFinanceDashboardClick = () => {
    setSelectedView("financeDashboard");
  };

  const handleGenerateInvoicesClick = () => {
    setSelectedView("generateClientInvoices");
  };

  const handlePastInvoicesClick = () => {
    setSelectedView("pastClientInvoices");
  };

  const handleGeneratePayslipsClick = () => {
    setSelectedView("generatePayslips");
  };

  const handlePastPayslipsClick = () => {
    setSelectedView("pastPayslips");
  };

  const renderView = () => {
    switch (selectedView) {
      case "financeDashboard":
        return <FinanceDashboard />;
      case "generateClientInvoices":
        return <GenerateClientInvoice />;
      case "pastClientInvoices":
        return <PastClientInvoices />;
      case "generatePayslips":
        return <GenerateEmployeePayslip />;
      case "pastPayslips":
        return <PastEmployeePayslips />;
      default:
        return <FinanceDashboard />;
    }
  };
  return (
    <div className="cstm-contant-main">
      <div className="cstm-contant-main-cont">
        <FinanceSidebar
          defaultSelectedView={selectedView}
          onFinanceDashboardClick={handleFinanceDashboardClick}
          onGenerateClientInvoicesClick={handleGenerateInvoicesClick}
          onPastClientInvoicesClick={handlePastInvoicesClick}
          onGeneratePayslipsClick={handleGeneratePayslipsClick}
          onPastPayslipsClick={handlePastPayslipsClick}
        />
        <div className="cstm-contant-main-cont-all">{renderView()}</div>
      </div>
    </div>
  );
};

export default FinancesContainer;
