import { Box, Typography } from '@mui/material'
import React from 'react'
import { NavBox } from './DashboardShifts'

const DashboardEmployees = () => {
  return (
    <>
      <Box className="section-spacing">
        <Box>
          <Typography variant="h3" >Employees</Typography>
          <Typography variant="body2" >Lorem ipsum dolor sit amet consectetur. Massa erat interdum id a est massa.</Typography>
          <Box sx={{
            display: 'flex',
            alignItems: 'end',
            flexWrap: 'wrap',
            mt: '30px',
            marginLeft: '-24px',
            marginBottom: '-24px',
            '& > div': {
              marginLeft: '24px',
              marginBottom: '24px',
            }
          }}>
            <NavBox text='Onboarding' number='99' bgColor='#E5ECF6' />
            <NavBox text='Expired Lic.' number='99' bgColor='#E3F5FF' />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default DashboardEmployees