import React from 'react'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { images } from '../../../../img'
import { ReactComponent as LeftArrow } from '../../../../img/left-arrow.svg'
import { ReactComponent as RightArrow } from '../../../../img/right-arrow.svg'
import { ReactComponent as ArrowRight } from '../../../../img/arrow-right.svg'
import { Link } from 'react-router-dom'

export const NavBox = ({ text = "Dummy Text", number = "178", bgColor = "#E3F5FF" }) => {
  return (
    <>
      <Box sx={{
        maxWidth: '147px',
        width: '100%',
        '& a': {
          display: 'block',
          padding: '24px',
          borderRadius: '16px',
          backgroundColor: bgColor,
          textDecoration: 'none',
          '&:hover': {
            '& svg': {
              transform: 'translateX(10px)'
            }
          }
        }
      }}>
        <Link >
          <Typography variant="body1" pb="8px" fontSize={'16px'} fontWeight={600} color="#1C1C1C">{text}</Typography>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& svg': {
              width: '24px',
              height: '24px',
              objectFit: 'contain',
              objectPosition: 'center',
              color: '#000',
              transition: 'all .5s ease'
            }
          }}>
            <Typography variant="body1" fontSize={'20px'} fontWeight={600} color="#1C1C1C">{number}</Typography>
            <ArrowRight />
          </Box>
        </Link>
      </Box>
    </>
  )
}

const DashboardShifts = () => {


  return (
    <>
      <Box className="section-spacing">
        <Box>
          <Typography variant="h3" >Shifts</Typography>
          <Typography variant="body2" >Lorem ipsum dolor sit amet consectetur. Massa erat interdum id a est massa.</Typography>
          <Box sx={{
            display: 'flex',
            alignItems: 'end',
            flexWrap: 'wrap',
            mt: '30px',
            marginLeft: '-24px',
            marginBottom: '-24px',
            '& > div': {
              marginLeft: '24px',
              marginBottom: '24px',
            }
          }}>
            <Box sx={{
              backgroundColor: '#0C6CF2',
              borderRadius: '16px',
              width: '147px',
              flexShrink: '0',
              backgroundImage: `url(${images.boxBg})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              textAlign: 'center'
            }}>
              <Box sx={{
                padding: '29px 28px',
              }}>
                <Typography variant="body1" color={'#fff'} lineHeight={1} sx={{ fontSize: '14px', fontWeight: '600' }}>Shifts Calender</Typography>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '5px 0'
                }}>
                  <LeftArrow />
                  <Typography variant="body1" color={'#fff'} lineHeight={1} sx={{ fontSize: '12px', fontWeight: '500' }}>Feb <br /> 2021</Typography>
                  <RightArrow />

                </Box>
                <Typography variant="body1" color={'#fff'} lineHeight={1} sx={{ fontSize: '14px', fontWeight: '700' }}>Mon Day</Typography>
                <Typography variant="body1" color={'#fff'} lineHeight={1} sx={{ fontSize: '48px', fontWeight: '700', pt: '5px' }}>17</Typography>
              </Box>
            </Box>
            <NavBox text='Active' number='721' bgColor='#E3F5FF' />
            <NavBox text='Declined' number='99' bgColor='#E5ECF6' />
            <NavBox text='Assigned' number='721' bgColor='#E3F5FF' />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default DashboardShifts