import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Divider } from "../../components/Common/Divider";
import { Button } from "../../components/Button/Button";
import "../../components/Button/Button.css";
import Dropdown from "../../components/Dropdown/Dropdown";
import CommonTable from "../../components/Table/CommonTable";

import CustomPagination from "../../components/Pagination/CustomPagination";

import AdminApi from "../../api/AdminApi";

const PastEmployeePayslips = () => {
  const { adminId } = useSelector((state) => state.auth);

  const [employees, setEmployees] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  const [payslips, setPayslips] = useState([]);
  const [tblPayslips, setTblPayslips] = useState([]);

  const [selectedPayslipIds, setSelectedPayslipsIds] = useState([]);

  const [showTable, setShowTable] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployees();
  }, []);

  useEffect(() => {
    if (employees.length !== 0) {
      const allEmpOpt = {
        value: "ALL",
        label: `All Payslips`,
      };
      const emp = employees.map((employee) => ({
        value: employee.employeeId,
        label: `${employee.firstName} ${employee.lastName}`,
      }));

      setEmployeeOptions([allEmpOpt, ...emp]);
    }
  }, [employees]);

  useEffect(() => {
    setTblPayslips(
      payslips.map((payslip, index) => {
        return {
          index: index + 1,
          payslipId: payslip.payslipId,
          employeeName: `${payslip.employeeFirstName} ${payslip.employeeLastName}`,
          earnings: payslip.totalEarnings,
          shifts: payslip.shifts.length,
        };
      })
    );
  }, [payslips]);

  const fetchEmployees = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    try {
      const response = await AdminApi.fetchAllEmployees(formData);
      if (response.status === 200) {
        setEmployees(response.data.response.employees);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllPayslipsForEmployee = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("employeeId", selectedEmployeeId);
    try {
      const response = await AdminApi.fetchAllPayslipsForEmployee(formData);
      if (response.status === 200) {
        setPayslips(response.data.response.payslips);
        setShowTable(true);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllPayslips = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    try {
      const response = await AdminApi.fetchAllPayslips(formData);
      if (response.status === 200) {
        setPayslips(response.data.response.payslips);
        setShowTable(true);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetPayslipsClick = () => {
    if (!selectedEmployeeId) {
      alert("Please select an employee");
      return;
    }

    if (isAllEmployeesSelected()) {
      fetchAllPayslips();
    } else {
      fetchAllPayslipsForEmployee();
    }
  };

  const handleEmployeeSelected = (employeeId) => {
    setSelectedEmployeeId(employeeId);
  };

  const handleTableRowsSelected = (rows) => {
    const payslipIds = rows.map((row) => row.payslipId);
    setSelectedPayslipsIds(payslipIds);
  };

  function isAllEmployeesSelected() {
    return selectedEmployeeId && selectedEmployeeId === "ALL";
  }

  const handlePayslipClicked = (row) => {
    navigate("/payslip/" + row.payslipId);
  };

  const columns = [
    {
      title: "S.No",
      field: "index",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Employee Name",
      field: "employeeName",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Number of Shifts",
      field: "shifts",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Total Earnings",
      field: "earnings",
      emptyValue: () => <div>-</div>,
    },
  ];

  const filteredColumns = isAllEmployeesSelected()
    ? columns
    : columns.filter((column) => column.field !== "employeeName");

  return (
    <div className="cstm-contant-main-cont-all-main">
      <div className="cstm-cont-header">
        <div className="cstm-cont-header-title">
          <h1>Employee - Past Payslips</h1>
        </div>
      </div>
      <Divider />
      <div className="cstm-contant-main-cont-table">
        <div className="cstm-contant-main-cont-table-header">
          <div className="cstm-contant-main-cont-table-header-top">
            <div className="cstm-contant-main-cont-table-header-top-left">
              <Dropdown
                options={employeeOptions}
                placeholder={"Select the employee"}
                onChange={handleEmployeeSelected}
              />
            </div>
            <div className="cstm-contant-main-cont-table-header-top-right">
              <Button
                styles={{
                  width: "190px",
                }}
                onClick={handleGetPayslipsClick}
              >
                Get Payslips
              </Button>
            </div>
          </div>
        </div>

        {showTable && (
          <CommonTable
            columns={filteredColumns}
            apiData={tblPayslips}
            options={{
              emptyRowsWhenPaging: false,
              paginationPosition: "top",
              search: false,
              pageSize: 10,
              pageSizeOptions: [10, 20, 30],
              draggable: false,
              exportButton: false,
              thirdSortClick: false,
              maxBodyHeight: "40vh",
            }}
            isSelectable={tblPayslips.length > 0}
            onSelectionChange={handleTableRowsSelected}
            toolbarData={{
              Pagination: (props) => {
                let paginationprops = props;
                paginationprops = {
                  ...paginationprops,
                  label: "Payslips showing : ",
                  positionPagination: "top",
                };

                return <CustomPagination {...paginationprops} />;
              },
            }}
            onRowClick={(e, rowData) => {
              handlePayslipClicked(rowData);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PastEmployeePayslips;
