import { useState, useEffect } from "react";

import "../../components/Employee/Employee.css";
import { Button } from "../../components/Button/Button";
import "../../components/Button/Button.css";
import { Divider } from "../../components/Common/Divider";
import Loader from "../../components/Loader/Loader";
import AddressAutocomplete from "../../components/AddressAutocomplete/AddressAutocomplete";

import AdminApi from "../../api/AdminApi";

const AddCustomerPopup = ({ onCancelClick, onDataSubmit }) => {
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleKeyPress = (event) => {
    if (event.keyCode === 27) {
      onCancelClick();
    }
  };

  const handlePlaceSelect = (location) => {
    setAddress(location.address);
  };

  const [loader, setLoader] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [businessCode, setBusinessCode] = useState("");
  const [contactPhone, setContactPhone] = useState("");

  const [adminEmail, setAdminEmail] = useState("");
  const [adminFirstName, setAdminFirstName] = useState("");
  const [adminLastName, setAdminLastName] = useState("");

  const submitFormData = (e) => {
    e.preventDefault();

    if (
      !companyName ||
      !address ||
      !businessCode ||
      !contactPhone ||
      !adminEmail ||
      !adminFirstName ||
      !adminLastName
    ) {
      alert("Please add missing fields");
      return;
    }

    addCustomer();
  };

  const addCustomer = async () => {
    const formData = new FormData();
    formData.append("name", companyName);
    formData.append("address", address);
    formData.append("businessCode", businessCode);
    formData.append("businessPhone", contactPhone);
    formData.append("adminEmail", adminEmail);
    formData.append("adminFirstName", adminFirstName);
    formData.append("adminLastName", adminLastName);

    setLoader(true);

    try {
      const response = await AdminApi.addOrganisations(formData);
      if (response.status >= 200 && response.status < 300) {
        setLoader(false);
        const addedOrg = response.data.response;
        if (
          addedOrg === undefined ||
          addedOrg === null ||
          Object.keys(addedOrg).length === 0
        ) {
          alert(response.data.errorMessage);
        } else {
          onDataSubmit();
        }
      } else {
        setLoader(false);
        alert(response.data.errorMessage);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  if (loader) return <Loader />;

  return (
    <div className="AddEmployeePopupViewContainer">
      <form className="AddEmployeePopupContainer" onSubmit={submitFormData}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="AddEmployeePopupTitle">Add Customer</span>
        </div>
        <Divider />
        <div className="InputContainer">
          <div style={{ marginTop: "24px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                Company Name <span style={{ color: "#eb2727" }}>*</span>
              </span>
              <span className="InputTitles" style={{ marginLeft: "16px" }}>
                Contact Number <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input"
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <input
                className="Input"
                type="text"
                style={{ marginLeft: "11px" }}
                value={contactPhone}
                onChange={(e) => setContactPhone(e.target.value)}
              />
            </div>
          </div>

          <div style={{ marginTop: "24px" }}>
            <span className="InputTitles">
              ABN/ACN <span style={{ color: "#eb2727" }}>*</span>
            </span>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input"
                type="text"
                value={businessCode}
                onChange={(e) => setBusinessCode(e.target.value)}
              />
            </div>
          </div>

          <div style={{ marginTop: "29px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                Address <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <AddressAutocomplete
                className="Input"
                type="text"
                style={{ width: "78%" }}
                selected={address}
                onChange={handlePlaceSelect}
              />
            </div>
          </div>

          <div style={{ marginTop: "29px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                Admin Email <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input-Full"
                type="text"
                value={adminEmail}
                onChange={(e) => setAdminEmail(e.target.value)}
              />
            </div>
          </div>

          <div style={{ marginTop: "24px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                Admin First Name <span style={{ color: "#eb2727" }}>*</span>
              </span>
              <span className="InputTitles" style={{ marginLeft: "16px" }}>
                Admin Last Name <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input"
                type="text"
                value={adminFirstName}
                onChange={(e) => setAdminFirstName(e.target.value)}
              />
              <input
                className="Input"
                type="text"
                style={{ marginLeft: "11px" }}
                value={adminLastName}
                onChange={(e) => setAdminLastName(e.target.value)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignSelf: "flex-end",
              marginTop: "32px",
            }}
          >
            <Button
              onClick={onCancelClick}
              buttonStyle="btn--outline"
              styles={{
                marginLeft: "auto",
              }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              styles={{
                marginLeft: "12px",
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCustomerPopup;
