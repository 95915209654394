import "./Sidebar.css";
import { Divider } from "../Common/Divider";

const ComplaintsSidebar = () => {
  return (
    <div className="SidebarContainer">
      <div className="cstm-sideber-top">
        <span className="SidebarTitleText">All Incidents</span>

        <Divider />

        <div className="cstm-sideber-top-ove">
          <div className="cstm-sideber-top-box">
            <span className="SidebarElementText">Resolved Incidents</span>
          </div>
        </div>
      </div>
      <div className="cstm-sideber-bottom">
        <h3 className="SidebarElementText">Need help?</h3>
        <span className="need-help-link">
          <ul>
            <li>
              <a href="#">Report a bug</a>
            </li>
            <li>
              <a href="#">Contact SureSight support</a>
            </li>
          </ul>
        </span>
      </div>
    </div>
  );
};

export default ComplaintsSidebar;
