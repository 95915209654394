import React, { useRef, useState, useEffect } from "react";
import { MenuItems } from "./MenuItems";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";
import { ArrowDropDown, Notifications } from "@mui/icons-material";
import AdminApi from "../../api/AdminApi";
import Dummy from '../../img/dummy.png'
import trial_3 from '../../img/trial_3.jpg'
import { Avatar } from "@mui/material";


var data = {
  "notification": [
    {
      "id": 123,
      "imgUrl": trial_3,
      "notificationTitle": "lorem dummy site",
      "isFlag": "true/false",
      "timestamp": 1687415798
    },
    {
      "id": 456,
      "imgUrl": trial_3,
      "notificationTitle": "Abc",
      "isFlag": "true/false",
      "timestamp": 1687415798
    },
    {
      "id": 789,
      "imgUrl": trial_3,
      "notificationTitle": "lorem dummy lorem dummy site",
      "isFlag": "true/false",
      "timestamp": 1687415798
    },
    {
      "id": 123,
      "imgUrl": trial_3,
      "notificationTitle": "Xyz",
      "isFlag": "true/false",
      "timestamp": 1687415798
    },
    {
      "id": 456,
      "imgUrl": trial_3,
      "notificationTitle": "dummy site lorem",
      "isFlag": "true/false",
      "timestamp": 1687415798
    },
    {
      "id": 789,
      "imgUrl": trial_3,
      "notificationTitle": "access lorem dummy",
      "isFlag": "true/false",
      "timestamp": 1687415798
    },
    {
      "id": 789,
      "imgUrl": trial_3,
      "notificationTitle": "access lorem dummy",
      "isFlag": "true/false",
      "timestamp": 1687415798
    },
    {
      "id": 789,
      "imgUrl": trial_3,
      "notificationTitle": "lorem dummy lorem dummy site",
      "isFlag": "true/false",
      "timestamp": 1687415798
    }
  ]
}


const Navbar1 = () => {
  const [clicked, setCliked] = useState(false)
  const [isopen, setOpen] = useState(false)
  const [accountOpen, setAccount] = useState(false)
  const closeMenu = useRef(null)
  const closeAccount = useRef(null)
  const [notification, setNotification] = useState(data);
  const [isMobileMenu, setIsMobileMenu] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  //used to check that responsive view exists or not in window resize event
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth < 1024)
        setIsMobileMenu(true)
      else
        setIsMobileMenu(false)
    };

    window.addEventListener('resize', handleResize);
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const closeAccountMenu = (e) => {
    if (closeAccount.current && accountOpen && !closeAccount.current.contains(e.target)) {
      setAccount(false)
    }
  }
  document.addEventListener('mousedown', closeAccountMenu)
  const closeOpenMenus = (e) => {
    if (closeMenu.current && isopen && !closeMenu.current.contains(e.target)) {
      setOpen(false)
    }
  }
  document.addEventListener('mousedown', closeOpenMenus)

  const handleLogout = () => {
    localStorage.clear()
    window.location.href = '/login'
  }
  return (
    <>
      <div className="cstm-header-main">
        <nav className="NavbarItems">

          <div className="cstm-header-main-left">
            <div className="cstm-header-logo">
              <Link className="cstm-logo">
                <img src={Dummy} />
              </Link>
            </div>
            <div className="cstm-header-menu">
              <div className="menu-icon" onClick={() => { setCliked(true); }}>
                {clicked ? <i className={"fas fa-times"} onClick={(e) => { e.stopPropagation(); setCliked(false) }} ></i> : <i
                  className={"fas fa-bars"}
                ></i>}

              </div>
              {(isMobileMenu) && clicked && <ul className={clicked ? "nav-menu active" : "nav-menu"}>
                {MenuItems.map((item, index) => {
                  return (
                    <li key={index}>
                      <NavLink
                        className={item.cName}
                        to={item.url} onClick={() => { setCliked(false) }}>
                        {item.title}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>}
              {!isMobileMenu && <ul className={clicked ? "nav-menu active" : "nav-menu"}>
                {MenuItems.map((item, index) => {
                  return (
                    <li key={index}>
                      <NavLink
                        className={item.cName}
                        to={item.url} onClick={() => { setCliked(false) }}>
                        {item.title}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>}


            </div>
          </div>
          <div className="cstm-header-main-right">

            <div className="cstm-header-main-right-noti">
              <div className="cstm-hed-nt" onClick={() => setOpen(true)} ref={closeMenu}> <Notifications /> <span className="cstm-header-nt">26</span></div>
              {isopen &&
                <div className="dropdown cstm-nonotifications">
                  <h3>Notifications</h3>
                  <ul className="cstm-nt-bullet">
                    {
                      notification.notification.map((item, index) => (
                        <>
                          <li className={index > 0 ? "" : "cstm-nt-active"}>
                            <div className="cstm-nt-bullet-left"><img className="user-nt-img" src={item.imgUrl} /></div>
                            <div className="cstm-nt-bullet-right">
                              <span className="user-nt-cont">{item.notificationTitle}</span>
                              <span className="user-nt-date">{new Date(item.timestamp).toDateString()}</span>
                            </div>
                          </li>
                        </>
                      ))
                    }
                  </ul>
                  <div className="cstm-nt-read"><a href="#">Read more...</a></div>

                </div>
              }
            </div>

            <div className="cstm-header-main-right-drop" onClick={() => setAccount(true)} ref={closeAccount}>
              <div className="cstm-header-rt-drop">Account<ArrowDropDown /></div>
              {accountOpen &&
                <div className="dropdown cstm-header-rt-drop-in">
                  <ul>
                    <li onClick={() => handleLogout()}>Logout</li>
                  </ul>
                </div>
              }
            </div>

          </div>

        </nav>
      </div>
    </>
  );
}

export default Navbar1;