import { Divider } from "../../components/Common/Divider";
import { ReactComponent as ThreeDots } from "../../img/threedots.svg";
import "./FinanceCard.css";

const Card = ({ Header, Amount, Shifts }) => {
  return (
    <div className="card">
      <div className="flex-container flex-padding">
        <div className="header">{Header}</div>
        <div className="img">
          <ThreeDots />
        </div>
      </div>
      <div className="flex-container">
        <div className="amount">{Amount}</div>
        <div className="shifts">{Shifts}</div>
      </div>
    </div>
  );
};

const FinanceDashboardCardBoxRow = ({ Heading, SubHeading }) => {
  return (
    <div>
      <div className="section-header">{Heading}</div>
      <div className="section-subheader">{SubHeading}</div>
      <div className="flex-container">
        <Card Header={"Expected"} Amount={"16050 AUD"} Shifts={"107 Shifts"} />
        <Card Header={"Expected"} Amount={"16050 AUD"} Shifts={"107 Shifts"} />
        <Card Header={"Expected"} Amount={"16050 AUD"} Shifts={"107 Shifts"} />
        <div className="empty-div"></div>
      </div>
    </div>
  );
};

export const FinanceDashboardCardBox = () => {
  return (
    <div className="main-container">
      <div className="section-container">
        <FinanceDashboardCardBoxRow
          Heading={"Client"}
          SubHeading={
            "Lorem ipsum dolor sit amet consectetur. Massa erat interdum id a est massa."
          }
        />
      </div>
      <div className="divider">
        <Divider />
      </div>
      <div className="section-container">
        <FinanceDashboardCardBoxRow
          Heading={"Sub-Contractor"}
          SubHeading={
            "Lorem ipsum dolor sit amet consectetur. Massa erat interdum id a est massa."
          }
        />
      </div>
      <div className="divider">
        <Divider />
      </div>
      <div className="section-container">
        <FinanceDashboardCardBoxRow
          Heading={"Employee"}
          SubHeading={
            "Lorem ipsum dolor sit amet consectetur. Massa erat interdum id a est massa."
          }
        />
      </div>
    </div>
  );
};
