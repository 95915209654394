import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Divider } from "../Common/Divider";
import { Link } from "react-router-dom";

const LeftSidebar = ({ children, title = "All Clients" }) => {
  return (
    <>

      <Box className="SidebarContainer">
        <Box className="cstm-sideber-top">
          <span variant="caption" className="SidebarTitleText">{title}</span>

          <Divider />

          <Box className="cstm-sideber-top-ove">
            {children}
          </Box>
        </Box>
        <Box className="cstm-sideber-bottom">
          <h3 className="SidebarElementText">Need help?</h3>
          <span className="need-help-link">
            <ul>
              <li>
                <Link to="/">Report a bug</Link>
              </li>
              <li>
                <Link to="/">Contact SureSight support</Link>
              </li>
            </ul>
          </span>
        </Box>
      </Box>
    </>
  );
};

export default LeftSidebar;
