import { Box } from "@mui/material";
import React from "react";
import DashboardLeftSidebar from "../components/Sidebar/DashboardLeftSidebar";
import DashboardCenterView from "./Dashboard/DashboardCenterView/DashboardCenterView";
const Dashboard = () => {
  return (
    <>
      <Box
        sx={{
          paddingTop: "60px",
        }}
      >
        <Box
          sx={{
            display: { lg: "flex" },
            height: "100%",
          }}
        >
          <DashboardLeftSidebar title={"Eye-watch Security"} />

          {/* Dashboard Center Box */}
          <DashboardCenterView />
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
