export const MenuItems = [
  {
    title: "Case-studies",
    path: "/marketing",
    cName: "dropdown-link",
  },
  {
    title: "Consulting",
    path: "/consulting",
    cName: "dropdown-link",
  },
  {
    title: "Design",
    path: "/design",
    cName: "dropdown-link",
  },
  {
    title: "Development",
    path: "/development",
    cName: "dropdown-link",
  },
];
