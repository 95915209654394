import React from "react";
import "./Home.css";

const HomeBubbleComponent = ({ icon, titleText, subtitleText }) => {
  return (
    <div className="bubble-container">
      {icon && <img className="image-style" src={icon} />}
      <div className="text-container">
        {titleText && <div className="title-text">{titleText}</div>}
        {subtitleText && <div className="subtitle-text">{subtitleText}</div>}
      </div>
    </div>
  );
};

export default HomeBubbleComponent;
