import React from "react";
import "./Employee.css";
import { Button } from "../Button/Button";
import "../Button/Button.css";
import sucessIcon from "../../img/success-icon.png";

export const SuccessMsgPopup = ({ onIsSuccessClick }) => {
  return (
    <div className="AddEmployeePopupViewContainer cstm-popup-success">
      <div className="AddEmployeePopupContainer">
        <span className="AddEmployeePopupIcon">
          <img src={sucessIcon} />
        </span>
        <span className="AddEmployeePopupTitle">Document Uploaded</span>
        <div className="InputContainer">
          <div className="cstm-success-btn">
            <Button onClick={onIsSuccessClick} buttonStyle="btn--outline">
              ok
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
