import LineChart from "../Dashboard/DashboardCenterView/DashboardCenterComponents/StatisticsChart/LineChart";
import React, { useState } from "react";
import { Box, Grid } from "@mui/material";

const FinanceDashboardGraphs = () => {
  const earningAnnotations = [
    {
      labels: [
        {
          point: {
            xAxis: 0,
            yAxis: 0,
            x: 0, // x-value of the data point you want to label
            y: 180, // y-value of the data point you want to label
          },
          text: "<p>+8.5% &#x2191;</p>",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          padding: 5,
          borderRadius: 5,
          borderColor: "none",
          useHTML: true,
          style: {
            color: "#4896DC",
            fontSize: "16px",
            fontWeight: "bold",
          },
        },
      ],
    },
  ];

  const paymentAnnotations = [
    {
      labels: [
        {
          point: {
            xAxis: 0,
            yAxis: 0,
            x: 4.5, // x-value of the data point you want to label
            y: 160, // y-value of the data point you want to label
          },
          text: "<p>+22% &#x2191;</p>",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          padding: 5,
          borderRadius: 5,
          borderColor: "none",
          useHTML: true,
          style: {
            color: "#4896DC",
            fontSize: "8px",
            fontWeight: "bold",
          },
        },
      ],
    },
  ];

  const profitLossAnnotations = [
    {
      labels: [
        {
          point: {
            xAxis: 0,
            yAxis: 0,
            x: 4.5, // x-value of the data point you want to label
            y: 100, // y-value of the data point you want to label
          },
          text: "<p>+8.5% &#x2191;</p>",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          padding: 5,
          borderRadius: 5,
          borderColor: "none",
          useHTML: true,
          style: {
            color: "#4896DC",
            fontSize: "8px",
            fontWeight: "bold",
          },
        },
      ],
    },
  ];

  return (
    <>
      <Box className="section-spacing">
        <Grid
          container
          spacing={2}
          sx={{
            "& .highcharts-container ": {
              width: "100% !important",
            },
          }}
        >
          <Grid item xxl={6} lg={12} md={6} xs={12}>
            <Box
              sx={{
                borderRadius: "20px",
                boxShadow:
                  "0 4px 59px rgba(50, 50, 71, 0.06), 0 4px 103px rgba(50, 50, 71, 0.01)",
                overflow: "hidden",
                height: "328px",
                padding: "10px 0px 0",
                "& > div": {
                  height: "100%",
                },
                "& .highcharts-title": {
                  fontSize: "48px !important",
                  fontFamily: "Poppins",
                  fontWeight: "600 !important",
                  color: "#272830 !important",
                  fill: "#272830 !important",
                  // padding: "20px 20px 20px",
                },
                "& .highcharts-subtitle": {
                  fontSize: "16px !important",
                  fontFamily: "Poppins",
                  fontWeight: "600 !important",
                  color: "#4896DC !important",
                  fill: "#4896DC !important",
                },
                "& .highcharts-button-symbol": {
                  width: "17px",
                  height: "15px",
                },
                "& svg": {
                  width: "100%",
                  height: "100%",
                },
                "& .highcharts-button-box": {
                  fill: "transparent !important",
                },
              }}
            >
              <LineChart
                title="1650 AUD"
                subTitle=""
                exports={true}
                topAxis="y"
                data={[0, 20, 15, 35, 50, 40, 64, 80, 140, 150]}
                annotations={earningAnnotations}
              />
            </Box>
          </Grid>
          <Grid item xxl={6} lg={12} md={6} xs={12}>
            <Grid
              container
              spacing={2}
              sx={{
                "& .highcharts-container ": {
                  width: "100% !important",
                },
              }}
            >
              <Grid item xxl={6} lg={12} md={6} xs={12}>
                <Box
                  sx={{
                    borderRadius: "20px",
                    boxShadow:
                      "0 4px 59px rgba(50, 50, 71, 0.06), 0 4px 103px rgba(50, 50, 71, 0.01)",
                    overflow: "hidden",
                    height: "166px",
                    padding: "10px 0px 0",
                    "& > div": {
                      height: "100%",
                    },
                    "& .highcharts-title": {
                      fontSize: "13px !important",
                      fontFamily: "Inter",
                      fontWeight: "500 !important",
                      color: "#272830 !important",
                      fill: "#272830 !important",
                    },
                    "& .highcharts-subtitle": {
                      fontSize: "28px !important",
                      fontFamily: "Poppins",
                      fontWeight: "600 !important",
                      color: "#272830 !important",
                      fill: "#272830 !important",
                    },
                    "& .highcharts-button-symbol": {
                      width: "17px",
                      height: "15px",
                    },
                    "& svg": {
                      width: "100%",
                      height: "auto",
                    },
                    "& .highcharts-button-box": {
                      fill: "transparent !important",
                    },
                  }}
                >
                  <LineChart
                    title="Payments"
                    subTitle="16050 AUD"
                    exports={true}
                    data={[10, 80, 60, 55, 60, 80, 140, 0]}
                    annotations={paymentAnnotations}
                  />
                </Box>
              </Grid>
              <Grid item xxl={6} lg={12} md={6} xs={12}>
                <Box
                  sx={{
                    borderRadius: "20px",
                    boxShadow:
                      "0 4px 59px rgba(50, 50, 71, 0.06), 0 4px 103px rgba(50, 50, 71, 0.01)",
                    overflow: "hidden",
                    height: "166px",
                    padding: "10px 0px 0",
                    "& > div": {
                      height: "100%",
                    },
                    "& .highcharts-title": {
                      fontSize: "12px !important",
                      fontFamily: "Inter",
                      fontWeight: "500 !important",
                      color: "#272830 !important",
                      fill: "#272830 !important",
                    },
                    "& .highcharts-subtitle": {
                      fontSize: "28px !important",
                      fontFamily: "Poppins",
                      fontWeight: "600 !important",
                      color: "#272830 !important",
                      fill: "#272830 !important",
                    },
                    "& .highcharts-button-symbol": {
                      width: "17px",
                      height: "15px",
                    },
                    "& svg": {
                      width: "100%",
                      height: "auto",
                    },
                    "& .highcharts-button-box": {
                      fill: "transparent !important",
                    },
                  }}
                >
                  <LineChart
                    title="Profit/Loss"
                    subTitle="10630 AUD"
                    exports={true}
                    data={[10, 20, 30, 40, 60, 80, 70, 60]}
                    annotations={profitLossAnnotations}
                  />
                </Box>
              </Grid>
              <Grid item xxl={12} lg={12} md={6} xs={12}>
                <Box
                  sx={{
                    borderRadius: "20px",
                    boxShadow:
                      "0 4px 59px rgba(50, 50, 71, 0.06), 0 4px 103px rgba(50, 50, 71, 0.01)",
                    overflow: "hidden",
                    height: "145px",
                    padding: "10px 0px 0",
                    "& > div": {
                      height: "100%",
                    },
                    "& .highcharts-title": {
                      fontSize: "14px !important",
                      fontFamily: "Inter",
                      fontWeight: "500 !important",
                      color: "#272830 !important",
                      fill: "#272830 !important",
                    },
                    "& .highcharts-subtitle": {
                      fontSize: "28px !important",
                      fontFamily: "Poppins",
                      fontWeight: "600 !important",
                      color: "#272830 !important",
                      fill: "#272830 !important",
                    },
                  }}
                >
                  <LineChart
                    title="Clients"
                    subTitle=""
                    exports={true}
                    data={[9, 11, 10, 11, 11, 10, 9, 10]}
                    // annotations={clientAnnotations}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FinanceDashboardGraphs;
