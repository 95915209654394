import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts';
import { images } from '../../../../../img';

require("highcharts/modules/exporting")(Highcharts);

const CompanyLineChart = ({ data = [10, 80, 60, 55, 80, 140, 0], title = "Title", subTitle = "Sub-Title", crosshair = false, exports = false }) => {

  var perShapeGradient = {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 1
  };

  const options = {
    chart: {
      type: 'areaspline'
    },
    title: {
      text: title,
      align: 'left',
      x: 20,
      y: 30,
    },
    subtitle: {
      text: subTitle,
      align: 'left',
      x: 20,
      y: 70,
    },
    xAxis: {
      visible: false,
      crosshair: crosshair
    },
    colors: [{
      stops: [
        [0.8, '#0164C6'],
        [0, '#1B59F8 ']
      ]
    }],
    yAxis: {
      visible: false,
    },
    tooltip: {
      // enabled: false,
      borderColor: '#2c3e50',
      shared: true,
      formatter: function (tooltip) {
        const header = `<div class="chart-tooltip"><h4>${title}</h4>:- <p>${this.y}</p></div>`
        return header
      }
    },
    exporting: {
      enabled: exports,
      buttons: {
        contextButton: {
          symbol: `url(${images.dots})`
        }
      }
    },
    plotOptions: {
      series: {
        color: 'rgba(72, 150, 220, 1)',
        marker: {
          enabled: false
        }
      },
      areaspline: {
        fillColor: {
          linearGradient: perShapeGradient,
          stops: [
            [0, "rgba(72, 150, 220, 0.35)"],
            [0, "rgba(72, 150, 220, 0.2)"],
            [1, "rgba(72, 150, 220, 0)"]
          ]
        },
        marker: {
          radius: 2
        },
        lineWidth: 2,
        lineColor: '#4896DC',
        states: {
          hover: {
            lineWidth: 2,
          }
        },
        threshold: null
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: title,
      showInLegend: false,
      data: data
    }]
  };

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  )
}

export default CompanyLineChart