// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SimpleDivider {
  width: 100%;
  height: 1px;
  background: #d3d3d3;
}`, "",{"version":3,"sources":["webpack://./src/components/Common/Common.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".SimpleDivider {\n  width: 100%;\n  height: 1px;\n  background: #d3d3d3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
