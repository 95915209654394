import { Box, Typography } from '@mui/material'
import React from 'react'
import { NavBox } from '../../../Dashboard/DashboardCenterView/DashboardCenterComponents/DashboardShifts'


const CompanyDashboardIncidents = () => {
  return (
    <>
      <Box className="section-spacing">
        <Box>
          <Typography variant="h3" >Incidents</Typography>
          <Typography variant="body2" >Lorem ipsum dolor sit amet consectetur. Massa erat interdum id a est massa.</Typography>
          <Box sx={{
            display: 'flex',
            alignItems: 'end',
            flexWrap: 'wrap',
            mt: '30px',
            marginLeft: '-24px',
            marginBottom: '-24px',
            '& > div': {
              marginLeft: '24px',
              marginBottom: '24px',
            }
          }}>


            <NavBox text='Demo  Requests' number='23' bgColor='#E3F5FF' />
            <NavBox text='Issues/ Complaints' number='120' bgColor='#E5ECF6' />
          </Box>
        </Box>
      </Box>

    </>
  )
}

export default CompanyDashboardIncidents