import { useState, useEffect } from "react";
import ShiftHeader from "../../components/Header/ShiftHeader";
import ShiftSidebar from "../../components/Sidebar/ShiftSidebar";
import AdminApi from "../../api/AdminApi";
import CreateShiftPopup from "../../components/Shift/CreateShiftPopup";
import CommonTable from "../../components/Table/CommonTable";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Divider } from "../../components/Common/Divider";
import { DateRange } from "@mui/icons-material";
import { Button } from "../../components/Button/Button";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CustomPagination from "../../components/Pagination/CustomPagination";

function createShifts(
  srno,
  shiftType,
  shiftStatus,
  siteName,
  locationDetails,
  startTime
) {
  return {
    srno,
    shiftType,
    shiftStatus,
    siteName,
    locationDetails,
    startTime,
  };
}

const ShiftsContainer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [shifts, setShifts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [tblShifts, setTblShifts] = useState([]);

  const { adminId } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    fetchShiftsData();
  }, []);
  useEffect(() => {
    if (shifts.length > 0) {
      var newarr = [];
      var arr = [...shifts];
      for (var i = 0; i < arr.length; i++) {
        newarr[i] = createShifts(
          i + 1,
          arr[i].shiftType,
          arr[i].shiftStatus,
          arr[i].siteName,
          arr[i].locationDetails,
          arr[i].startTime
        );
      }
      setTblShifts(newarr);
    } else {
      setTblShifts([]);
    }
  }, [shifts]);
  const fetchShiftsData = async () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("adminId", adminId);
    try {
      const response = await AdminApi.fetchAllShifts(formData);
      if (response.status === 200) {
        setLoader(false);
        setShifts(response.data.response.shifts);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };
  const columns = [
    {
      title: "S.No",
      field: "srno",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Shift type",
      field: "shiftType",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Status",
      field: "shiftStatus",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Company",
      field: "siteName",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Site location",
      field: "locationDetails",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Shift date",
      field: "startTime",
      render: (rowData) => (
        <div>{new Date(rowData.startTime).toLocaleDateString()}</div>
      ),
      emptyValue: () => <div>-</div>,
    },
  ];

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const handleOnCancelClick = () => {
    setIsOpen(false);
  };

  const handleOnContinueClick = () => {
    setIsOpen(false);
    fetchShiftsData();
  };

  const onClickShiftDetails = (e, index) => {
    const shift = shifts[index - 1];
    navigate("/shift-details/" + shift.shiftId);
  };
  return (
    <>
      <div className="cstm-contant-main">
        <div className="cstm-contant-main-cont">
          <ShiftSidebar />
          <div className="cstm-contant-main-cont-all">
            <div className="cstm-contant-main-cont-all-main">
              <div className="cstm-cont-header">
                <ShiftHeader child={"All Shifts"} buttonchild={<></>} />

                <div className="cstm-cont-header-btn">
                  <Button onClick={() => togglePopup()}>Create Shift</Button>
                </div>
              </div>

              <Divider />

              <div className="cstm-contant-main-cont-table">
                <div className="cstm-contant-main-cont-table-header">
                  <div className="cstm-contant-main-cont-table-header-top">
                    <div className="cstm-contant-main-cont-table-header-top-left">
                      <DateRange className="cld-sf-icon" />
                      <select className="select-all cstm-lastdays">
                        <option>Last 7 Days</option>
                      </select>
                    </div>
                    {/* TODO: Add it later
                    <div className="cstm-contant-main-cont-table-header-top-right">
                      <div className="cstm-filter-shifts">
                        Filter shifts or find a specific shift using conditions
                        :
                      </div>
                      <div className="cstm-fuc-btn cstm-fuc-btn">
                        <Button>
                          <FilterAltIcon />
                          Filter using Conditions
                        </Button>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div>
                  <CommonTable
                    loader={loader}
                    apiData={tblShifts}
                    columns={columns}
                    options={{
                      thirdSortClick: false,
                      emptyRowsWhenPaging: false,
                      paginationPosition: "top",
                      search: false,
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 30],
                      draggable: false,
                      search: false,
                    }}
                    onRowClick={(e, rowData) => {
                      onClickShiftDetails(e, rowData.srno);
                    }}
                    toolbarData={{
                      Pagination: (props) => {
                        let paginationprops = props;
                        paginationprops = {
                          ...paginationprops,
                          label: "Shift showing : ",
                          positionPagination: "top",
                        };

                        return <CustomPagination {...paginationprops} />;
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <CreateShiftPopup
            onCancelClick={() => handleOnCancelClick()}
            onDataSubmit={() => handleOnContinueClick()}
          />
        )}
      </div>
    </>
  );
};

export default ShiftsContainer;
