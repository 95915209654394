import "./ShiftDetails.css";

import {
  convertUTCToDateWithDayString,
  convertUTCTo24HourString,
  convertDurationToHoursMinutes,
} from "../../util/DateTimeUtils";

const ShiftFooter = ({ shiftDetails }) => {
  return (
    <div className="shift-footer-frame">
      <div className="div">
        <div className="div-wrapper">
          <div className="text-wrapper">Date</div>
        </div>
        <div className="div-wrapper-2">
          <div className="text-wrapper-2">Start time</div>
        </div>
        <div className="div-wrapper-3">
          <div className="text-wrapper-3">Allowed break time</div>
        </div>
        <div className="div-wrapper-4">
          <div className="text-wrapper-4">End Time</div>
        </div>
        <div className="div-wrapper-5">
          <div className="text-wrapper-5">Duration</div>
        </div>
      </div>
      <div className="div-2">
        <div className="div-wrapper-6">
          <div className="text-wrapper-6">
            {convertUTCToDateWithDayString(shiftDetails.startTime)}
          </div>
        </div>
        <div className="div-wrapper-7">
          <div className="text-wrapper-7">
            {convertUTCTo24HourString(shiftDetails.startTime)}
          </div>
        </div>
        <div className="div-wrapper-8">
          <div className="text-wrapper-8">
            {convertUTCTo24HourString(shiftDetails.endTime)}
          </div>
        </div>
        <div className="div-wrapper-9">
          <div className="text-wrapper-9">
            {convertDurationToHoursMinutes(shiftDetails.allowedBreakTime)}
          </div>
        </div>
        <div className="div-wrapper-10">
          <div className="text-wrapper-10">
            {convertDurationToHoursMinutes(shiftDetails.duration)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftFooter;
