import React from "react";
import "./Form.css";
import HomeDemoFormComponent from "./HomeDemoFormComponent";

const HomeFormContainerComponent = () => {
  return (
    <div className="home-page-form-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <label
          className="home-page-form-title"
          style={{
            marginTop: "10.4vh",
            width: "36vw",
          }}
        >
          Schedule a free demo
        </label>

        <label
          className="home-page-form-subtext"
          style={{
            marginTop: "3.6vh",
            width: "38.5vw",
          }}
        >
          Book a free demo to see what the Suresight app can do for your
          business
        </label>

        <label
          className="home-page-form-subtext"
          style={{
            marginTop: "3.6vh",
            width: "38.5vw",
          }}
        >
          Click here to know more about us?
        </label>
      </div>
      <HomeDemoFormComponent />
    </div>
  );
};

export default HomeFormContainerComponent;
