import React, { useEffect, useState } from "react";
import ShiftSidebar from "../../components/Sidebar/ShiftSidebar";
import ShiftHeader from "../../components/Header/ShiftHeader";
import CommonTable from "../../components/Table/CommonTable";
import AdminApi from "../../api/AdminApi";
import { useSelector } from "react-redux";
import { Divider } from "../../components/Common/Divider";
import { DateRange } from "@mui/icons-material";
import { Button } from "../../components/Button/Button";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CustomPagination from "../../components/Pagination/CustomPagination";

function createUpcomingShifts(
  srno,
  shiftType,
  shiftStatus,
  siteName,
  locationDetails,
  startTime
) {
  return {
    srno,
    shiftType,
    shiftStatus,
    siteName,
    locationDetails,
    startTime,
  };
}
const UpcomingShift = () => {
  const { adminId } = useSelector((state) => state.auth);
  const [searchText, setSearchText] = useState("");
  const [tblUpComingShifts, setTblUpComingShifts] = useState([]);
  const [upcomingShift, setUpcomingShift] = useState([]);

  useEffect(() => {
    fetchcomingShift();
  }, []);
  useEffect(() => {
    if (upcomingShift.length > 0) {
      var newarr = [];
      var arr = [...upcomingShift];
      for (var i = 0; i < arr.length; i++) {
        newarr[i] = createUpcomingShifts(
          i + 1,
          arr[i].shiftType,
          arr[i].shiftStatus,
          arr[i].siteName,
          arr[i].locationDetails,
          arr[i].startTime
        );
      }
      setTblUpComingShifts(newarr);
    } else {
      setTblUpComingShifts([]);
    }
  }, [upcomingShift]);

  async function fetchcomingShift() {
    const formData = new FormData();
    formData.append("adminId", adminId);
    try {
      const response = await AdminApi.fetchUpcomingShift(formData);
      if (response.status) {
        console.log(response, "upres");
        setUpcomingShift(response.data.response.shifts);
      }
    } catch (error) {
      console.log(error, "err");
    }
  }
  const columns = [
    {
      title: "S.No",
      field: "srno",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Shift type",
      field: "shiftType",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Status",
      field: "shiftStatus",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Company",
      field: "siteName",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Site location",
      field: "locationDetails",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Shift date",
      field: "startTime",
      render: (rowData) => (
        <div>{new Date(rowData.startTime).toLocaleDateString()}</div>
      ),
      emptyValue: () => <div>-</div>,
    },
  ];
  return (
    <>
      <div className="cstm-contant-main">
        <div className="cstm-contant-main-cont">
          <ShiftSidebar />
          <div className="cstm-contant-main-cont-all">
            <div className="cstm-contant-main-cont-all-main">
              <div className="cstm-cont-header">
                <ShiftHeader child={"Upcoming Shifts"} />
              </div>
              <Divider />
              <div className="cstm-contant-main-cont-table">
                <div className="cstm-contant-main-cont-table-header">
                  <div className="cstm-contant-main-cont-table-header-top">
                    <div className="cstm-contant-main-cont-table-header-top-left">
                      <DateRange className="cld-sf-icon" />
                      <select className="select-all cstm-lastdays">
                        <option>Last 7 Days</option>
                      </select>
                    </div>
                    <div className="cstm-contant-main-cont-table-header-top-right">
                      <div className="cstm-filter-shifts">
                        Filter shifts or find a specific shift using conditions
                        :
                      </div>
                      <div className="cstm-fuc-btn cstm-fuc-btn">
                        <Button>
                          <FilterAltIcon />
                          Filter using Conditions
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <CommonTable
                    apiData={tblUpComingShifts}
                    columns={columns}
                    options={{
                      thirdSortClick: false,
                      paginationPosition: "top",
                      search: false,
                      pageSize: 10,
                      emptyRowsWhenPaging: false,
                      pageSizeOptions: [10, 20, 30],
                      draggable: false,
                      search: false,
                    }}
                    toolbarData={{
                      Pagination: (props) => {
                        let paginationprops = props;
                        paginationprops = {
                          ...paginationprops,
                          label: "Shift showing : ",
                          positionPagination: "top",
                        };

                        return <CustomPagination {...paginationprops} />;
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpcomingShift;
