import MaterialTable from "material-table";
import React from "react";
import Loader from "../Loader/Loader";

import { createTheme, MuiThemeProvider } from "@material-ui/core";

const CommonTable = ({
  loader,
  apiData,
  columns,
  options,
  onRowClick,
  toolbarData,
  isSelectable,
  onSelectionChange,
}) => {
  if (loader) return <Loader />;
  const theme = createTheme({
    overrides: {
      MuiCheckbox: {
        colorSecondary: {
          "&$checked": {
            color: "#1f79ce", // Change checkbox color
          },
        },
      },
    },
  });

  const onSelectionChangeListener = (rows) => {
    if (onSelectionChange) {
      onSelectionChange(rows);
    }
  };

  const onRowClickListener = (e, row) => {
    if (onRowClick) {
      onRowClick(e, row);
    }
  };

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          title=""
          data={apiData}
          columns={columns}
          options={{
            ...options,
            selection: isSelectable ? true : false,
            showTextRowsSelected: false,
            toolbar: false,
          }}
          onRowClick={onRowClickListener}
          components={toolbarData}
          onSelectionChange={onSelectionChangeListener}
        />
      </MuiThemeProvider>
    </>
  );
};

export default CommonTable;
