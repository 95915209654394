import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "../../components/Employee/Employee.css";
import AdminApi from "../../api/AdminApi";
import "../Button/Button.css";
import EnterClientDetails from "./EnterClientDetails";
import ClientManagerDetails from "./ClientManagerDetails";
import ClientRateDetails from "./ClientRateDetails";

const AddClientPopup = ({ onCancelClick, onDataSubmit }) => {
  const [formPage, setFormPage] = useState(1);
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleKeyPress = (event) => {
    if (event.keyCode === 27) {
      onCancelClick();
    }
  };

  const [clientName, setClientName] = useState("");
  const [businessCode, setBusinessCode] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const [contactPersonName, setContactPersonName] = useState("");
  const [contactPersonEmail, setContactPersonEmail] = useState("");
  const [contactPersonPhone, setContactPersonPhone] = useState("");

  const [accountPersonName, setAccountPersonName] = useState("");
  const [accountPersonEmail, setAccountPersonEmail] = useState("");
  const [accountPersonPhone, setAccountPersonPhone] = useState("");

  const { adminId } = useSelector((state) => state.auth);

  const saveInitialClientDetails = (value) => {
    setFormPage(2);
    setClientName(value.clientName);
    setBusinessCode(value.businessCode);
    setPhone(value.phone);
    setAddress(value.address);
  };

  const saveSpocDetails = (value) => {
    setFormPage(3);
    setContactPersonName(value.contactPerson.contactPersonName);
    setContactPersonEmail(value.contactPerson.contactPersonEmail);
    setContactPersonPhone(value.contactPerson.contactPersonPhone);
    setAccountPersonName(value.accountPerson.accountPersonName);
    setAccountPersonEmail(value.accountPerson.accountPersonEmail);
    setAccountPersonPhone(value.accountPerson.accountPersonPhone);
  };

  const saveRateDetails = (rateInfo) => {
    addClient(
      rateInfo.dayRate,
      rateInfo.nightRate,
      rateInfo.saturdayRate,
      rateInfo.sundayRate,
      rateInfo.holidayRate
    );
  };

  const addClient = async (
    dayRate,
    nightRate,
    saturdayRate,
    sundayRate,
    holidayRate
  ) => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("name", clientName);
    formData.append("businessCode", businessCode);
    formData.append("businessPhone", phone);
    formData.append("address", address);
    formData.append("contactPersonName", contactPersonName);
    formData.append("contactPersonEmail", contactPersonEmail);
    formData.append("contactPersonPhone", contactPersonPhone);
    formData.append("accountPersonName", accountPersonName);
    formData.append("accountPersonEmail", accountPersonEmail);
    formData.append("accountPersonPhone", accountPersonPhone);
    formData.append("dayRateInCents", dayRate);
    formData.append("nightRateInCents", nightRate);
    formData.append("saturdayRateInCents", saturdayRate);
    formData.append("sundayRateInCents", sundayRate);
    formData.append("holidayRateInCents", holidayRate);

    try {
      const response = await AdminApi.addClient(formData);
      if (response.status >= 200 && response.status < 300) {
        onDataSubmit();
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  function getPage() {
    if (formPage === 1) {
      return (
        <EnterClientDetails
          onCancelClick={onCancelClick}
          onDataSubmit={saveInitialClientDetails}
        />
      );
    } else if (formPage === 2) {
      return (
        <ClientManagerDetails
          onCancelClick={onCancelClick}
          onDataSubmit={saveSpocDetails}
        />
      );
    } else {
      return (
        <ClientRateDetails
          onCancelClick={onCancelClick}
          onDataSubmit={saveRateDetails}
        />
      );
    }
  }

  return <div>{getPage()}</div>;
};

export default AddClientPopup;
