import { Box } from "@mui/material";
import React from "react";
import { Divider } from "../../../components/Common/Divider";
import CompanyDashboardIncidents from "./CompanyDashboardCenterComponents/CompanyDashboardIncidents";
import CompanyDashboardNotification from "../CompanyDashboardNotification/CompanyDashboardNotification";
import CompanyDashboardClients from "./CompanyDashboardCenterComponents/CompanyDashboardClients";
import CompanyDashboardStatistics from "./CompanyDashboardCenterComponents/CompanyDashboardStatistics";

const CompanyDashboardCenterView = () => {
  return (
    <>
      <Box
        className="custom-scrollbar cstm-contant-main-cont-all"
        sx={{
          width: "100%",
          overflow: "auto",
          display: { sm: "flex" },
          alignItems: { sm: "flex-start" },
          height: { sm: "calc(100vh - 60px)" },
        }}
      >
        <Box
          className="cstm-contant-main-cont-all-main"
          sx={{
            width: { sm: "100%" },
            "& .section-spacing:not(:last-child)": {
              paddingBottom: { md: "64px", sx: "40px", xs: "30px" },
            },
          }}
        >
          <Box
            sx={{
              pb: "25px",
            }}
          >
            <Box className="cstm-cont-header">
              <Box className="cstm-cont-header-title">
                <h1>Dashboard</h1>
              </Box>
            </Box>

            <Divider />
          </Box>

          {/* CompanyDashboardStatistics */}
          <CompanyDashboardStatistics />

          {/* Incidents */}
          <CompanyDashboardIncidents />

          {/* Clients */}
          <CompanyDashboardClients />
        </Box>
        <CompanyDashboardNotification />
      </Box>
    </>
  );
};

export default CompanyDashboardCenterView;
