import { useState, useEffect } from "react";
import AddSitePopup from "../components/Sites/AddSitePopup";
import SitesHeader from "../components/Header/SitesHeader";
import SitesSidebar from "../components/Sidebar/SitesSidebar";
import AdminApi from "../api/AdminApi";
import CommonTable from "../components/Table/CommonTable";
import { useSelector } from "react-redux";
const SitesContainer = () => {
  const [isAddSitePopupOpen, setIsAddSitePopupOpen] = useState(false);
  const [sites, setSites] = useState([]);
  const { adminId } = useSelector((state) => state.auth);

  useEffect(() => {
    fetchSites();
  }, []);

  const fetchSites = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    try {
      const response = await AdminApi.fetchAllSites(formData);
      if (response.status) {
        setSites(response.data.response.sites);
      }
    } catch (error) {
      localStorage.clear();
      window.location.href = "/login";
    }
  };

  const columns = [
    {
      title: "Site Name",
      field: "siteName",
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Site Address",
      field: "addressString",
      emptyValue: () => <div>-</div>,
    },
  ];
  return (
    <>
      <div className="cstm-contant-main">
        <div className="cstm-contant-main-cont">
          <SitesSidebar />
          <div className="cstm-contant-main-cont-all">
            <div className="cstm-contant-main-cont-all-main">
              <SitesHeader
                onAddSiteClick={() => {
                  setIsAddSitePopupOpen(true);
                }}
              />
              <CommonTable
                columns={columns}
                apiData={sites}
                options={{
                  thirdSortClick: false,
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 15],
                  search: false,
                  draggable: false,
                  emptyRowsWhenPaging: false,
                }}
              />
            </div>
          </div>
        </div>
        {isAddSitePopupOpen && (
          <AddSitePopup
            onCancelClick={() => {
              setIsAddSitePopupOpen(false);
            }}
            onDataSubmit={() => {
              setIsAddSitePopupOpen(false);
              fetchSites();
            }}
          />
        )}
      </div>
    </>
  );
};

export default SitesContainer;
