import { useRef } from "react";
import HomeNavbar from "../components/HomeNavbar/HomeNavbar";
import AppPage from "../components/pages/AppPage";
import HomeFormContainerComponent from "../components/Home/HomeFormContainerComponent";

const AppContainer = () => {
  const requestDemoFormRef = useRef(null);

  const scrollToBottom = () => {
    requestDemoFormRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <HomeNavbar onNavbarRequestDemoClicked={scrollToBottom} />
      <AppPage />
      <div ref={requestDemoFormRef}>
        <HomeFormContainerComponent />
      </div>
    </>
  );
};

export default AppContainer;
