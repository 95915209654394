const ComplaintsHeader = ({ child, buttonchild }) => {
  return (
    <div className="cstm-cont-header-title">
      <h1>{child}</h1>
      {buttonchild}
    </div>
  );
};

export default ComplaintsHeader;
