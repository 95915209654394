import { useState } from "react";
import "../../components/Employee/Employee.css";
import { Button } from "../Button/Button";
import "../Button/Button.css";
import { Divider } from "../../components/Common/Divider";

const ClientRateDetails = ({ onCancelClick, onDataSubmit }) => {
  const [dayRate, setDayRate] = useState(null);
  const [nightRate, setNightRate] = useState(null);
  const [saturdayRate, setSaturdayRate] = useState(null);
  const [sundayRate, setSundayRate] = useState(null);
  const [holidayRate, setHolidayRate] = useState(null);

  const submitFormData = (e) => {
    e.preventDefault();

    if (
      dayRate === null ||
      nightRate === null ||
      saturdayRate === null ||
      sundayRate === null ||
      holidayRate === null
    ) {
      alert("Please add missing fields");
      return;
    }

    onDataSubmit({
      dayRate: dayRate * 100,
      nightRate: nightRate * 100,
      saturdayRate: saturdayRate * 100,
      sundayRate: sundayRate * 100,
      holidayRate: holidayRate * 100,
    });
  };

  return (
    <div className="AddEmployeePopupViewContainer">
      <form className="AddEmployeePopupContainer" onSubmit={submitFormData}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="AddEmployeePopupTitle">Add Client</span>
          <span className="cstm-pfo-title">
            Step 3/3 - Client Rate Information
          </span>
        </div>
        <Divider />
        <div className="InputContainer">
          <div style={{ marginTop: "20px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                Day Rate <span style={{ color: "#eb2727" }}>*</span>
              </span>
              <span className="InputTitles" style={{ marginLeft: "16px" }}>
                Night Rate <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input"
                pattern="[0-9]+(\.[0-9]+)?"
                value={dayRate}
                onChange={(e) => setDayRate(e.target.value)}
              />
              <input
                className="Input"
                pattern="[0-9]+(\.[0-9]+)?"
                style={{ marginLeft: "16px" }}
                value={nightRate}
                onChange={(e) => setNightRate(e.target.value)}
              />
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                Saturday Rate <span style={{ color: "#eb2727" }}>*</span>
              </span>
              <span className="InputTitles" style={{ marginLeft: "16px" }}>
                Sunday Rate <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input"
                pattern="[0-9]+(\.[0-9]+)?"
                value={saturdayRate}
                onChange={(e) => setSaturdayRate(e.target.value)}
              />
              <input
                className="Input"
                pattern="[0-9]+(\.[0-9]+)?"
                style={{ marginLeft: "16px" }}
                value={sundayRate}
                onChange={(e) => setSundayRate(e.target.value)}
              />
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div style={{ display: "flex" }}>
              <span className="InputTitles">
                Holiday Rate <span style={{ color: "#eb2727" }}>*</span>
              </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <input
                className="Input"
                pattern="[0-9]+(\.[0-9]+)?"
                value={holidayRate}
                onChange={(e) => setHolidayRate(e.target.value)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignSelf: "flex-end",
              marginTop: "32px",
            }}
          >
            <Button
              onClick={onCancelClick}
              buttonStyle="btn--outline"
              styles={{
                marginLeft: "auto",
              }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              styles={{
                marginLeft: "12px",
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ClientRateDetails;
