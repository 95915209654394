import "./ShiftDetails.css";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ShiftSidebar = ({ shiftDetails }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div onClick={() => navigate("/shifts")} className="back-link">
        <ArrowBack /> Back to all shifts
      </div>
      <div className="shift-sidebar-frame">
        <div className="shift-sidebar-frame-wrapper">
          <div className="overlap-group-wrapper">
            <div className="overlap-group">
              <div className="div-wrapper">
                <div className="text-wrapper">:</div>
              </div>
              <div className="div">
                <div className="text-wrapper-2">:</div>
              </div>
              <div className="div-2">
                <div className="div-3">
                  <div className="div-wrapper-2">
                    <div className="text-wrapper-3">06</div>
                  </div>
                  <div className="div-wrapper-2">
                    <div className="text-wrapper-4">28</div>
                  </div>
                  <div className="div-wrapper-2">
                    <div className="text-wrapper-4">55</div>
                  </div>
                </div>
                <div className="div-4">
                  <div className="div-wrapper-2">
                    <div className="text-wrapper-5">day</div>
                  </div>
                  <div className="div-wrapper-2">
                    <div className="text-wrapper-6">hr</div>
                  </div>
                  <div className="div-wrapper-2">
                    <div className="text-wrapper-7">min</div>
                  </div>
                </div>
                <div className="shift-sidebar-frame-wrapper-2">
                  <div className="div-wrapper-3">
                    <div className="text-wrapper-8">
                      {shiftDetails.shiftStatus}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftSidebar;
