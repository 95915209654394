const SubcontractorSearchResultComponent = ({
  results,
  selectedResult,
  onResultSelect,
  isEmployee,
}) => {
  return (
    <div className="search-results-container">
      <ul className="search-results-list">
        {results.map((result, index) => (
          <li
            key={index}
            className={selectedResult === index ? "selected" : ""}
            onClick={() => onResultSelect(index)}
          >
            {isEmployee
              ? result.firstName + " " + result.lastName
              : result.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

SubcontractorSearchResultComponent.defaultProps = {
  isEmployee: false,
};

export default SubcontractorSearchResultComponent;
