import { useState } from "react";

import "../../components/Sidebar/Sidebar.css";
import { Divider } from "../../components/Common/Divider";
import ClickableText from "../../components/Text/ClickableText";

const FinanceSidebar = ({
  defaultSelectedView,
  onFinanceDashboardClick,
  onGenerateClientInvoicesClick,
  onPastClientInvoicesClick,
  onGeneratePayslipsClick,
  onPastPayslipsClick,
}) => {
  const [selectedView, setSelectedView] = useState(defaultSelectedView);

  const handleFinanceDashboardClick = () => {
    setSelectedView("financeDashboard");
    onFinanceDashboardClick();
  };

  const handleGenerateInvoicesClick = () => {
    setSelectedView("generateClientInvoices");
    onGenerateClientInvoicesClick();
  };

  const handlePastInvoicesClick = () => {
    setSelectedView("pastClientInvoices");
    onPastClientInvoicesClick();
  };

  const handleGeneratePayslipsClick = () => {
    setSelectedView("generatePayslips");
    onGeneratePayslipsClick();
  };

  const handlePastPayslipsClick = () => {
    setSelectedView("pastPayslips");
    onPastPayslipsClick();
  };
  return (
    <>
      <div className="SidebarContainer">
        <div className="cstm-sideber-top">
          <span
            className="SidebarTitleText"
            onClick={handleFinanceDashboardClick}
            style={{
              cursor: "pointer",
            }}
          >
            Finance Dashboard
          </span>

          <Divider />

          <div className="cstm-sideber-top-ove">
            <div className="cstm-sideber-top-box">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <span className="SidebarElementTextNoPadding">
                  Client Invoices
                </span>

                <ClickableText
                  text={"Generate Invoice"}
                  onClick={handleGenerateInvoicesClick}
                  isSelected={selectedView === "generateClientInvoices"}
                />

                <ClickableText
                  text={"Past Invoices"}
                  onClick={handlePastInvoicesClick}
                  isSelected={selectedView === "pastClientInvoices"}
                />
              </div>
            </div>

            <Divider />

            <div className="cstm-sideber-top-box">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <span className="SidebarElementTextNoPadding">
                  Employee Payments
                </span>

                <ClickableText
                  text={"Generate Payslip"}
                  onClick={handleGeneratePayslipsClick}
                  isSelected={selectedView === "generatePayslips"}
                />

                <ClickableText
                  text={"Past Paylips"}
                  onClick={handlePastPayslipsClick}
                  isSelected={selectedView === "pastPayslips"}
                />
              </div>
            </div>

            <Divider />

            <div className="cstm-sideber-top-box">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <span className="SidebarElementTextNoPadding">
                  Sub-contractor Expenses
                </span>

                <ClickableText
                  text={"Generate Invoice"}
                  onClick={() => {
                    console.log("qwe");
                  }}
                />

                <ClickableText
                  text={"Past Invoices"}
                  onClick={() => {
                    console.log("qwe");
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="cstm-sideber-bottom">
          <span className="SidebarElementText">Need help?</span>
          <span className="need-help-link">
            <ul>
              <li>
                <a href="#">Report a bug</a>
              </li>
              <li>
                <a href="#">Contact SureSight support</a>
              </li>
            </ul>
          </span>
        </div>
      </div>
    </>
  );
};

export default FinanceSidebar;
