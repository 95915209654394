const HomeFormFooterComponent = () => {
  return (
    <div className="footer">
      <div className="footer-links">
        <a href="/privacy-policy">Privacy Policy</a>
        <span className="separator"> </span>
        <a href="/terms-and-conditions">Terms & Conditions</a>
      </div>
    </div>
  );
};

export default HomeFormFooterComponent;
