import React from "react";
import { Button } from "../Button/Button";
import "./HomePage.css";
import mob from "../../img/mob.png";
import FirstLogo from "../../img/WI_3.png";
import SecondLogo from "../../img/WI_4.png";
import ThirdLogo from "../../img/WI_2.png";
import FourthLogo from "../../img/WI_1.png";
import DeskLogo from "../../img/desk2.png";
import HomeBubbleComponent from "../Home/HomeBubbleComponent";

const HomePage = ({ onRequestDemoClick }) => {
  return (
    <div>
      <div className="home-page-container">
        <div
          className="home-page-title"
          style={{
            width: "36vw",
          }}
        >
          SURESIGHT – Manage your business with one app
        </div>

        <div
          className="home-page-subtext"
          style={{
            width: "38.5vw",
          }}
        >
          The employee management system for industries where rostering,
          sub-contracting and critical incident reporting is essential
        </div>

        <Button
          buttonStyle="btn--home"
          styles={{
            marginTop: "2.6vh",
            height: "6.5vh",
            width: "11.8vw",
          }}
          onClick={onRequestDemoClick}
        >
          Request Demo
        </Button>

        <div className="home-page-mob-container">
          <img className="home-page-mob-img" src={mob} />
          <div className="home-page-mob-sub-container">
            <div className="home-page-title">
              Fully customisable app to easily manage frontline employees and
              subcontractors
            </div>
            <div
              className="home-page-subtext"
              style={{
                width: "34.1vw",
              }}
            >
              Quick rostering. Instant shift allocation. Apply for leave. Pay
              the correct penalty rates. Live updates.
            </div>
            <Button
              buttonStyle="btn--home"
              styles={{
                marginTop: "2.6vh",
                height: "6.5vh",
                width: "11.8vw",
              }}
            >
              Learn more
            </Button>
          </div>
        </div>

        <div
          className="advanced-management-dashboard-main"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className="home-page-title">Advanced management dashboard</div>
          <div className="home-page-subtext">
            Advanced and easy to use applications.
          </div>
        </div>

        <div
          className="icons-container-row"
          style={{
            marginTop: "7.8vh",
          }}
        >
          <HomeBubbleComponent
            icon={FirstLogo}
            titleText={"Rostering"}
            subtitleText={
              "Make sure the right people are in the right place at the right time"
            }
          />

          <HomeBubbleComponent
            icon={SecondLogo}
            titleText={"Incident reporting"}
            subtitleText={
              "One-click incident reporting with time stamped images"
            }
          />
        </div>

        <div
          className="icons-container-row"
          style={{
            marginTop: "4vh",
          }}
        >
          <HomeBubbleComponent
            icon={ThirdLogo}
            titleText={"Manage sub-contractors"}
            subtitleText={
              "Sub-contractors can quickly sync their roster to the main roster across multiple clients"
            }
          />
          <HomeBubbleComponent
            icon={FourthLogo}
            titleText={"Access anywhere"}
            subtitleText={
              "Accessible from anywhere with an internet connection"
            }
          />
        </div>
        <div
          className="icons-container-row"
          style={{
            marginTop: "4vh",
          }}
        >
          <HomeBubbleComponent
            icon={ThirdLogo}
            titleText={"Integrate with accounting software"}
            subtitleText={"Automatically calculate wages and penalty rates"}
          />
          <HomeBubbleComponent
            icon={FourthLogo}
            titleText={"Easy onboarding"}
            subtitleText={
              "Onboard new staff and sub-contractors easily and efficiently"
            }
          />
        </div>

        <div className="home-page-desk-container">
          <img className="home-page-desk-image" src={DeskLogo} />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
