import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminApi from "../../api/AdminApi";

import Navbar1 from "../../components/Navbar/Navbar1";
import EmployeeDetailSidebar from "../../components/Sidebar/EmployeeDetailSidebar";
import { Divider } from "../../components/Common/Divider";
import DocumentUploadPopup from "../../components/Employee/DocumentUploadPopup";
import { SuccessMsgPopup } from "../../components/Employee/SuccessMsgPopup";
import Loader from "../../components/Loader/Loader";

import { convertUTCToDateString } from "../../util/DateTimeUtils";

import { EMPLOYEE_SIDEBAR_OPTIONS } from "./EmployeeDetailsSidebarOptions";

import EmployeeDetails from "./EmployeeDetails";
import Documents from "./Documents";

const EmployeeDetailsPage = () => {
  const sidebarOptions = Object.values(EMPLOYEE_SIDEBAR_OPTIONS);
  const [loader, setLoader] = useState(true);
  const [selectedOption, setSelectedOption] = useState(
    EMPLOYEE_SIDEBAR_OPTIONS.DETAILS
  );

  // set popup
  const [isError, setIsError] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const handleOnCancelClick = () => {
    setIsOpen(false);
  };
  const handleOnIsSuccessClick = () => {
    setIsSuccess(false);
    setIsOpen(false);
    setIsError("");
  };
  const handleOnContinueClick = async (empUpload) => {
    if (!empUpload.upload) {
      setIsError("Please Upload Document");
    } else {
      const formData = new FormData();
      formData.append("file", empUpload.upload);
      formData.append("employeeId", id);
      try {
        const uploadEmployeeDocResponse = await AdminApi.uploadEmployeeDocument(
          formData
        );
        setLoader(true);
        if (uploadEmployeeDocResponse.status === 200) {
          setLoader(false);
          setIsSuccess(true);
        }
      } catch (error) {
        setIsError(error.message);
      }
    }
  };

  const [employeeDetails, setEmployeeDetails] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    fetchEmployeeDeatils();
  }, []);
  async function fetchEmployeeDeatils() {
    const formData = new FormData();
    formData.append("employeeId", id);
    try {
      const response = await AdminApi.fetchEmployeeDeatils(formData);
      if (response.status) {
        setEmployeeDetails(response.data.response);
      }
    } catch (error) {
      navigate("/employees");
    } finally {
      setLoader(false);
    }
  }

  if (loader) return <Loader />;

  return (
    <>
      <Navbar1 />
      <div className="cstm-contant-main">
        <div className="cstm-contant-ed">
          <EmployeeDetailSidebar
            options={sidebarOptions}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />

          <div className="cstm-contant-ed-right">
            <div className="cstm-pfo-main">
              <div className="cstm-pfo-main-left">{selectedOption.label}</div>
              <div className="cstm-pfo-main-right">
                {employeeDetails.dateCreated && (
                  <div className="cstm-pfo-title">
                    Employee added on{" "}
                    {convertUTCToDateString(employeeDetails.dateCreated)}
                  </div>
                )}
              </div>
            </div>
            <Divider />
            {selectedOption === EMPLOYEE_SIDEBAR_OPTIONS.DETAILS && (
              <EmployeeDetails employeeDetails={employeeDetails} />
            )}
            {selectedOption === EMPLOYEE_SIDEBAR_OPTIONS.DOCUMENTS && (
              <Documents employeeDetails={employeeDetails} />
            )}
          </div>
        </div>
        {isOpen && (
          <DocumentUploadPopup
            onCancelClick={() => handleOnCancelClick()}
            onDataSubmit={handleOnContinueClick}
            isError={isError}
            loader={loader}
          />
        )}
        {isSuccess && (
          <SuccessMsgPopup onIsSuccessClick={() => handleOnIsSuccessClick()} />
        )}
      </div>
    </>
  );
};

export default EmployeeDetailsPage;
