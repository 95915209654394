import { useState, useEffect } from "react";
import React from "react";
import { useSelector } from "react-redux";
import "../../components/Employee/Employee.css";
import AdminApi from "../../api/AdminApi";
import { Button } from "../Button/Button";
import "../Button/Button.css";

import SubcontractorSearchResultComponent from "../Subcontractor/SubcontractorSearchResultComponent";

const AssignShiftPopup = ({
  shiftId,
  shiftStartTime,
  shiftEndTime,
  onCancelClick,
  onDataSubmit,
}) => {
  const handleKeyPress = (event) => {
    if (event.keyCode === 27) {
      onCancelClick();
    }
  };

  const EMPLOYEES_TYPE_KEY = "EMPLOYEE";
  const SUBCONTRACTOR_TYPE_KEY = "SUBCONTRACTOR";

  const [availableEmployees, setAvailableEmployees] = useState([]);
  const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(null);

  const [subcontractors, setSubcontractors] = useState([]);
  const [selectedSubcontractorIndex, setSelectedSubcontractorIndex] =
    useState(null);

  const [selectedGroup, setSelectedGroup] = useState(EMPLOYEES_TYPE_KEY);

  const { adminId } = useSelector((state) => state.auth);

  useEffect(() => {
    fetchAvailableEmployees();
  }, []);

  useEffect(() => {
    fetchSubcontractors();
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const fetchAvailableEmployees = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("startTime", shiftStartTime);
    formData.append("endTime", shiftEndTime);
    try {
      const response = await AdminApi.fetchAvailableEmployees(formData);
      if (response.status === 200) {
        setAvailableEmployees(response.data.response.employees);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSubcontractors = async () => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    try {
      const response = await AdminApi.fetchAllSubcontractors(formData);
      if (response.status === 200) {
        setSubcontractors(response.data.response.subContractors);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function assignShiftToEmployee() {
    if (selectedEmployeeIndex === null) {
      alert("No employee selected");
      return;
    }

    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("shiftId", shiftId);
    formData.append("employeeId", availableEmployees[selectedEmployeeIndex].id);
    try {
      const response = await AdminApi.assignShiftToEmployee(formData);
      if (response.status) {
        onDataSubmit();
      }
    } catch (error) {
      alert("monka");
    }
  }

  async function assignShiftToSubcontractor() {
    if (selectedSubcontractorIndex === null) {
      alert("No subcontractor selected");
      return;
    }

    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("shiftId", shiftId);
    formData.append(
      "subContractorId",
      subcontractors[selectedSubcontractorIndex].id
    );
    try {
      const response = await AdminApi.assignShiftToSubcontractor(formData);
      if (response.status) {
        onDataSubmit();
      }
    } catch (error) {
      alert("monka");
    }
  }

  const renderChooseGroupOptions = () => {
    const groupOptions = [];
    groupOptions.push(<option value={EMPLOYEES_TYPE_KEY}>Employees</option>);
    groupOptions.push(
      <option value={SUBCONTRACTOR_TYPE_KEY}>Subcontractors</option>
    );
    return groupOptions;
  };

  const onGroupOptionChangedHandler = (event) => {
    setSelectedGroup(event.target.value);
  };

  const handleSubcontractorSelect = (index) => {
    setSelectedSubcontractorIndex(index);
  };

  const handleEmployeeSelect = (index) => {
    setSelectedEmployeeIndex(index);
  };

  const submitFormData = (e) => {
    e.preventDefault();

    if (selectedGroup == EMPLOYEES_TYPE_KEY) {
      assignShiftToEmployee();
    } else {
      assignShiftToSubcontractor();
    }
  };

  return (
    <div className="AddEmployeePopupViewContainer">
      <form className="CreateShiftPopupContainer" onSubmit={submitFormData}>
        <span className="AddEmployeePopupTitle">Assign shift</span>
        <div className="InputContainer">
          <div style={{ display: "flex", gap: "12px" }}>
            {/* Group selector start*/}
            <div className="drop-down-with-text-container">
              <span className="InputTitles">Choose a group</span>

              <select
                style={{ marginTop: "8px" }}
                className="select-all cstm-lastdays"
                onChange={onGroupOptionChangedHandler}
              >
                {renderChooseGroupOptions()}
              </select>
            </div>
            {/* Group selector end*/}
          </div>

          <div style={{ marginTop: "8px" }}>
            {selectedGroup === EMPLOYEES_TYPE_KEY && (
              <SubcontractorSearchResultComponent
                results={availableEmployees}
                selectedResult={selectedEmployeeIndex}
                onResultSelect={handleEmployeeSelect}
                isEmployee={true}
              />
            )}
            {selectedGroup === SUBCONTRACTOR_TYPE_KEY && (
              <SubcontractorSearchResultComponent
                results={subcontractors}
                selectedResult={selectedSubcontractorIndex}
                onResultSelect={handleSubcontractorSelect}
              />
            )}
          </div>

          {/* Footer Buttons */}
          <div
            style={{
              display: "flex",
              alignSelf: "flex-end",
              marginTop: "32px",
            }}
          >
            <Button
              onClick={onCancelClick}
              buttonStyle="btn--outline"
              styles={{
                marginLeft: "auto",
              }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              styles={{
                marginLeft: "12px",
              }}
            >
              Continue
            </Button>
          </div>
          {/* Footer Buttons end*/}
        </div>
      </form>
    </div>
  );
};

export default AssignShiftPopup;
