// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-results-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-results-list li {
  padding: 8px;
  cursor: pointer;
  text-align: left; /* Align text to the left */
}

.search-results-list li:hover {
  background-color: #f0f0f0;
}

.search-results-list li.selected {
  background-color: #007bff;
  color: #fff;
}

.no-subcontractors-found p {
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Subcontractor/SearchResult.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,gBAAgB,EAAE,2BAA2B;AAC/C;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".search-results-list {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n}\n\n.search-results-list li {\n  padding: 8px;\n  cursor: pointer;\n  text-align: left; /* Align text to the left */\n}\n\n.search-results-list li:hover {\n  background-color: #f0f0f0;\n}\n\n.search-results-list li.selected {\n  background-color: #007bff;\n  color: #fff;\n}\n\n.no-subcontractors-found p {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
