import { useState } from "react";
import { Button } from "../Button/Button";
import { Link } from "react-router-dom";
import Logo from "../../img/logo.png";

import "./HomeNavbar.css";
import Dropdown from "./Dropdown";
import { useSelector } from "react-redux";

const HomeNavbar = ({ onNavbarRequestDemoClicked }) => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    setDropdown(true);
    // if (window.innerWidth < 960) {
    //   setDropdown(false);
    // } else {
    //   setDropdown(true);
    // }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const handleDropdown = () => {
    if (window.innerWidth < 960) {
      setDropdown(true);
    } else {
      setDropdown(true);
    }
  };

  return (
    <>
      <nav className="navbar header-main">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img
            className="header-logo"
            style={{
              width: "250px",
            }}
            src={Logo}
          />
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "home-nav-menu "}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              Case studies
            </Link>
          </li>
          <li
            className="nav-item cstm-drop-box"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Link className="nav-links" onClick={() => setDropdown(!dropdown)}>
              About us <i className="fas fa-caret-down" />
            </Link>
            {dropdown && <Dropdown />}
          </li>
          <li className="nav-item">
            <Link
              to="/products"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Clients
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/downloads"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Mobile App
            </Link>
          </li>
          <li className="nav-request-memo">
            <Button
              buttonStyle="btn--home"
              onClick={onNavbarRequestDemoClicked}
            >
              Request Demo
            </Button>
          </li>
          <li className="nav-login">
            <Link
              to="/login"
              className="header-loginbtm"
              onClick={closeMobileMenu}
            >
              <Button buttonStyle="btn--secondary">Login</Button>
            </Link>
          </li>
          {/* <li>
            <Link
              to="/sign-up"
              className="nav-links-mobile"
              onClick={closeMobileMenu}
            >
              Sign Up
            </Link>
          </li> */}
        </ul>
      </nav>
    </>
  );
};

export default HomeNavbar;
