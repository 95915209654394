import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import "../../components/Employee/Employee.css";
import { Button } from "../Button/Button";
import "../Button/Button.css";
import { Divider } from "../../components/Common/Divider";

import "./SearchResult.css";

import SubcontractorSearchResultComponent from "./SubcontractorSearchResultComponent";
import NoSubcontractorFoundComponent from "./NoSubcontractorFoundComponent";

import { Search } from "@mui/icons-material";

import AdminApi from "../../api/AdminApi";

const AddSubcontractorPopup = ({ onCancelClick, onDataSubmit }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSubContractorId, setSelectedSubcontractorId] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [showInvite, setShowInvite] = useState(false);

  const [subContractorEmail, setSubContractorEmail] = useState("");
  const [subContractorFirstName, setSubContractorFirstName] = useState("");
  const [subContractorLastName, setSubContractorLastName] = useState("");

  const { adminId } = useSelector((state) => state.auth);

  const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [searchTerm]);

  const handleKeyPress = (event) => {
    if (event.keyCode === 27) {
      onCancelClick();
    } else if (event.keyCode === 13) {
      event.preventDefault();
      searchOrganisation(searchTerm);
    }
  };

  async function searchOrganisation() {
    if (searchTerm === "") {
      alert("Enter search term");
      return;
    }
    setSelectedSubcontractorId(null);
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("searchTerm", searchTerm);
    try {
      const response = await AdminApi.searchOrganisation(formData);
      if (response.status) {
        const subby = response.data.response;
        if (
          subby === undefined ||
          subby === null ||
          Object.keys(subby).length === 0 ||
          subby.organisations.length === 0
        ) {
          setError(true);
          setSuccess(false);
        } else {
          setSearchResults(subby.organisations);
          setSuccess(true);
          setError(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function addSubcontractor() {
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("subContractorId", selectedSubContractorId);
    try {
      const response = await AdminApi.addSubcontractor(formData);
      console.log(response);
      if (response.status === 200 && response.data.errorMessage === "") {
        onDataSubmit();
      } else {
        alert(response.data.errorMessage);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function inviteSubcontractor() {
    if (!emailRegex.test(subContractorEmail)) {
      alert("Please enter valid email");
      return;
    }
    if (subContractorFirstName === "" || subContractorLastName === "") {
      alert("Please enter name of subcontractor");
      return;
    }
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("emailId", subContractorEmail);
    formData.append("subContractorFirstName", subContractorFirstName);
    formData.append("subContractorLastName", subContractorLastName);
    try {
      const response = await AdminApi.inviteSubcontractor(formData);
      console.log(response);
      if (response.status === 200 && response.data.errorMessage === "") {
        onDataSubmit();
      } else {
        alert(response.data.errorMessage);
        onCancelClick();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleResultSelect = (index) => {
    setSelectedIndex(index);
    setSelectedSubcontractorId(searchResults[index].id);
  };

  const onAddSubcontractorClicked = () => {
    if (selectedSubContractorId === "" || selectedSubContractorId === null) {
      alert("No subcontractor chosen");
      return;
    }

    addSubcontractor();
  };

  const handleInviteClick = () => {
    setShowInvite(true);
  };

  return (
    <div className="AddEmployeePopupViewContainer">
      <div className="AddEmployeePopupContainer">
        {!showInvite && (
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className="AddEmployeePopupTitle">Add Subcontractor</span>
            </div>
            <Divider />
            <div className="InputContainer">
              <div style={{ marginTop: "16px" }}>
                <div style={{ display: "flex" }}>
                  <span className="InputTitles">Search for sub-contractor</span>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginTop: "5px",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <input
                    className="Input-Full"
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Search
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      searchOrganisation();
                    }}
                  />
                </div>
              </div>

              <div style={{ marginTop: "16px" }}>
                {error && (
                  <NoSubcontractorFoundComponent
                    onInviteClick={handleInviteClick}
                  />
                )}
                {success && (
                  <SubcontractorSearchResultComponent
                    results={searchResults}
                    selectedResult={selectedIndex}
                    onResultSelect={handleResultSelect}
                  />
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  alignSelf: "flex-end",
                  marginTop: "32px",
                }}
              >
                <Button
                  onClick={onCancelClick}
                  buttonStyle="btn--outline"
                  styles={{
                    marginLeft: "auto",
                  }}
                >
                  Cancel
                </Button>

                <Button
                  onClick={onAddSubcontractorClicked}
                  styles={{
                    marginLeft: "12px",
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>
        )}

        {showInvite && (
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className="AddEmployeePopupTitle">
                Invite Subcontractor
              </span>
            </div>
            <Divider />
            <div className="InputContainer">
              <div style={{ marginTop: "24px" }}>
                <div style={{ display: "flex", marginTop: "5px" }}>
                  <input
                    className="Input"
                    type="text"
                    placeholder="First name"
                    value={subContractorFirstName}
                    onChange={(e) => setSubContractorFirstName(e.target.value)}
                  />
                  <input
                    className="Input"
                    type="text"
                    style={{ marginLeft: "11px" }}
                    placeholder="Last name"
                    value={subContractorLastName}
                    onChange={(e) => setSubContractorLastName(e.target.value)}
                  />
                </div>
              </div>
              <div style={{ marginTop: "16px" }}>
                <div
                  style={{
                    display: "flex",
                    marginTop: "5px",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <input
                    className="Input-Full"
                    type="text"
                    value={subContractorEmail}
                    placeholder="Enter email address"
                    onChange={(e) => setSubContractorEmail(e.target.value)}
                  />
                </div>
              </div>

              {/* Footer buttons */}
              <div
                style={{
                  display: "flex",
                  alignSelf: "flex-end",
                  marginTop: "32px",
                }}
              >
                <Button
                  onClick={onCancelClick}
                  buttonStyle="btn--outline"
                  styles={{
                    marginLeft: "auto",
                  }}
                >
                  Cancel
                </Button>

                <Button
                  onClick={() => {
                    inviteSubcontractor();
                  }}
                  styles={{
                    marginLeft: "12px",
                  }}
                >
                  Invite
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddSubcontractorPopup;
