const BASE_URL = "schedit.com.au/api";
const PORT = "8080";
const VERSION = "v2";
export const API_PATH = "https://" + BASE_URL + "/" + VERSION + "/";
export const ADMIN_API_PATH = API_PATH + "admin/";
export const EMPLOYEE_API_PATH = API_PATH + "employee/";
export const SITE_API_PATH = API_PATH + "site/";
export const SHIFT_API_PATH = API_PATH + "shift/";
export const CLIENT_API_PATH = API_PATH + "client/";
export const UPLOAD_EMP_API_PATH = API_PATH + "upload/employee/";
export const ORGANISATION_API_PATH = API_PATH + "organisation/";
export const PAYSLIP_API_PATH = API_PATH + "finance/payslip/";
export const INVOICE_API_PATH = API_PATH + "finance/invoice/";
export const INCIDENTS_API_PATH = API_PATH + "incident/";
