import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button/Button";
import { useDispatch } from "react-redux";
import Logo from "../../img/logo.png";
import { useSelector } from "react-redux";
import AdminApi from "../../api/AdminApi";
const Login = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [checked, setChekded] = useState(true);
  const dispatch = useDispatch();

  const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/
  );

  const validate = () => {
    let loginError = {};
    let isValid = false;
    if (!loginData.password) {
      isValid = true;
      loginError.password = "Enter Password";
    }
    if (!loginData.email) {
      isValid = true;
      loginError.email = "Enter Email";
    } else if (!emailRegex.test(loginData.email)) {
      isValid = true;
      loginError.email = "Enter Valid Email";
    } else {
      loginError.email = "";
    }
    setError(loginError);
    return isValid;
  };
  useEffect(() => {
    if (isLoggedIn) {
      window.location.href = "/employees";
    }
  }, []);
  //login Api
  const formData = new FormData();
  formData.append("email", loginData.email);
  formData.append("password", loginData.password);
  async function authAdmin() {
    try {
      const response = await AdminApi.loginAdmin(formData);

      if (response.status) {
        setMessage("");
        setSuccessMessage("Login Success");
        setTimeout(() => {
          localStorage.setItem(
            "accessToken",
            response.data.response.accessToken
          );
          localStorage.setItem("adminId", response.data.response.adminId);
          localStorage.setItem("auth", response.data.response.email);
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("StayLoggedIn", checked);
          dispatch({
            type: "LOGIN_SUCCESS",
            payload: response.data.response.email,
          });
          dispatch({ type: "stay", payload: checked });
          if (response.data.response.role === "CEO") {
            localStorage.setItem("role", "CEO");
            window.location.href = "/manage";
          } else {
            window.location.href = "/employees";
          }
        }, 2000);
      }
    } catch (error) {
      setMessage(error.response.data.errorMessage);
      console.log(error, "err");
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate(loginData)) {
      authAdmin();
    }
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
    switch (name) {
      case "password":
        value === ""
          ? setError({ ...error, password: "Enter Password" })
          : setError({ ...error, password: "" });
        break;
      case "email":
        value === ""
          ? setError({ ...error, email: "Enter email" })
          : !emailRegex.test(value)
          ? setError({ ...error, email: "Enter valid email" })
          : setError({ ...error, email: "" });
      default:
        break;
    }
  };
  return (
    <>
      <div className="cstm-form-man">
        <div className="cstm-form-man-in">
          <div className="cstm-form-man-logo">
            <Link to="/">
              <img src={Logo} />
            </Link>
          </div>
          <div className="cstm-form-man-info">
            <form>
              <div className="cstm-form-title">Login</div>
              <span className="error-message cstm-es">{message}</span>
              <span className="success-message cstm-es">{successMessage}</span>
              <div className="cstm-form-box">
                <div className="cstm-form-box-top">
                  Email<span className="form-asterisk">*</span>
                </div>
                <div className="cstm-form-box-bottom">
                  <input
                    autoComplete="off"
                    placeholder="Enter Email"
                    className="cstm-input-fm"
                    type="email"
                    name="email"
                    onChange={handleOnChange}
                    value={loginData.email}
                  />
                  <span className="error-message"> {error.email}</span>
                </div>
              </div>

              <div className="cstm-form-box">
                <div className="cstm-form-box-top">
                  Password<span className="form-asterisk">*</span>
                </div>
                <div className="cstm-form-box-bottom">
                  <input
                    placeholder="Enter Password"
                    className="cstm-input-fm"
                    type="password"
                    name="password"
                    onChange={handleOnChange}
                    value={loginData.password}
                  />

                  <span className="error-message"> {error.password}</span>
                </div>
              </div>

              <div className="cstm-form-box2">
                <div className="cstm-form-box2-stay">
                  <label>
                    <input
                      type="checkbox"
                      defaultChecked={true}
                      onChange={() => setChekded(!checked)}
                    />{" "}
                    Keep me signed in
                  </label>
                </div>
                <div className="cstm-form-box2-fg">
                  <Link to="/forgot-password">Forgot password?</Link>
                </div>
              </div>

              <div className="cstm-form-box3">
                <Button type="submit" onClick={handleSubmit}>
                  Login
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
