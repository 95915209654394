import "./Sidebar.css";
import { Divider } from "../Common/Divider";
import { useNavigate } from "react-router-dom";

const ShiftSidebar = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="SidebarContainer">
        <div className="cstm-sideber-top">
          <span
            className="ShiftSidebarTitleText"
            onClick={() => navigate("/shifts")}
          >
            All Shifts
          </span>

          <Divider />

          <div className="cstm-sideber-top-ove">
            <div className="cstm-sideber-top-box">
              <span
                className="SidebarElementText2"
                onClick={() => navigate("/upcoming-shift")}
              >
                Upcoming shifts
              </span>
              <span className="SidebarElementText2">
                <span className="yellow-dot"></span>Accept-Pending Shifts
              </span>
              <span className="SidebarElementText2">
                <span className="green-dot"></span>Accepted Shifts
              </span>
            </div>

            <Divider />

            <div className="cstm-sideber-top-box">
              <span
                className="SidebarElementText2"
                onClick={() => navigate("/declined-shift")}
              >
                <span className="red-dot"></span>Declined shifts
              </span>
            </div>

            <Divider />

            <div className="cstm-sideber-top-box">
              <span
                className="SidebarElementText2"
                onClick={() => navigate("/past-shift")}
              >
                Past shifts
              </span>
            </div>

            <Divider />

            <div className="cstm-sideber-top-box">
              <span className="SidebarElementText2 mrg-btm">
                Employee created shifts
              </span>
              <span className="SidebarElementSubText">
                Accept or reject the shifts created by the employee.
              </span>
            </div>

            <Divider />

            <div className="cstm-sideber-top-box">
              <span className="SidebarElementText2">Checklists</span>
            </div>
          </div>
        </div>

        <div className="cstm-sideber-bottom">
          <span className="SidebarElementText">Need help?</span>
          <span className="need-help-link">
            <ul>
              <li>
                <a href="#">Report a bug</a>
              </li>
              <li>
                <a href="#">Contact SureSight support</a>
              </li>
            </ul>
          </span>
        </div>
      </div>
    </>
  );
};

export default ShiftSidebar;
