import { Box, Typography } from '@mui/material'
import React from 'react'
import DashboardShifts from './DashboardCenterComponents/DashboardShifts'
import DashboardNotification from '../DashboardNotification/DashboardNotification'
import { Divider } from '../../../components/Common/Divider'
import DashboardIncidents from './DashboardCenterComponents/DashboardIncidents'
import DashboardGenerateFinance from './DashboardCenterComponents/DashboardGenerateFinance'
import DashboardEmployees from './DashboardCenterComponents/DashboardEmployees'
import DashboardStatistics from './DashboardCenterComponents/DashboardStatistics'

const DashboardCenterView = () => {
  return (
    <>
      <Box className='custom-scrollbar cstm-contant-main-cont-all' sx={{
        width: '100%',
        overflow: 'auto',
        display: { sm: 'flex' },
        alignItems: { sm: 'flex-start' },
        height: { sm: 'calc(100vh - 60px)' },
      }}>
        <Box className="cstm-contant-main-cont-all-main" sx={{
          // minHeight: '100vh',
          width: { sm: '100%' },
          '& .section-spacing:not(:last-child)': {
            paddingBottom: { md: '64px', sx: '40px', xs: '30px' }
          }
        }}>

          <Box sx={{
            pb: '25px'
          }}>
            <Box className="cstm-cont-header">
              <Box className="cstm-cont-header-title">
                <h1>Dashboard</h1>
              </Box>
            </Box>

            <Divider />
          </Box>

          {/* Shifts */}
          <DashboardStatistics />

          {/* Shifts */}
          <DashboardShifts />

          {/* Incidents */}
          <DashboardIncidents />

          {/* Generate Finance lists */}
          <DashboardGenerateFinance />

          {/* Employees  */}
          <DashboardEmployees />

        </Box>
        <DashboardNotification />
      </Box>
    </>
  )
}

export default DashboardCenterView