import React, { useEffect, useState, forwardRef } from "react";

const CustomPagination = (props) => {
    const {
        label,
        page,
        rowsPerPage,
        count,
        onChangePage,
        rowsPerPageOptions,
        onChangeRowsPerPage
    } = props;

    let from = rowsPerPage * page + 1;
    let to = rowsPerPage * (page + 1);
    if (to > count) {
        to = count;
    }
    return (
        <td className="cstm-pagination-container">
            <div className="cstm-contant-main-cont-table-header-bottom">
                <div className="cstm-contant-main-cont-table-header-bottom-left">{label}<span className="cstm-cs-cont">{count}</span></div>
                <div className="cstm-contant-main-cont-table-header-bottom-right">
                    <div className="show-rows-title">Show rows :</div>
                    <div className="all-employee"><select className="select-pg" value={rowsPerPage}
                        onChange={onChangeRowsPerPage}>  {rowsPerPageOptions.map((x, i) => (
                            <option value={x} key={i}>
                                {x}
                            </option>
                        ))}</select></div>
                    <div className="pg-nbr">{from}-{to} of {count}</div>
                    <div className="pg-ar">
                        <button className="pg-ar-left" disabled={page === 0}
                            onClick={(e) => onChangePage(e, 0)}><span className="material-icons">chevron_left</span></button>
                        <button className="pg-ar-right" disabled={page === 0}
                            onClick={(e) => onChangePage(e, page - 1)}><span className="material-icons">chevron_right</span></button>
                    </div>
                </div>
            </div>

        </td>
    );
};
export default CustomPagination;