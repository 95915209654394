import { Box, Typography } from "@mui/material";
import React from "react";
import { images } from "../../../../img";

import { useNavigate } from "react-router-dom";

export const GradientBlueBox = ({
  text = "Main Text",
  subText = "Sub Text",
  onClick,
}) => {
  return (
    <>
      <Box
        sx={{
          maxWidth: "186px",
          width: "100%",
          minHeight: "90px",
          borderRadius: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          backgroundColor: "#0C6CF2",
          backgroundImage: `url(${images.rectangleGradientBg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <Typography
          variant="body1"
          color="#fff"
          fontSize={"14px"}
          lineHeight={1.3}
          letterSpacing="3%"
        >
          {text} <br /> {subText}
        </Typography>
      </Box>
    </>
  );
};

const DashboardGenerateFinance = () => {
  const navigate = useNavigate();

  const onClientInvoicesClick = () => {
    navigate("/finances", { state: { selectedTab: "generateClientInvoices" } });
  };

  const onEmployeePayslipsClick = () => {
    navigate("/finances", { state: { selectedTab: "generatePayslips" } });
  };

  const onSubcontractorExpensesClick = () => {
    navigate("/finances", { state: { selectedTab: "financeDashboard" } });
  };
  return (
    <>
      <Box className="section-spacing">
        <Box>
          <Typography variant="h3">Generate Finance lists</Typography>
          <Typography variant="body2">
            Lorem ipsum dolor sit amet consectetur. Massa erat interdum id a est
            massa.
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              flexWrap: "wrap",
              mt: "30px",
              marginLeft: "-24px",
              marginBottom: "-24px",
              "& > div": {
                marginLeft: "24px",
                marginBottom: "24px",
              },
            }}
          >
            <GradientBlueBox
              text="CLIENT"
              subText="Invoices"
              onClick={onClientInvoicesClick}
            />
            <GradientBlueBox
              text="EMPLOYEE"
              subText="Payslips"
              onClick={onEmployeePayslipsClick}
            />
            <GradientBlueBox
              text="SUB-CONTRACTOR"
              subText="Expenses"
              onClick={onSubcontractorExpensesClick}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DashboardGenerateFinance;
