function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export function convertUTCToDateString(utcTimestamp) {
  const date = new Date(utcTimestamp);

  const options = { month: "long", year: "numeric" };
  const dateFormatter = new Intl.DateTimeFormat("en-US", options);

  const formattedDate = dateFormatter.format(date);

  // Add the appropriate suffix to the day (e.g., "1st", "2nd", "3rd", "4th", etc.)
  const day = date.getDate();
  const daySuffix = getDaySuffix(day);
  const dateString = `${day}${daySuffix} ${formattedDate}`;

  return dateString;
}

export function convertUTCToDateWithDayString(utcTimestamp) {
  const date = new Date(utcTimestamp);

  const options = { month: "long", year: "numeric" };
  const dateFormatter = new Intl.DateTimeFormat("en-US", options);

  const dayOptions = { weekday: "long" };
  const dayDateFormatter = new Intl.DateTimeFormat("en-US", dayOptions);

  const formattedDate = dateFormatter.format(date);
  const formattedDay = dayDateFormatter.format(date);

  // Add the appropriate suffix to the day (e.g., "1st", "2nd", "3rd", "4th", etc.)
  const day = date.getDate();
  const daySuffix = getDaySuffix(day);
  const dateString = `${formattedDay}, ${day}${daySuffix} ${formattedDate}`;

  return dateString;
}

export function convertUTCTo24HourString(utcTimestamp) {
  const date = new Date(utcTimestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedTime = `${String(hours).padStart(2, "0")}${String(
    minutes
  ).padStart(2, "0")}`;
  return formattedTime;
}

export function convertDurationToHoursMinutes(durationInMilliseconds) {
  const hours = Math.floor(durationInMilliseconds / 3600000); // 1 hour = 3600000 milliseconds
  const minutes = Math.floor((durationInMilliseconds % 3600000) / 60000); // 1 minute = 60000 milliseconds

  let result = "";

  if (hours > 0) {
    result += `${hours} ${hours === 1 ? "hour" : "hours"}`;
  }

  if (minutes > 0) {
    result += ` ${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
  }

  return result.trim();
}
