import { useState } from "react";
import React from "react";
import "./Employee.css";
import { Button } from "../Button/Button";
import "../Button/Button.css";
import Alert from "@mui/material/Alert";
import Loader from "../Loader/Loader";

const DocumentUploadPopup = ({
  onCancelClick,
  onDataSubmit,
  isError,
  loader,
}) => {
  console.log("loader", loader);
  const [upload, setUpload] = useState(null);
  const submitFormData = (e) => {
    e.preventDefault();
    onDataSubmit({ upload });
    setUpload("");
  };
  if (loader) return <Loader />;

  const onFileSelected = (event) => {
    setUpload("");
    if (event.target.files.length > 0) {
      const fileSize = event.target.files[0].size;

      if (fileSize / 1024 > 5) {
        alert("File size is too big, please select a file less than 5mb");
        event.target.value = null;
      } else {
        setUpload(event.target.files[0]);
      }
    }
  };
  return (
    <div className="AddEmployeePopupViewContainer csrm-popup-document-upload">
      <form className="AddEmployeePopupContainer" onSubmit={submitFormData}>
        <span className="AddEmployeePopupTitle">Upload Document</span>
        <div className="InputContainer">
          <div className="csrm-document-upload-img">
            <input
              type="file"
              accept=".pdf,.jpeg,.png"
              onChange={onFileSelected}
            />
            {isError && <Alert>{isError}</Alert>}
          </div>
          <div className="csrm-document-upload-btn">
            <Button onClick={onCancelClick} buttonStyle="btn--outline">
              Cancel
            </Button>
            <Button type="submit">Continue</Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DocumentUploadPopup;
