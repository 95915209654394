import { LOGIN_SUCCESS } from "../actions/type";

const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
const auth = localStorage.getItem("auth");
const accessToken = localStorage.getItem("accessToken");
const adminId = localStorage.getItem("adminId");
const stay = JSON.parse(localStorage.getItem("StayLoggedIn"));
const role = localStorage.getItem("role");
const initialState = {
  isLoggedIn: isLoggedIn || false,
  auth: auth,
  accessToken: accessToken,
  adminId: adminId,
  StayLoggedIn: stay,
  role: role,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        auth: payload,
        accessToken: accessToken,
        adminId: adminId,
        StayLoggedIn: payload,
        role: role,
      };
    default:
      return state;
  }
}
