import { useState, useEffect } from "react";
import EmployeeHeader from "../../components/Header/EmployeeHeader";
import EmployeeSidebar from "../../components/Sidebar/EmployeeSidebar";
import AddEmployeePopup from "../../components/Employee/AddEmployeePopup";
import AdminApi from "../../api/AdminApi";
import CommonTable from "../../components/Table/CommonTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Divider } from "../../components/Common/Divider";
import { MTablePagination, MTableToolbar } from "material-table";
import { Pagination, TablePagination } from "@mui/material";
import CustomPagination from "../../components/Pagination/CustomPagination";

function createEmployees(srno, firstName, lastName, employeeId, email, phone) {
  return {
    srno,
    firstName,
    lastName,
    employeeId,
    email,
    phone,
  };
}

const EmployeeContainer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [employees, setEmployees] = useState([]);
  const [tblEmployees, setTblEmployees] = useState([]);

  const { adminId, accessToken } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployees();
  }, []);
  useEffect(() => {
    if (employees.length > 0) {
      var newarr = [];
      var arr = [...employees];
      for (var i = 0; i < arr.length; i++) {
        newarr[i] = createEmployees(
          i + 1,
          arr[i].firstName,
          arr[i].lastName,
          arr[i].employeeId,
          arr[i].email,
          arr[i].phone
        );
      }
      setTblEmployees(newarr);
    } else {
      setTblEmployees([]);
    }
  }, [employees]);
  const columns = [
    {
      title: "S.No",
      field: "srno",
      render: (rowData) => <div>{rowData.srno}</div>,
      cellStyle: {
        width: "10px",
        height: "14px",
        margin: "16px 7px 14px 20px",
        fontFamily: "Helvetica",
        fontSize: "14px",
        fontweight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.21",
        letterSpacing: "0.28px",
        textAlign: "center",
        color: "#26659d",
      },
      headerStyle: {
        width: "20px",
        height: "14px",
        margin: "0 56px 0 38px",
        fontFamily: "Helvetica",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.21",
        letterSpacing: "0.28px",
        textAlign: "center",
        color: "#000",
      },
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Employee name",
      field: "firstName",
      render: (rowData) => (
        <div className="css-empname">
          {rowData.firstName + " " + rowData.lastName}
        </div>
      ),
      cellStyle: {
        width: "45px",
        height: "14px",
        margin: "16px 7px 14px 20px",
        fontFamily: "Helvetica",
        fontSize: "14px",
        fontweight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.21",
        letterSpacing: "0.28px",
        textAlign: "center",
        color: "#26659d",
      },
      headerStyle: {
        width: "95px",
        height: "14px",
        margin: "0 56px 0 38px",
        fontFamily: "Helvetica",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.21",
        letterSpacing: "0.28px",
        textAlign: "center",
        color: "#000",
      },
      emptyValue: () => <div>-</div>,
    },
    {
      title: "Employee ID",
      field: "employeeId",
      emptyValue: () => <div>-</div>,
      cellStyle: {
        width: "59px",
        height: "14px",
        margin: "16px 43px 14px 20px",
        opacity: "0.75",
        fontFamily: "Helvetica",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.21",
        letterSpacing: "0.28px",
        textAlign: "center",
        color: "#000",
      },
      headerStyle: {
        width: "105px",
        height: "14px",
        margin: "0 56px 0 38px",
        fontFamily: "Helvetica",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.21",
        letterSpacing: "0.28px",
        textAlign: "center",
        color: "#000",
      },
    },
    {
      title: "Mobile",
      field: "phone",
      emptyValue: () => <div>-</div>,
      headerStyle: {
        width: "105px",
        height: "14px",
        margin: "0 56px 0 38px",
        fontFamily: "Helvetica",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.21",
        letterSpacing: "0.28px",
        textAlign: "center",
        color: "#000",
      },
      cellStyle: {
        width: "59px",
        height: "14px",
        margin: "16px 43px 14px 20px",
        opacity: "0.75",
        fontFamily: "Helvetica",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.21",
        letterSpacing: "0.28px",
        textAlign: "center",
        color: "#000",
      },
    },
    {
      title: "Email address",
      field: "email",
      emptyValue: () => <div>-</div>,
      headerStyle: {
        width: "105px",
        height: "14px",
        margin: "0 56px 0 38px",
        fontFamily: "Helvetica",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.21",
        letterSpacing: "0.28px",
        textAlign: "center",
        color: "#000",
      },
      cellStyle: {
        width: "155px",
        height: "14px",
        margin: "16px 20px 14px",
        opacity: "0.75",
        fontFamily: "Helvetica",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.21",
        letterSpacing: "0.28px",
        textAlign: "center",
        color: "#000",
      },
    },
  ];
  const fetchEmployees = async () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("adminId", adminId);
    try {
      const employeeResponse = await AdminApi.fetchAllEmployees(formData);
      if (employeeResponse.status === 200) {
        setEmployees(employeeResponse.data.response.employees);
        setTotalRows(employeeResponse.data.response.employees.length);
        setLoader(false);
      }
    } catch (error) {
      setLoader(true);
      localStorage.clear();
      window.location.href = "/login";
    }
  };
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const handleOnCancelClick = () => {
    setIsOpen(false);
  };
  const handleOnContinueClick = async (employeeToAdd) => {
    const formData = new FormData();
    formData.append("adminId", adminId);
    formData.append("accessToken", accessToken);
    formData.append("firstName", employeeToAdd.firstName);
    formData.append("lastName", employeeToAdd.lastName);
    formData.append("email", employeeToAdd.email);
    formData.append("phone", employeeToAdd.phone);
    formData.append("dayRateInCents", employeeToAdd.dayRate);
    formData.append("nightRateInCents", employeeToAdd.nightRate);
    formData.append("saturdayRateInCents", employeeToAdd.saturdayRate);
    formData.append("sundayRateInCents", employeeToAdd.sundayRate);
    formData.append("holidayRateInCents", employeeToAdd.holidayRate);
    try {
      const addEmployeeResponse = await AdminApi.addEmployee(formData);
    } catch (error) {}
    setIsOpen(false);
    fetchEmployees();
  };
  const onClickemployeeDetails = (e, id) => {
    navigate("/employee-details/" + id);
  };
  const handleSearch = (e) => {
    if (searchText !== null && searchText !== "") {
      if (employees.length > 0) {
        var newarr = [];
        var arr = employees.filter((u) => {
          if (
            u.firstName !== undefined &&
            u.firstName.toLowerCase().includes(searchText.toLowerCase())
          )
            return u;
        });

        for (var i = 0; i < arr.length; i++) {
          newarr[i] = createEmployees(
            i + 1,
            arr[i].firstName,
            arr[i].lastName,
            arr[i].enployeeId,
            arr[i].email,
            arr[i].phone
          );
        }
        setTblEmployees(newarr);
      }
    } else {
      var newarr = [];
      var arr = employees;
      for (var i = 0; i < arr.length; i++) {
        newarr[i] = createEmployees(
          i + 1,
          arr[i].firstName,
          arr[i].lastName,
          arr[i].employeeId,
          arr[i].email,
          arr[i].phone
        );
      }

      setTblEmployees(newarr);
    }
  };
  const handleSearchKeyDown = (e) => {
    if (e.key == "Enter") {
      if (searchText !== null && searchText !== "") {
        if (tblEmployees.length > 0) {
          var arr = tblEmployees.filter((u) => {
            if (
              u.firstName !== undefined &&
              u.firstName.toLowerCase().includes(searchText.toLowerCase())
            )
              return u;
          });
          setTblEmployees(arr);
        }
      } else {
        setTblEmployees(tblEmployees);
      }
    }
  };
  return (
    <div className="cstm-contant-main">
      <div className="cstm-contant-main-cont">
        <EmployeeSidebar />
        <div className="cstm-contant-main-cont-all">
          <div className="cstm-contant-main-cont-all-main">
            <EmployeeHeader onAddEmployeeClick={() => togglePopup()} />
            <Divider />
            <div className="cstm-contant-main-cont-table">
              <div className="cstm-contant-main-cont-table-header">
                <div className="cstm-contant-main-cont-table-header-top">
                  <div className="cstm-contant-main-cont-table-header-top-left">
                    <select className="select-all">
                      <option>All Employees</option>
                    </select>
                  </div>
                  <div className="cstm-contant-main-cont-table-header-top-right">
                    <div className="cstm-searchbar">
                      <i className="fa fa-search"></i>
                      <input
                        className="search-input"
                        type="text"
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                        onKeyDown={handleSearchKeyDown}
                        placeholder="Search Employee"
                      />
                      <button className="search-btn" onClick={handleSearch}>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <CommonTable
                loader={loader}
                columns={columns}
                apiData={tblEmployees}
                options={{
                  searchFieldStyle: {
                    width: "347px",
                    height: "38px",
                    opacity: "0.75",
                    borderRadius: "5px",
                    border: "solid 0.5px #707070",
                    backgroundColor: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  },
                  emptyRowsWhenPaging: false,
                  paginationPosition: "top",
                  search: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 30],
                  draggable: false,
                  exportButton: false,
                  toolbar: true,
                  thirdSortClick: false,
                }}
                onRowClick={(e, rowData) => {
                  onClickemployeeDetails(e, rowData.employeeId);
                }}
                toolbarData={{
                  Pagination: (props) => {
                    let paginationprops = props;
                    paginationprops = {
                      ...paginationprops,
                      label: "Contacts showing : ",
                      positionPagination: "top",
                    };

                    return <CustomPagination {...paginationprops} />;
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <AddEmployeePopup
          onCancelClick={() => handleOnCancelClick()}
          onDataSubmit={handleOnContinueClick}
        />
      )}
    </div>
  );
};

export default EmployeeContainer;
