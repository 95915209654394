import { useEffect, useState } from "react";

import "../Employee/Employee.css";

const DateTimeTextPicker = ({ onTimeSelected, presetTime }) => {
  const [time, setTime] = useState("");

  // useEffect(() => {
  //   setTime(presetTime ? presetTime : "");
  // }, []);

  const handleTimeChange = (e) => {
    let inputValue = e.target.value.trim();

    const numRegex = /[0 - 9]*/;

    if (numRegex.test(inputValue)) {
      setTime(inputValue);
    } else {
      // Handle invalid input
      console.log(
        "Invalid time format. Please enter a valid 24-hour time (HH:mm)"
      );
    }
  };

  const fixTimeFormatting = (e) => {
    let inputValue = e.target.value.trim();
    if (/^[1-9][0-5][0-9]$/.test(inputValue)) {
      inputValue = `0${inputValue}`;
    }

    const regex = /^([01][01]?[0-9]|2[0-3])[0-5][0-9]$/;

    if (regex.test(inputValue)) {
      setTime(inputValue);
      onTimeSelected(convertTimeToSeparateValues(inputValue));
    } else {
      onTimeSelected(null);

      console.log(
        "Invalid time format. Please enter a valid 24-hour time (HH:mm)"
      );
    }
  };
  function convertTimeToSeparateValues(timeString) {
    // Extract hours and minutes from the time string
    const hours = parseInt(timeString.substring(0, 2));
    const minutes = parseInt(timeString.substring(2));

    return { hours, minutes };
  }
  return (
    <div>
      <input
        className="Input-Time"
        placeholder="hhmm"
        maxLength={4}
        value={time}
        pattern="[0-9]*"
        onBlur={fixTimeFormatting}
        onChange={handleTimeChange}
      />
    </div>
  );
};

export default DateTimeTextPicker;
