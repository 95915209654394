const initialState = {
    employeeid: ''
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case "EMPLOYEE_ID":
            return {
                ...state,
                employeeid: payload
            }
        default:
            return state
    }
}