import React from "react";
import HomeBubbleComponent from "../Home/HomeBubbleComponent";
import mob1 from "../../img/mob.png";
import mob2 from "../../img/single_1.png";
import screens from "../../img/trial_3.jpg";
import "./AppPage.css";

const AppPage = () => {
  return (
    <div style={{ backgroundColor: "#011432", }}>
      <div className="app-page-container">
        <div className="app-page-mob-container">
          <img className="app-page-mob-img" src={mob1} />
          <div
            className="app-page-mob-sub-container"
            style={{ marginLeft: "5vw" }}>
            <div
              className="app-page-title"
              style={{ width: "32.2vw", }}>
              Shift management & tracking in real-time
            </div>
            <div className="app-page-subtext" style={{ marginTop: "3.6vh", width: "35.1vw", }}>
              Send shift details and updates directly to the employee from admin
              dashboard in real-time.
            </div>

            <div style={{ width: "32.2vw", marginTop: "3.7vh", }}>
              <HomeBubbleComponent
                subtitleText={"Key features include - Dashboard that enables multiple project managers to work simultaneously in sync, etc."}
              />
            </div>
          </div>
        </div>

        <div className="app-page-mob-container">
          <div className="app-page-mob-sub-container">
            <div
              className="app-page-title"
              style={{ width: "32.2vw", }}>
              Instant in-app incident reporting
            </div>
            <div className="app-page-subtext" style={{ marginTop: "3.6vh", width: "35.1vw", }}>
              Fill out a quick description form, click and upload pictures from
              the app and raise a complaint.
            </div>

            <div style={{ width: "32.2vw", marginTop: "3.7vh", }}>
              <HomeBubbleComponent
                subtitleText={"Key features include - Dashboard that enables multiple project managers to work simultaneously in sync, etc."}
              />
            </div>
          </div>
          <img
            className="app-page-mob-img"
            src={mob2}
            style={{marginLeft: "10vw",}}
          />
        </div>
      </div>
      <img
        className="app-page-screens-img"
        src={screens}
        style={{width: "-webkit-fill-available",marginTop: "10vh",}}
      />
    </div>
  );
};

export default AppPage;
