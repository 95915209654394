import { Fade } from "@material-ui/core";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Divider } from "../../../../../components/Common/Divider";
import { ReactComponent as DropDownArrow } from "../../../../../img/DropdownDownArrow.svg";
import ColumnChart from "./ColumnChart";

const IncidentsChart = () => {
  const [menu, setMenu] = useState(null);
  const [selectVal, setSelectVal] = useState("2022");
  const [selectValData, setSelectValData] = useState([]);

  const open = Boolean(menu);
  const handleClick = (event) => {
    setMenu(event.currentTarget);
  };
  const handleClose = () => {
    setMenu(null);
  };
  const handleSelect = (value) => {
    setMenu(null);
    setSelectVal(value);
    setSelectValData(selectValData);
  };

  useEffect(() => {
    const chartData = [
      {
        year: "2022",
        data: [100, 150, 140, 220, 300, 200, 250, 100, 280, 340, 380, 400],
      },
      {
        year: "2023",
        data: [100, 280, 340, 380, 400, 100, 150, 140, 220, 300, 200, 250],
      },
      {
        year: "2024",
        data: [220, 300, 200, 250, 100, 150, 140, 100, 280, 340, 380, 400],
      },
      {
        year: "2025",
        data: [300, 200, 280, 340, 380, 400, 250, 100, 100, 150, 140, 220],
      },
    ];
    setSelectValData(
      chartData.filter((item) => item.year === selectVal)[0].data
    );
  }, [selectVal]);

  return (
    <>
      <Box
        sx={{
          border: "1px solid #EFF0F6",
          borderRadius: "20px",
          overflow: "hidden",
          // maxWidth: '844px',
          // width: '100%',
          margin: "24px 0 40px",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
            paddingBottom: "9px",
          }}
        >
          <Typography variant="h3" color="#4D4D4D" fontFamily={"Inter"}>
            Overall Incidents Acquisition
          </Typography>

          <Button
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{
              padding: "0",
              color: "#5B5E6F",
              fontWeight: "600",
              flexShrink: "0",
              "& .MuiTouchRipple-root .MuiTouchRipple-child": {
                backgroundColor: "#E3F5FF !important",
              },
              "& svg": {
                ml: "5px",
                transition: "all .5s ease",
                transform: `rotate(${open ? "180" : "0"}deg)`,
              },
              "&:hover": {
                backgroundColor: "transparent !important",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
          >
            {selectVal}
            <DropDownArrow />
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={menu}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem
              onClick={() => {
                handleSelect("2023");
              }}
            >
              2023
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleSelect("2024");
              }}
            >
              2024
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleSelect("2025");
              }}
            >
              2025
            </MenuItem>
          </Menu>
        </Box>
        <Divider />
        <ColumnChart data={selectValData} />
      </Box>
    </>
  );
};

export default IncidentsChart;
