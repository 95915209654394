import "./Sidebar.css";
import { Divider } from "../Common/Divider";
import { Button } from "../Button/Button";

const EmployeeSidebar = () => {
  return (
    <>
      <div className="SidebarContainer">

        <div className="cstm-sideber-top">

        <span className="SidebarTitleText">All Employees</span>

        <Divider />


        <div className="cstm-sideber-top-ove">
        <div className="cstm-sideber-top-box">
        <span className="SidebarElementText">Employee Groups</span>
        
        <span className="SidebarElementSubText">
          Group your employees to use randomised shifts
        </span>

        <span className="create-group-btn"><Button>+ Create Group</Button></span>   

        </div> 

        <Divider />

        <div className="cstm-sideber-top-box">
        <span className="SidebarElementText">Onboarding <span className="SidebarPending">5 pending</span></span>
        <span className="SidebarElementSubText">
          Verify uploaded documents to onboard employees
        </span>
        </div>

        <Divider />

        <div className="cstm-sideber-top-box">
        <span className="SidebarElementText">Expired License</span>
        <span className="SidebarElementSubText">
          Check for license of employees that were expired
        </span>
       </div>

        <Divider />

        <div className="cstm-sideber-top-box">
        <span className="SidebarElementText">Archived Employees</span>
        <span className="SidebarElementSubText">
          A collection of employees of employees that worked in the past
        </span>
        </div>

        </div>

        </div>

 
        <div className="cstm-sideber-bottom">
          <span className="SidebarElementText">Need help?</span>
          <span className="need-help-link">
            <ul>
               <li><a href="#">Report a bug</a></li>
               <li><a href="#">Contact SureSight support</a></li> 
            </ul>
            </span>
        </div>

      </div>
    </>
  );
};

export default EmployeeSidebar;
