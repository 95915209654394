import React, { useState } from 'react'
import { Button } from '../../components/Button/Button'
import Navbar from '../../components/Navbar/Navbar'
import Logo from '../../img/logo.png'
import { Link } from 'react-router-dom'

const ForgotPassword = () => {

    const [error, setError] = useState({
        newPassword: "",
        confirmPassword: "",
        passError: "",
        Email: ""
    })
    const [emailData, setEmailData] = useState({ Email: "" })

    const emailRegex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)

    const validateEmail = () => {
        let emailError = {}
        let isValid = false
        if (!emailData.Email) {
            isValid = true
            emailError.Email = "Enter Email"
        } else if (!emailRegex.test(emailData.Email)) {
            isValid = true
            emailError.Email = "Enter valid Email"
        } else {
            emailError.Email = ""
        }
        setError(emailError)
        return isValid
    }

    const handleEmailChange = (e) => {
        const { name, value } = e.target
        setEmailData({ ...emailData, [name]: value })
        switch (name) {
            case "Email":
                value === "" ? setError({ ...error, Email: "Enter Email" }) : !emailRegex.test(emailData.Email) ? setError({ ...error, Email: "Enter valid email" }) : setError({ ...error, Email: "" })
                break;

            default:
                break;
        }
    }

    const handleEmailSubmit = (e) => {
        e.preventDefault()
        if (!validateEmail(emailData)) {
            console.log(emailData, "email");
        }
    }

    return (
        <>
            <div className='cstm-form-man'>
                <div className='cstm-form-man-in'>
                    <div className='cstm-form-man-logo'><Link to="/login"><img src={Logo} /></Link></div>
                    <div className='cstm-form-man-info'>
                        <form>
                            <div className='cstm-form-title'>Forgot Password</div>

                            <div className='cstm-form-box'>
                                <div className='cstm-form-box-top'>Email<span className="form-asterisk">*</span></div>
                                <div className='cstm-form-box-bottom'>
                                    <input
                                        placeholder='Enter Email'
                                        className="cstm-input-fm"
                                        type="email"
                                        name='Email'
                                        onChange={handleEmailChange}
                                        value={emailData.Email}
                                    />
                                    <span className="error-message"> {error.Email} </span>

                                </div>
                            </div>
                            <div className='cstm-form-box3'>
                                <Button
                                    onClick={handleEmailSubmit}
                                >Forgot Password</Button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;