import { useParams } from "react-router-dom";

const ComplaintDetailsContainer = () => {
  const { id } = useParams();

  return (
    <>
      <div>{id}</div>
    </>
  );
};

export default ComplaintDetailsContainer;
